import rimg from '@pixelunion/rimg-shopify';
import EventHandler from '@pixelunion/events';
import productCompare from './ProductCompare';

export default class ProductCompareDrawerContent {
  constructor(el) {
    this.el = el;
    this.events = new EventHandler();
    this.initialHTML = this.el.innerHTML;
    this.productTemplate = this.el.querySelector('[data-product-compare-drawer-item-template]');

    this.events.register(this.el, 'click', e => {
      const compareDrawerRemoveButton = e.target.closest('[data-product-compare-drawer-remove]');

      if (compareDrawerRemoveButton) {
        this._removeProduct(compareDrawerRemoveButton.dataset.productCompareDrawerRemove);
      }
    });

    const onUpdate = ({ products }) => {
      this._reset();
      if (!products.length) {
        this.el.scroll(0, 0);
      }
      products.forEach(product => this._addProduct(product));
      rimg.watch(this.el);
    };

    onUpdate({ products: productCompare.products });

    productCompare.runOnUpdate(onUpdate);
  }

  unload() {
    this.events.unregisterAll();
  }

  _reset() {
    rimg.unwatch(this.el);
    this.el.innerHTML = this.initialHTML;
  }

  _addProduct({ handle, data }) {
    const newProduct = this.productTemplate.cloneNode(true).content;
    const newProductFragment = new DocumentFragment();
    const compareDrawerTitle = newProduct.querySelector('[data-product-compare-drawer-title]');
    const compareDrawerImage = newProduct.querySelector('[data-product-compare-drawer-image]');
    const compareDrawerRemove = newProduct.querySelector('[data-product-compare-drawer-remove]');
    const compareDrawerItemPlaceholder = this.el.querySelector('[data-product-compare-drawer-item-placeholder]');

    compareDrawerTitle.innerHTML = data.title;
    compareDrawerTitle.href = data.url;
    compareDrawerRemove.dataset.productCompareDrawerRemove = handle;
    compareDrawerImage.innerHTML = data.image;
    compareDrawerImage.style.setProperty('--product-grid-item-image-aspect-ratio', data.imageAspectRatio);

    newProductFragment.append(newProduct);
    this.el.insertBefore(newProductFragment, compareDrawerItemPlaceholder);

    if (compareDrawerItemPlaceholder) {
      compareDrawerItemPlaceholder.remove();
    }
  }

  _removeProduct(handle) {
    productCompare.remove(handle);
  }
}
