import EventHandler from '@pixelunion/events';
import { transition } from '@pixelunion/animations';

import StaticSearch from './StaticSearch';
import Forms from '../Forms';
import FilterGroups from '../components/FilterGroups';
import Modal from '../components/Modal';


export default class FacetedFilterSearch extends StaticSearch {
  constructor(section) {
    super(section);
    this.el = section.el;
    this.filterInputs = null;
    this.filterEvents = new EventHandler();

    this.updateTimeout = null;

    this._initFilterEvents = this._initFilterEvents.bind(this);
    this._updatePrice = this._updatePrice.bind(this);
    this._buildFilterQuery = this._buildFilterQuery.bind(this);

    this.filterGroups = section.data.filter_groups;
    this.filterStyle = 'faceted';
    this.filtersContentSelector = '[data-productgrid-sidebar]';
    this.filtersContent = this.el.querySelector(this.filtersContentSelector);
    this.filtersTrigger = this.el.querySelector('[data-productgrid-trigger-filters]');

    this.filterRange = this.el.querySelectorAll('[data-filter-range]');
    this.filterCheckboxes = this.el.querySelectorAll('.filter-icon--checkbox');
    this.rangeNames = [];
    this.fillAnimations = {};
    this.checkAnimations = {};

    this.modal = new Modal();

    this.filterEvents.register(this.filtersTrigger, 'click', e => {
      this._showFiltersModal(e);
    });

    this._initAnimations();

    this.filterRange.forEach(el => {
      this.rangeNames.push(el.name);
    });

    if (this.filtersContent) {
      const options = {
        groups: this.filterGroups,
        style: this.filterStyle,
      };
      this.filterGroupAccordions = new FilterGroups(this.filtersContent, options);

      this._initFilters();
    }

    this.forms = new Forms(this.el);

    this._initFilterEvents();
  }

  _initFilterEvents() {
    /* For radio buttons */
    this.filterInputs = this.el.querySelectorAll('[data-filter-input]');

    this.filterInputs.forEach(filter => {
      this.filterEvents.register(filter, 'click', e => {
        e.preventDefault();
        const target = e.currentTarget;

        if (target.dataset.hasOwnProperty('disabled')) return;

        const handle = target.getAttribute('data-handle');

        let animateTo = 'checked';

        if (target.getAttribute('data-filter-active')) {
          animateTo = 'unchecked';
          target.removeAttribute('data-filter-active');
        } else {
          target.setAttribute('data-filter-active', '');
        }

        if (this.fillAnimations[handle] && this.checkAnimations[handle]) {
          this.fillAnimations[handle].animateTo(animateTo);
          this.checkAnimations[handle].animateTo(animateTo);
        }

        this.getFilteredResults(e.currentTarget);
      });
    });

    /* For range input */
    this.filterRange.forEach(filter => {
      this.filterEvents.register(filter, 'keyup', e => this._updatePrice(e.currentTarget));
      this.filterEvents.register(filter, 'change', e => this._updatePrice(e.currentTarget));
    });

    this.filterAccordionButton = this.el.querySelectorAll('[data-filter-group-trigger]');

    this.filterAccordionButton.forEach(filter => {
      this.filterEvents.register(filter, 'click', e => {
        e.preventDefault();
      });
    });
  }

  /*
   * Initialize animations on checkbox container
   * using filter tag as JSON key
   */
  _initAnimations() {
    this.filterCheckboxes.forEach(el => {
      const tagHandle = el.dataset.handle;
      const checkmark = el.querySelector('.checkmark');
      const checkmarkCheck = el.querySelector('.checkmark__check');
      let state = 'unchecked';

      if (el.closest('[data-filter-input]').getAttribute('data-filter-active')) {
        state = 'checked';
      }

      const fillAnimation = transition({ el: checkmark, state });
      const checkAnimation = transition({ el: checkmarkCheck, state });

      this.fillAnimations[tagHandle] = fillAnimation;
      this.checkAnimations[tagHandle] = checkAnimation;
    });
  }

  _initFilters() {
    const activePriceFilter = this.el.querySelector('[data-filter-group-range]');

    this.filterRange.forEach(priceInput => {
      if (priceInput.value.length > 0) {
        activePriceFilter.setAttribute('data-filter-open', 'true');
      }
    });

    const activeFilters = this.el.querySelectorAll('[data-filter-open="true"]');

    activeFilters.forEach(filter => {
      this._openActiveGroup(filter);
    });
  }

  _showFiltersModal(event) {
    event.preventDefault();

    this.focusItem = event.currentTarget;
    this.modal.open(this.filtersContentSelector, 'productgrid-filters');
  }

  _openActiveGroup(filter) {
    const button = filter.closest('[data-filter-group]').querySelector('[data-filter-group-trigger]');

    const list = filter.closest('[data-accordion-content]');
    this.filterGroupAccordions.openGroup(button, list, true);
  }

  _buildFilterQuery(element) {
    let searchParameters;

    // If input is price range, the url_to_add property is not available, so we
    // need to build the query manually
    if (element.classList.contains('collection-filters__filter-range-input')) {
      const currentSearch = window.location.search.replace('?', '');
      const formData = new FormData(element.closest('form'));
      const newSearch = new URLSearchParams(formData).toString();

      // Filter through current search string to see if price range input already exists,
      // if it does, create new string without current price range parameters
      const splitCurrentSearch = currentSearch.split('&');

      let filteredCurrentSearch = splitCurrentSearch.filter(search => {
        if (search.includes(this.rangeNames[0]) || search.includes(this.rangeNames[1])) {
          return false;
        }

        return true;
      });

      filteredCurrentSearch = filteredCurrentSearch.join('&');

      // If current search parameters exist, append new search parameters
      // on to existing parameters
      if (filteredCurrentSearch.length > 0) {
        searchParameters = `${filteredCurrentSearch}&${newSearch}`;
      } else {
        searchParameters = `${newSearch}`;
      }
    } else {
      // If input is a link, build searchParameters using dataset URL
      const elementUrl = element.dataset.url;
      const decodedElementUrl = decodeURIComponent(elementUrl);

      searchParameters = decodedElementUrl.split('?')[1];

      if (!searchParameters) {
        searchParameters = '';
      }
    }

    let urlQueryString = '';

    if (searchParameters) {
      urlQueryString = `?${searchParameters}`;
    }

    return urlQueryString;
  }

  _updatePrice(element) {
    // cancel any pending requests
    if (this.updateTimeout !== null) {
      clearTimeout(this.updateTimeout);
    }

    // Wait for user to finish entering input before reloading page
    this.updateTimeout = setTimeout(() => {
      this.getFilteredResults(element);
    }, 2000);
  }

  getFilteredResults(element) {
    const filterQuery = this._buildFilterQuery(element);
    window.location.search = filterQuery;
  }

  onSectionUnload() {
    super.onSectionUnload();
    this.filterEvents.unregisterAll();
    this.forms.unload();
  }
}
