import Disclosure from '@pixelunion/shopify-cross-border';

export default class StaticUtilityBar {
  constructor(section) {
    this.el = section.el;

    // Utility Menu Variables
    this.mobileUtilityMenuEl = section.el.querySelector('[data-utility-menu-mobile]');
    this.mobileNavContentEl = null;
    this.mobileElChildren = [];
    this.documentFragmentMenu = document.createDocumentFragment();
    this.mobileLayout = section.data.settings.mobile_layout;
    this._loadMobileNavItems = this._loadMobileNavItems.bind(this);

    // Utility Bar Variables - Disclosure & Social List
    this.mobileUtilityBarEl = section.el.querySelector('[data-utility-bar-mobile]');
    this.mobileUtilityContentEl = null;
    this.mobileUtilityChildren = [];
    this.mobileUtilityPlacement = document.querySelector('[data-utility-mobile]');
    this.documentFragmentDisclosure = document.createDocumentFragment();
    this._loadMobileUtilityItems = this._loadMobileUtilityItems.bind(this);
    this.mobileScrollHeight = document.querySelector('[data-mobile-nav-content]');;
    this.mobileUtilitySocial = document.querySelector('[data-utility-social-mobile]');
    this.countryDisclosureEl = this.el.querySelector('[data-disclosure-country]');
    this.localeDisclosureEl = this.el.querySelector('[data-disclosure-locale]');
    this.disclosures = [];

    if (this.mobileUtilityMenuEl) {
      this._loadMobileNavItems();
      window.addEventListener('shopify:section:load', this._loadMobileNavItems);
    }

    if (this.mobileUtilityBarEl) {
      this._loadMobileUtilityItems();
      window.addEventListener('shopify:section:load', this._loadMobileUtilityItems);
    }

    if (this.countryDisclosureEl) {
      this.disclosures.push(new Disclosure(this.countryDisclosureEl));
    }

    if (this.localeDisclosureEl) {
      this.disclosures.push(new Disclosure(this.localeDisclosureEl));
    }

    if (this.mobileUtilitySocial) {
      this.mobileScrollHeight.classList.add('utility-social-enabled');
    }
  }

  onSectionUnload() {
    window.removeEventListener('shopify:section:load', this._loadMobileNavItems);
    window.removeEventListener('shopify:section:load', this._loadMobileUtilityItems);
    if (this.mobileNavContentEl) {
      this.mobileElChildren.forEach(child => this.mobileNavContentEl.removeChild(child));
    }
    if (this.mobileUtilityContentEl) {
      this.mobileUtilityChildren.forEach(child => this.mobileUtilityPlacement.removeChild(child));
    }
    this.disclosures.forEach(disclosure => disclosure.unload());
  }

  _loadMobileNavItems() {
    const inDom = this.mobileElChildren.some(child => document.body.contains(child));

    // If utility nav already exists in mobile flyout, return
    if (inDom) return;

    this.mobileNavContentEl = document.querySelector('[data-mobile-nav-content]');
    this.mobileElChildren = [];

    if (this.mobileNavContentEl) {
      const { children } = this.mobileUtilityMenuEl;

      for (let i = 0; i < children.length; i++) {
        const clone = children[i].cloneNode(true);
        this.mobileElChildren.push(clone);
        this.documentFragmentMenu.appendChild(clone);
      }

      if (this.mobileLayout === 'below') {
        this.mobileNavContentEl.appendChild(this.documentFragmentMenu);
        this.mobileNavContentEl.classList.add('utility-nav-below');
      } else {
        this.mobileNavContentEl.insertBefore(this.documentFragmentMenu, this.mobileNavContentEl.firstElementChild);
      }
    }
  }

  _loadMobileUtilityItems() {
    const inDom = this.mobileUtilityChildren.some(child => document.body.contains(child));

    // If utility content already exists in mobile flyout, return
    if (inDom) return;

    this.mobileUtilityContentEl = document.querySelector('[data-utility-bar-mobile]');
    this.mobileUtilityChildren = [];

    if (this.mobileUtilityContentEl) {
      const { children } = this.mobileUtilityContentEl;

      for (let i = 0; i < children.length; i++) {
        const clone = children[i].cloneNode(true);
        this.mobileUtilityChildren.push(clone);
        this.documentFragmentDisclosure.appendChild(clone);
      }

      this.mobileUtilityPlacement.appendChild(this.documentFragmentDisclosure);

      const countryDisclosureElMobile = this.mobileUtilityPlacement.querySelector('[data-disclosure-country]');
      const localeDisclosureElMobile = this.mobileUtilityPlacement.querySelector('[data-disclosure-locale]');

      if (countryDisclosureElMobile) {
        this.disclosures.push(new Disclosure(countryDisclosureElMobile));
      }

      if (localeDisclosureElMobile) {
        this.disclosures.push(new Disclosure(localeDisclosureElMobile));
      }
    }
  }
}
