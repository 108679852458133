import EventHandler from '@pixelunion/events';
import debounce from 'just-debounce';
import rimg from '@pixelunion/rimg-shopify';
import AsyncView from '@pixelunion/shopify-asyncview';

let swatchGap = null;

export default class GridItemSwatches {
  constructor(options) {
    this.el = options.el;
    this.setInitialVariant = options.setInitialVariant;

    this.expanded = false;
    this.swatchImages = {};
    this.swatchVariantIds = {};
    this.selectedImage = null;
    this.state = {};
    this.events = new EventHandler();

    this.data = JSON.parse(this.el.querySelector('[data-swatch-data]').innerHTML);
    this.variants = this.data.variants;

    this.swatchesContainer = this.el.querySelector('[data-swatches-container]');
    this.cardLinks = this.el.querySelectorAll('[data-product-page-link]');
    this.cardLinkHref = this.cardLinks[0].getAttribute('href');
    this.activeControlTime = 0;
    this.activeControl = this.swatchesContainer.querySelector('[checked]');
    this.isPreselected = !!this.activeControl;

    this.swatches = this.swatchesContainer.querySelectorAll('[data-swatch]');
    this.swatchCount = this.swatches.length;
    this.swatchCountWrapper = this.el.querySelector('[data-swatch-count-wrapper]');
    this.swatchCount = this.swatchCountWrapper.querySelector('[data-swatch-count]');
    this.swatchWidths = Array.prototype.map.call(this.swatches, swatch => swatch.getBoundingClientRect().width);
    this.resize();
    this.el.querySelector('[data-swatches]').classList.add('processed');

    this.originalImages = {
      primary: this.el.querySelector('.productitem--image-primary'),
      alternate: this.el.querySelector('.productitem--image-alternate'),
    };

    this.disableUnavailable();

    // +/- button expansion events
    this.events.register(window, 'resize', debounce(() => this.resize(), 50));
    this.events.register(this.swatchCountWrapper, 'click', () => this._toggleExpanded());

    // Image injection events
    this.injectImagesMouseoverEvent = this.events.register(this.el, 'mouseover', () => this._injectImages());
    this.injectImagesFocusinEvent = this.events.register(this.el, 'focusin', () => this._injectImages());

    // Image/swatch switching events
    this.events.register(this.swatchesContainer, 'mouseover', e => this._handleMouseOver(e));
    this.events.register(this.swatchesContainer, 'mouseleave', e => this._handleMouseLeave(e));
    this.events.register(this.swatchesContainer, 'click', e => this._handleClick(e));
    this.events.register(this.swatchesContainer, 'change', e => this._handleChange(e));
    this.events.register(this.swatchesContainer, 'focusin', e => this._handleSwatchFocus(e));
  }

  disableUnavailable() {
    if (!this.variants) {
      return;
    }

    const availableSwatches = {};

    this.variants.forEach(variant => {
      if (variant.available) {
        availableSwatches[variant[this.data.swatchOptionKey]] = true;
      }
    });

    const swatchInputs = this.swatchesContainer.querySelectorAll('input[name="swatch"]');

    swatchInputs.forEach(swatch => {
      if (!(swatch.value in availableSwatches)) {
        swatch.classList.add('swatch-disabled')

        if (!swatch.classList.contains('sold_out_option--selectable')) {
          swatch.disabled = true;
        }
      }
    });
  }

  resize() {
    if (this.expanded) this._toggleExpanded();

    const availableWidth = this.swatchesContainer.getBoundingClientRect().width
      - parseInt(window.getComputedStyle(this.swatchesContainer).paddingRight, 10);

    let newShowSwatchCount = this.swatches.length;
    let cumulativeWidth = 0;

    for (let i = 0; i < this.swatches.length; i++) {
      if (cumulativeWidth + this.getSwatchGap() + this.swatchWidths[i] < availableWidth) {
        cumulativeWidth += swatchGap + this.swatchWidths[i];
      } else {
        newShowSwatchCount = i;
        break;
      }
    }

    if (newShowSwatchCount === this.showSwatchCount) return;

    this.showSwatchCount = newShowSwatchCount;

    this.swatches.forEach((swatch, index) => {
      if (index < this.showSwatchCount) {
        swatch.classList.add('productitem--swatches-swatch-visible');
        swatch.classList.remove('productitem--swatches-swatch-hidden');
      } else {
        swatch.classList.remove('productitem--swatches-swatch-visible');
        swatch.classList.add('productitem--swatches-swatch-hidden');
      }
    });

    this.swatchCountWrapper.style.left = `${cumulativeWidth}px`;

    if (this.swatches.length > this.showSwatchCount) {
      this.swatchCountWrapper.style.display = 'flex';
      this.swatchCount.innerText = `+${this.swatches.length - this.showSwatchCount}`;
    } else {
      this.swatchCountWrapper.style.display = 'none';
    }
  }

  _injectImages() {
    this.events.unregister(this.injectImagesFocusinEvent);
    this.events.unregister(this.injectImagesMouseoverEvent);

    AsyncView.load(this.cardLinkHref, 'product-swatch-data')
      .then(({ data }) => {
        if (this.imagesInjected) return;
        this.imagesInjected = true;
        const tempContainer = document.createDocumentFragment();

        if (data.featuredImage) {
          const tempEl = document.createElement('div');
          tempEl.innerHTML = data.featuredImage;
          const img = tempEl.querySelector('img');
          tempContainer.appendChild(img);
          this.featuredImage = img;
        }

        data.swatches.forEach(({ swatchValue, imageString, variantId }) => {
          this.swatchVariantIds[swatchValue] = variantId;
          if (imageString) {
            const tempEl = document.createElement('div');
            tempEl.innerHTML = imageString;
            const img = tempEl.querySelector('img');
            tempContainer.appendChild(img);
            this.swatchImages[swatchValue] = img;
          }
        });
        const imagesContainer = this.el.querySelector('[data-product-item-image]');
        const salesBadge = imagesContainer.querySelector('[data-badge-sales]');

        imagesContainer.insertBefore(tempContainer, salesBadge);
        rimg.watch(imagesContainer);

        this._setState({
          swatchName: this.activeControl ? this.activeControl.value : null,
          primaryImage: this.originalImages.primary,
          hideAlternateImage: !!this.activeControl,
        });
      });
  }

  _setState({ swatchName, primaryImage, hideAlternateImage }) {
    const oldPrimaryImg = this.state.primaryImage;

    const href = swatchName ? `${this.cardLinkHref}?variant=${this.swatchVariantIds[swatchName]}` : this.cardLinkHref;
    this.cardLinks.forEach(link => link.setAttribute('href', href));

    this.setInitialVariant(this.swatchVariantIds[swatchName] || this.variants[0].id);

    if (oldPrimaryImg) {
      oldPrimaryImg.classList.remove('productitem--image-primary');
      oldPrimaryImg.style.visibility = '';
    }

    if (primaryImage) {
      primaryImage.classList.add('productitem--image-primary');
      primaryImage.style.visibility = hideAlternateImage ? 'visible' : '';
    }

    if (this.originalImages.alternate) {
      this.originalImages.alternate.style.visibility = hideAlternateImage ? 'hidden' : '';
    }

    this.state = { swatchName, primaryImage, hideAlternateImage };
  }

  _handleChange({ target }) {
    this.activeControlTime = Date.now();
    this.activeControl = target;
    const swatchName = target.value;
    this.selectedImage = this.swatchImages[swatchName] || this.originalImages.primary;
    this._setState({
      swatchName,
      primaryImage: this.selectedImage,
      hideAlternateImage: true,
    });
  }

  _handleClick({ target }) {
    if (target === this.activeControl && this.activeControlTime + 150 < Date.now()) {
      // Allow deselection, if swatch has been active for more than the threshold
      // because we can't guarantee the order of the click and change events
      target.checked = false;
      this.activeControl = null;
      this.selectedImage = null;

      // Swaps in featured image if card was rendered on server with preselected swatch variant image.
      if (this.isPreselected && this.featuredImage) {
        this.originalImages.primary = this.featuredImage;
        this.isPreselected = false;
      }

      this._setState({
        swatchName: null,
        primaryImage: this.state.primaryImage,
        hideAlternateImage: true,
      });
    }
  }

  _handleMouseOver(event) {
    if (event.target.hasAttribute('data-swatch-tooltip')) {
      this._setState({
        swatchName: this.state.swatchName,
        primaryImage: this.swatchImages[event.target.dataset.swatchTooltip] || this.originalImages.primary,
        hideAlternateImage: true,
      });
    }
  }

  _handleMouseLeave({ target }) {
    if (target.hasAttribute('data-swatches-container')) {
      this._setState({
        swatchName: this.state.swatchName,
        primaryImage: this.selectedImage || this.originalImages.primary,
        hideAlternateImage: !!this.selectedImage,
      });
    }
  }

  _handleSwatchFocus({ target }) {
    if (this.expanded === false && target.nextElementSibling.classList.contains('productitem--swatches-swatch-hidden')) {
      this._toggleExpanded();
    }
  }

  _toggleExpanded() {
    const swatchesEl = this.el.querySelector('[data-swatches]');

    if (this.expanded) {
      this.expanded = false;
      swatchesEl.classList.remove('productitem--swatches-expanded');
      this.swatchCount.innerText = `+${this.swatches.length - this.showSwatchCount}`;
    } else {
      this.expanded = true;
      swatchesEl.classList.add('productitem--swatches-expanded');
    }
  }

  // Getters are used to avoid having to get these properties for every card on the page
  getSwatchGap() {
    if (swatchGap === null) {
      swatchGap = parseInt(window.getComputedStyle(this.swatches[0]).getPropertyValue('margin-right'), 10);
    }
    return swatchGap;
  }

  unload() {
    this.events.unregisterAll();
  }
}
