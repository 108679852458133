import ProductGridItem from '../components/ProductGridItem';
import SearchForm from '../components/search/SearchForm';

export default class StaticSearch {
  constructor(section) {
    this.section = section;
    this.el = section.el;
    this.searchField = this.el.querySelector('[data-live-search]');

    // Product items
    this.productItems = [];
    const productItems = this.el.querySelectorAll('[data-product-item]');

    productItems.forEach(productItem => {
      this.productItems.push(
        new ProductGridItem({
          el: productItem,
          id: this.section.id,
          lazy: true,
        }),
      );
    });

    this.searchForm = new SearchForm(this.searchField);
  }

  onSectionUnload() {
    this.searchForm.unload();

    this.productItems.forEach(productItem => {
      productItem.unload();
    });
  }
}
