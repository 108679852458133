import EventHandler from '@pixelunion/events';
import * as breakpoint from '@pixelunion/breakpoint';

import Accordion from '../Accordion';

export default class FilterGroups {
  constructor(el, options) {
    this.el = el;
    this.filterGroupsSetting = options.groups;
    this.filterStyleSetting = options.style;

    this.Accordion = new Accordion(el);
    this._closeAllGroups();

    // Filter group selectors
    this.activeMenuClass = 'filter-group-active';
    this.activeButtonClass = 'productgrid--sidebar-button-active';
    this.buttonSelector = '[data-filter-group-trigger]';
    this.listSelector = '[data-accordion-content]';
    this.buttonTriggers = this.el.querySelectorAll(this.buttonSelector);

    this.events = new EventHandler();
    this.buttonTriggers.forEach(button => this.events.register(button, 'click', e => this._toggleGroup(e)));

    this._init();
  }

  _init() {
    if (!this.el.querySelector(this.listSelector)) return;

    if (this.filterStyleSetting === 'tags' && breakpoint.max('S')) {
      this._openFirst();
      return;
    }

    switch (this.filterGroupsSetting) {
      case 'expand_all':
        this._openAllGroups();
        break;
      case 'expand_first':
        this._openFirst();
        break;
      default:
        this._closeAllGroups();
        break;
    }
  }

  _openFirst() {
    const button = this.el.querySelector(this.buttonSelector);
    const list = button.parentElement.querySelector(this.listSelector);

    this.openGroup(button, list, true);
  }

  _openAllGroups() {
    this.buttonTriggers.forEach(button => {
      const list = button.parentElement.querySelector(this.listSelector);

      this.openGroup(button, list, true);
    });
  }

  _closeAllGroups() {
    this.Accordion.closeAll({ force: true });
  }

  _toggleGroup(event) {
    const button = event.target;
    const list = button.parentElement.querySelector(this.listSelector);

    list.classList.contains(this.activeMenuClass) ?
      this._closeGroup(button, list) :
      this.openGroup(button, list);
  }

  openGroup(button, list, force = false) {
    list.classList.add(this.activeMenuClass);
    button.classList.add(this.activeButtonClass);

    const onComplete = () => {
      button.setAttribute('aria-expanded', true);
    };

    this.Accordion.open(list, { onComplete, force });
  }

  _closeGroup(button, list) {
    list.classList.remove(this.activeMenuClass);
    button.classList.remove(this.activeButtonClass);

    const onComplete = () => {
      button.setAttribute('aria-expanded', false);
    };

    this.Accordion.close(list, { onComplete });
  }

  unload() {
    this.events.unregisterAll();
  }
}
