import $ from 'jquery';
import {
  trapFocus,
  removeTrapFocus,
} from '@shopify/theme-a11y';
import { transition } from '@pixelunion/animations';
import EventHandler from '@pixelunion/events';
import AsyncView from '@pixelunion/shopify-asyncview';
import MessageBanner from './MessageBanner';
import Images from '../helpers/Images';
import { setupRippleEffect } from '../helpers/Ripple';

export default class AddToCartFlyout {
  constructor(formData, options, callbacks = {}) {
    this.formData = formData;
    this.settings = {
      moneyFormat: null,
      cartRedirection: false,
      ...options.settings,
    };

    this.atcButton = options.atcButton;

    this.header = document.querySelector('[data-site-header]');
    this.announcementBar = document.querySelector('[data-announcement-bar]');
    this.utilityBar = document.querySelector('[data-utility-bar]');
    this.flyOutSelector = '[data-atc-banner]';
    this.atcTemplate = document.querySelector(`[data-templates] ${this.flyOutSelector}`);
    this.recipientForm = document.querySelector('[data-recipient-form]');

    this.activeElement = document.activeElement;
    this.itemId = null;
    this.flyOut = null;

    this._onInit = this._onInit.bind(this);
    this._onError = this._onError.bind(this);
    this._onSuccess = this._onSuccess.bind(this);
    this._onCloseAll = this._onCloseAll.bind(this);

    // Allows ATC Flow to be overridden
    this.callbacks = {
      onInit: this._onInit,
      onError: this._onError,
      onSuccess: this._onSuccess,
      onClose: this._onCloseAll,
      ...callbacks,
    };

    this._handleDocumentClick = this._handleDocumentClick.bind(this);
    this._closeFlyOut = this._closeFlyOut.bind(this);
    this._closeEsc = this._closeEsc.bind(this);

    this.events = new EventHandler();

    this.Images = new Images();
    this.messageBanner = null;

    this.callbacks.onInit();

    this._disableAtcButton();
    this._updateCart();
  }

  unload() {
    if (this.messageBanner) {
      this.messageBanner.unload();
    }
    this._closeFlyOut();
  }

  _updateCart() {
    const flyOut = this.atcTemplate.cloneNode(true);
    const quantityField = this.formData.filter(data => data.name === 'quantity');
    const quantity = quantityField[0].value;

    $.ajax({
      type: 'POST',
      url: `${window.Theme.routes.cart_add_url}.js`,
      data: $.param(this.formData),
      dataType: 'json',
    })
      .done(response => {
        this.itemId = response.id;

        if (response.image) {
          const imageUrl = this.Images.getSizedImageUrl(response.image, '200x');
          this.Images.loadImage(imageUrl);

          const productImage = flyOut.querySelector('[data-atc-banner-product-image]');
          productImage.innerHTML = `<img src="${imageUrl}" alt="${response.product_title}">`;
        }

        const productTitle = flyOut.querySelector('[data-atc-banner-product-title]');
        productTitle.innerHTML = response.product_title;

        /*
          TODO: Bring in `variant.options`, iterate through to get option
            name for: <strong>Option name:</strong> Option
        */
        if (response.variant_options[0] !== 'Title' && response.variant_options[0] !== 'Default Title') {
          const productOptions = flyOut.querySelector('[data-atc-banner-product-options]');
          productOptions.innerHTML = response.variant_options.join(', ');
        }

        if (response.selling_plan_allocation) {
          const productSubscriptionTitle = flyOut.querySelector('[data-atc-banner-product-subscription-title]');
          productSubscriptionTitle.innerHTML = response.selling_plan_allocation.selling_plan.name;
        }

        /*
          TODO: Bring in variant, and use that to check compare_at_price
            to see if the item is on sale
        */
        const productPriceQuantity = flyOut.querySelector('[data-atc-banner-product-price-quantity]');
        productPriceQuantity.innerHTML = `${quantity} × `;

        // Update Free shipping bar contents
        const freeShippingBar = flyOut.querySelector('[data-free-shipping-bar]');

        if (freeShippingBar) {
          AsyncView.load(
            window.Theme.routes.cart_url,
            'static-cart',
          )
            .then(({ html }) => {
              freeShippingBar.innerHTML = html.free_shipping_bar;
            })
            .catch(() => {
              console.error('Error loading content.');
            });
        }

        $.ajax({
          type: 'GET',
          url: `${window.Theme.routes.cart_url}.js`,
          dataType: 'json',
        })
          .done(secondResponse => {
            if (this.settings.cartRedirection || document.body.classList.contains('template-cart')) {
              location.href = window.Theme.routes.cart_url;
              return;
            }

            this.callbacks.onSuccess();

            // Reset formData in case instance is never cleared
            this.formData = {};

            let lineItem = null;
            secondResponse.items.forEach(item => {
              if (item.id === this.itemId) {
                if (!lineItem) {
                  lineItem = item;
                } else {
                  // If there are 2 lineItems with the same id, it means that there is
                  // likely a BOGO offer on the product. We need to grab the highest discounted
                  // price (BOGO will be 0) while also combining with the different discounts on
                  // the product in the discounts array.
                  lineItem.line_level_discount_allocations = lineItem
                    .line_level_discount_allocations.concat(item.line_level_discount_allocations);
                  lineItem.final_price = lineItem.final_price > item.final_price
                    ? lineItem.final_price
                    : item.final_price;
                  lineItem.quantity += item.quantity;
                }
              }
            });

            const productPriceValue = flyOut.querySelector('[data-atc-banner-product-price-value]');
            productPriceValue.innerHTML = Shopify.formatMoney(lineItem.original_price, this.settings.moneyFormat);

            const productPriceDiscounted = flyOut.querySelector('[data-atc-banner-product-price-discounted]');
            if (lineItem.final_price < lineItem.original_price) {
              productPriceDiscounted.innerHTML = Shopify.formatMoney(lineItem.final_price, this.settings.moneyFormat);

              productPriceDiscounted.classList.remove('hidden');
              productPriceValue.classList.add('original-price');
            } else {
              productPriceDiscounted.classList.add('hidden');
              productPriceValue.classList.remove('original-price');
            }

            const productDiscounts = flyOut.querySelector('[data-atc-banner-product-discounts]');

            // Price Per Unit
            const unitPrice = flyOut.querySelector('[data-atc-banner-unit-price]');
            let unitPriceString = unitPrice.innerHTML;

            if (unitPrice && lineItem.unit_price_measurement) {
              unitPriceString = unitPriceString.replace(
                '** total_quantity **',
                `${lineItem.unit_price_measurement.quantity_value}${lineItem.unit_price_measurement.quantity_unit}`,
              );

              unitPriceString = unitPriceString.replace(
                '** unit_price **',
                Shopify.formatMoney(lineItem.unit_price, this.settings.moneyFormat),
              );
              if (lineItem.unit_price_measurement.reference_value === 1) {
                unitPriceString = unitPriceString.replace('** unit_measure **', lineItem.unit_price_measurement.reference_unit);
              } else {
                unitPriceString = unitPriceString.replace(
                  '** unit_measure **',
                  `${lineItem.unit_price_measurement.reference_value}${lineItem.unit_price_measurement.reference_unit}`,
                );
              }
              unitPrice.innerHTML = unitPriceString;
              unitPrice.classList.remove('hidden');
            }

            if (lineItem.line_level_discount_allocations.length > 0) {
              const discountItemTemplate = productDiscounts.firstElementChild.cloneNode(true);
              productDiscounts.innerHTML = '';

              lineItem.line_level_discount_allocations.forEach(discount => {
                const listItem = discountItemTemplate.cloneNode(true);
                const title = listItem.querySelector('.discount-title');
                const amount = listItem.querySelector('.discount-amount');

                title.innerHTML = discount.discount_application.title;
                amount.innerHTML = Shopify.formatMoney(discount.amount, this.settings.moneyFormat);
                productDiscounts.appendChild(listItem);
              });

              productDiscounts.classList.remove('hidden');
            } else {
              productDiscounts.classList.add('hidden');
            }

            const subTotal = flyOut.querySelector('[data-atc-banner-cart-subtotal]');
            subTotal.innerHTML = Shopify.formatMoney(secondResponse.total_price, this.settings.moneyFormat);

            const itemCount = flyOut.querySelector('[data-atc-banner-cart-button] span');
            itemCount.innerHTML = secondResponse.item_count;

            this.header.appendChild(flyOut);

            this.flyOut = flyOut;

            setupRippleEffect(this.flyOut);

            // Notifiy Header of new cart count
            const countEvent = new CustomEvent('cartcount:update', { detail: secondResponse });
            window.dispatchEvent(countEvent);

            /*
            If user has initiated a new ATC Flow before the first has finished,
            the first FlyOut could have opened after the first attempt to close open flyouts so
            we need to send out an event to close any open flyouts.
            */
            document.dispatchEvent(new Event('closeFlyouts'));

            const closeButton = flyOut.querySelector('[data-atc-banner-close]');

            this.events.register(closeButton, 'click', e => this._closeFlyOut(e));
            this.events.register(document, 'click', e => this._handleDocumentClick(e));
            this.events.register(document, 'touchstart', e => this._handleDocumentClick(e));
            this.events.register(document, 'closeFlyouts', e => this._closeFlyOut(e));
            this.events.register(window, 'keydown', e => this._closeEsc(e));

            this._enableAtcButton();

            this.atcAnimation = transition({ el: this.flyOut, state: 'closed' });
            this.atcAnimation.animateTo('open').then(() => {
              trapFocus(this.flyOut);
            });
          });
      })
      .fail(response => {
        let errorText;
        try {
          const responseText = JSON.parse(response.responseText);
          errorText = responseText.description;
        } catch (error) {
          errorText = `${response.status} ${response.statusText}`;
          if (response.status === 401) {
            errorText = `${errorText}. Try refreshing and logging in.`;
          }
        }

        this._enableAtcButton();

        if (errorText.email) {
          this.recipientForm.classList.add('recipient-form--has-errors');
        } else {
          this.callbacks.onError(errorText);
        }
      });
  }

  _onError(error) {
    this.messageBanner = new MessageBanner(error, 'error');
  }

  _onInit() {
    if (this.messageBanner) {
      this.messageBanner.unload();
    }

    if (this.announcementBar) {
      this.announcementBar.style.setProperty('--index-announcement-bar', '1100');
    }

    if (this.utilityBar) {
      this.utilityBar.style.setProperty('--index-utility-bar', '1100');
    }
  }

  _onSuccess() {
    /*
      By default, the ATC Flyout doesn't need any additional success callbacks

      The `this.callbacks.onSuccess` is used to allow other views to initiate
      behaviour when a product has been added to the cart
     */
  }

  _onCloseAll() {
    /*
      By default, the ATC Flyout doesn't need any additional close callbacks

      The `this.callbacks.onClose` is used to allow other views to initiate
      behaviour when the atc banner has been closed
     */
  }

  _closeEsc(e) {
    if (e.key === 'Escape') {
      this._closeFlyOut(e);
    }
  }

  /**
   * Close an open FlyOut
   *
   * @private
   */
  _closeFlyOut() {
    if (!this.flyOut) {
      return;
    }

    removeTrapFocus(this.flyOut);

    // if the user clicked onto the search box, move focus
    // to the search instead of going to the previous active element.
    if (this.documentClickEventTarget && 'liveSearchInput' in this.documentClickEventTarget.dataset) {
      this.documentClickEventTarget.focus();
    } else if (this.activeElement) {
      this.activeElement.focus();
    }

    this.atcAnimation.animateTo('closed').then(() => {
      if (this.announcementBar) {
        this.announcementBar.style.setProperty('--index-announcement-bar', '');
      }

      if (this.utilityBar) {
        this.utilityBar.style.setProperty('--index-utility-bar', '');
      }

      this.callbacks.onClose();
      this.flyOut.remove();
      this.flyOut = null;

      this.events.unregisterAll();
      this.atcAnimation.unload();
      delete this.Images;
    });
  }

  _disableAtcButton() {
    this.atcButton.classList.add('processing');
    this.atcButton.setAttribute('disabled', 'disabled');
  }

  _enableAtcButton() {
    this.atcButton.removeAttribute('disabled');
    this.atcButton.classList.remove('processing');
  }

  _handleDocumentClick(e) {
    const { target } = e;
    const $parent = $(target).parents('[data-atc-banner]');

    if ($parent.length) {
      return;
    }

    this.documentClickEventTarget = target;
    this._closeFlyOut(e);
  }
}
