import debounce from 'just-debounce';
import Flickity from 'flickity';
import FlickityA11yPatch from '../helpers/FlickityA11yPatch';
import layout from '../Layout';

export default class ProductRowScroller {
  constructor(productRow) {
    this.$window = $(window);
    this.flickity = null;
    this.productRow = productRow;
    this.$productRow = $(this.productRow);

    // Activate flickity on mobile
    this._mobileSlider = this._mobileSlider.bind(this);
    layout.onBreakpointChange(this._mobileSlider);
    this._mobileSlider();

    this.flickityA11yPatch = new FlickityA11yPatch(this.productRow);
  }

  unload() {
    layout.offBreakpointChange(this._mobileSlider);
    this._destroyFlickity();
  }

  _initFlickity() {
    this.flickity = new Flickity(this.productRow, {
      cellSelector: '.productgrid--item',
      contain: true,
      freeScroll: true,
      percentPosition: false,
      prevNextButtons: false,
      pageDots: false,
      setGallerySize: false,
    });

    this._bindSlider();
  }

  _destroyFlickity() {
    if (!this.flickity) {
      return;
    }

    this.$window.off('.product-row');
    this.$productRow.off('.product-row');

    this.flickity.destroy();

    if (this.flickityA11yPatch) {
      this.flickityA11yPatch.unload();
    }

    this.flickity = null;
  }

  _mobileSlider() {
    // If is Large layout, attempt to destroy flickity
    if (layout.isGreaterThanBreakpoint('M')) {
      this._destroyFlickity();
      return;
    }

    // Is XS/S/M, and flickity is init'd -- do nothing
    if (this.flickity) {
      return;
    }

    // Is XS/S/M, and flickity is not init'd
    this._initFlickity();
  }

  _bindSlider() {
    const $slider = this.$productRow.find('.flickity-slider');

    this.$window.on('resize.product-row', debounce(() => {
      this.$productRow.trigger('heightUpdate.product-row');
    }));

    this.flickity.on('cellSelect', () => {
      this.$productRow.trigger('heightUpdate.product-row');
    });

    this.$productRow.on('heightUpdate.product-row', () => {
      if (!this.flickity) {
        return;
      }

      $slider.height(Math.ceil(this.flickity.maxCellHeight));
    });

    // Sets the Slider to the height of the first slide
    this.$productRow.trigger('heightUpdate.product-row');
  }
}
