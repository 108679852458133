import $ from 'jquery';
import { transition } from '@pixelunion/animations';
import * as breakpoint from '@pixelunion/breakpoint';
import AsyncView from '@pixelunion/shopify-asyncview';
import Flickity from 'flickity';
import EventHandler from '@pixelunion/events';

import ProductGridItem from '../components/ProductGridItem';
import productCompare from '../components/ProductCompare';
import Modal from '../components/Modal';
import FilterGroups from '../components/FilterGroups';
import RichText from '../components/RichText';

export default class StaticCollection {
  constructor(section) {
    this.section = section;
    this.$el = $(section.el);
    this.el = section.el;
    this.view = null;

    // sub collection section slideshow
    this.flickity = null;
    this.handleEvents = new EventHandler();
    this.subcollectionSlideshow = this.el.querySelector('[data-subcollections-layout="slideshow"]');
    this.slides = this.el.querySelectorAll('[data-subcollections-grid-item]');

    if (this._shouldInitFlickity(breakpoint)) {
      this._initFlickity();
    } else {
      this._destroyFlickity();
    }

    this.context = section.data.context;
    this.showFilterProductCount = section.data.show_filter_product_count;
    this.productCount = section.data.product_count;
    this.filterGroups = section.data.filter_groups;
    this.filterStyle = section.data.filter_style;
    this.noMatchedProductsText = section.data.no_matched_products_text;
    this.collectionUrl = this.context.collectionUrl;
    this.currentTags = this.context.current_tags;
    this.$focusItem = null;
    this.defaultView = this.context.grid_list;
    this.postMessage = section.postMessage;
    this.filterType = section.data.filter_type;

    this.productgridSidebar = '[data-productgrid-sidebar]';
    this.filtersContentSelector = '[data-productgrid-filters-content]';
    this.filterGroupSelector = '[data-productgrid-sidebar-group]';
    this.sortContent = '[data-productgrid-sort-content]';
    this.$sortTrigger = this.$el.find('[data-productgrid-trigger-sort]');
    this.$sortTriggerButton = this.$el.find('[data-productgrid-trigger-sort-button]');
    this.$sortTriggerModal = this.$el.find('[data-productgrid-modal-sort]');
    this.$filtersTrigger = this.$el.find('[data-productgrid-trigger-filters]');
    this.$filtersContent = this.$el.find(this.filtersContentSelector);
    this.$allTags = this.$filtersContent.find('.filter-item a:not([data-filter-toggle])');
    this.$advancedTags = this.$el.find('[data-tag-advanced] a');
    this.$additionalTags = this.$el.find('[data-filter-toggle]');
    this.$viewToggle = this.$el.find('[data-collection-view]');
    this.gridContainer = this.el.querySelector('.productgrid--outer');
    this.$description = this.$el.find('[data-collection-description]');
    this.filterCheckboxes = this.el.querySelectorAll('.filter-icon--checkbox');
    this.stickyUtils = this.el.querySelector('[data-sticky-utils]');
    this.stickyUtilsIntersectionTarget = this.el.querySelector('[data-utils-intersection-target]');
    this.header = document.querySelector('[data-site-header]');
    this.stickyHeaderClass = 'site-header-sticky';

    this.compareToggle = this.el.querySelector('[data-compare-toggle]');

    this._changeSorting = this._changeSorting.bind(this);
    this._changeSortingButton = this._changeSortingButton.bind(this);
    this._showSortModal = this._showSortModal.bind(this);
    this._showFiltersModal = this._showFiltersModal.bind(this);
    this._activateTag = this._activateTag.bind(this);
    this._advancedTags = this._advancedTags.bind(this);
    this._toggleTags = this._toggleTags.bind(this);

    this._toggleView = this._toggleView.bind(this);
    this._checkListView = this._checkListView.bind(this);

    this.events = [
      this.$sortTrigger.on('change.collection', this._changeSorting),
      this.$sortTriggerButton.on('click.collection', this._changeSortingButton),
      this.$sortTriggerModal.on('click.collection', this._showSortModal),
      this.$filtersTrigger.on('click.collection', this._showFiltersModal),
      this.$allTags.on('click.collection', e => this._activateTag(e.currentTarget)),
      this.$additionalTags.on('click.collection', this._toggleTags),
      this.$viewToggle.on('click.collection', this._toggleView),
    ];

    if (this.$description.length) {
      this.richText = new RichText(this.$description);
    }

    // Product items
    this.productItems = [];
    this.fillAnimations = {};
    this.checkAnimations = {};

    this._initAnimations();
    this._setSortByQueryParameters();
    this._checkListView();

    if (this.section.data.enable_product_compare) {
      this._initProductCompare();
    }

    if (breakpoint.max('S')) {
      this._initProductGridUtils();
    }

    // Save all tag handles on the page
    this.handleEls = section.el.querySelectorAll('[data-handle]');
    this.handles = [];
    this.handleEls.forEach(current => {
      this.handles.push(current.dataset.handle);
    });

    this.modal = new Modal();

    if (this.$filtersContent.length > 0) {
      const options = {
        groups: this.filterGroups,
        style: this.filterStyle,
      };
      this.filterGroupAccordions = new FilterGroups(this.$filtersContent[0], options);

      this._initTags();
    }

    this.handleBreakpointChange = breakpoints => this.onBreakpointChange(breakpoints);
    breakpoint.onChange(this.handleBreakpointChange);
  }

  _initProductCompare() {
    const onEnableChange = enabled => {
      this.compareToggle.checked = enabled;
    };

    onEnableChange(productCompare.enabled);

    productCompare.addRunOnEnableChange(onEnableChange);

    this.handleEvents.register(this.compareToggle, 'change', () => {
      if (this.compareToggle.checked) {
        productCompare.enable();
      } else {
        productCompare.disable();
      }
    });
  }

  /**
   * Initialize animations on checkbox container
   * using filter tag as JSON key
   */
  _initAnimations() {
    this.filterCheckboxes.forEach(el => {
      const tagHandle = el.dataset.handle;
      const checkmark = el.querySelector('.checkmark');
      const checkmarkCheck = el.querySelector('.checkmark__check');
      let state = 'unchecked';

      if (el.closest('.filter-item').dataset.filterActive === 'true') {
        state = 'checked';
      }

      const fillAnimation = transition({ el: checkmark, state });
      const checkAnimation = transition({ el: checkmarkCheck, state });

      this.fillAnimations[tagHandle] = fillAnimation;
      this.checkAnimations[tagHandle] = checkAnimation;
    });
  }

  onSectionUnload() {
    this._destroyFlickity();
    breakpoint.offChange(this.handleBreakpointChange);
    this.handleEvents.unregisterAll();

    this.events.forEach($el => $el.off('.collection'));
    this.modal.unload();

    Object.keys(this.fillAnimations).forEach(key => {
      this.fillAnimations[key].unload();
      this.checkAnimations[key].unload();
    });

    this.productItems.forEach(productItem => {
      productItem.unload();
    });

    if (this.$filtersContent.length > 0) {
      this.filterGroupAccordions.unload();
    }

    if (this.observer) {
      this.observer.disconnect();
    }
  }

  _initFlickity() {
    if (this.flickity) {
      return;
    }

    this.flickity = new Flickity(this.subcollectionSlideshow, {
      autoPlay: 0,
      accessibility: true,
      cellAlign: 'left',
      cellSelector: '[data-subcollections-grid-item]',
      groupCells: true,
      contain: true,
      pageDots: false,
      arrowShape: 'M65.29 11.99L27.28 50L65.3 87.99L70.25 83.06L37.19 50L70.26 16.94L65.29 11.99Z',
    });

    const viewport = this.subcollectionSlideshow.querySelector('.flickity-viewport');
    const slider = this.subcollectionSlideshow.querySelector('.flickity-slider');
    const sliderWrapper = document.createElement('div');
    sliderWrapper.classList.add('flickity-slider--wrapper');
    viewport.appendChild(sliderWrapper);
    sliderWrapper.appendChild(slider);

    this.handleEvents.register(this.subcollectionSlideshow, 'rimg:load', () => {
      if (this.flickity) {
        this.flickity.resize();
      }
    });
  }

  _destroyFlickity() {
    if (!this.flickity) {
      return;
    }

    this.flickity.destroy();
    this.flickity = null;
  }

  _initProductItems(view = 'grid-view') {
    const $productItems = this.$el.find('[data-product-item]');

    $productItems.each((i, productItem) => {
      this.productItems.push(
        new ProductGridItem({
          el: productItem,
          id: this.section.id,
          lazy: true,
          grid_list: view,
        }),
      );
    });
  }

  _shouldInitFlickity(bp) {
    if (
      (bp.max('XXXS') && this.slides.length > 3)
      || (bp.max('M') && this.slides.length >= 5)
      || (bp.min('L') && this.slides.length > 7)
    ) {
      return true;
    }

    if (this.subcollectionSlideshow) return false;

    return null;
  }

  /**
   * Open Tags/Filters modal (on mobile)
   *
   * @param event
   * @private
   */
  _showFiltersModal(event) {
    event.preventDefault();

    this.$focusItem = $(event.currentTarget);
    this.modal.open(this.productgridSidebar, 'productgrid-sidebar');
  }

  /**
   * Open Sort by modal (on mobile)
   *
   * @param event
   * @private
   */
  _showSortModal(event) {
    event.preventDefault();

    this.$focusItem = $(event.currentTarget);
    this.modal.open(this.sortContent, 'productgrid-sort');
  }

  _deactivateTags(currentFilterItem) {
    const currentGroup = currentFilterItem.closest('[data-filter-group]');
    const activeTags = currentGroup.querySelectorAll('[data-filter-active="true"]');

    activeTags.forEach(el => {
      const itemTag = el.dataset.handle;

      currentGroup.querySelector(`.filter-item[data-handle='${itemTag}']`).dataset.filterActive = false;

      if (this.fillAnimations[itemTag] && this.checkAnimations[itemTag]) {
        this.fillAnimations[itemTag].animateTo('unchecked');
        this.checkAnimations[itemTag].animateTo('unchecked');
      }
    });
  }

  /**
   * Style a tag as active after click, before page transition
   *
   * @param event
   * @private
   */
  _activateTag(target) {
    event.preventDefault();
    const href = target.getAttribute('href');
    const filterItem = target.closest('.filter-item');
    const filterItemTag = filterItem.dataset.handle;
    const isDisabled = filterItem.classList.contains('filter-item--disabled');

    if (isDisabled) {
      return;
    }

    let animateTo = 'checked';

    if (filterItem.dataset.filterActive === 'true') {
      animateTo = 'unchecked';
    }

    this._deactivateTags(filterItem);
    if (animateTo === 'checked') {
      filterItem.dataset.filterActive = true;
    } else {
      filterItem.dataset.filterActive = false;
    }

    if (this.fillAnimations[filterItemTag] && this.checkAnimations[filterItemTag]) {
      this.fillAnimations[filterItemTag].animateTo(animateTo);
      this.checkAnimations[filterItemTag]
        .animateTo(animateTo)
        .then(() => {
          if (this.$advancedTags.length === 0) {
            location.href = href;
          } else {
            this._advancedTags($(target));
          }
        });
    } else {
      // If the animation is not registered to the tag, it is a swatch and should
      // go straight to the advanced tags function
      this._advancedTags($(target));
    }
  }

  /**
   * Used by advanced tags to concatenate tag searches
   *
   * @param event
   * @private
   */
  _advancedTags(link) {
    const $target = link.parent();
    const $filtersContent = $target.closest('nav');
    const filterGroups = $filtersContent.find('[data-filter-group]');
    const filterHandles = [];

    // Build the filter for the url based on what is in the dom
    filterGroups.each((index, filterGroup) => {
      const selectedItems = filterGroup.querySelectorAll('[data-filter-active="true"]');

      if (selectedItems.length) {
        filterHandles.push($(selectedItems).data('handle'));
      }
    });

    /*
      If any of the current tags are not available on the page, it may be a menu item
      filtered by a non-grouped tag, so this tag should be added to the filters.
    */
    if (this.currentTags.length) {
      this.currentTags.forEach(tag => {
        if (!this.handles.includes(tag)) filterHandles.push(tag);
      });
    }

    this._updateLocation(filterHandles.join('+'));
  }

  _updateLocation(filter) {
    if (this.collectionUrl.indexOf('vendors') > -1) {
      location.href = `${this.collectionUrl}&constraint=${filter}`;
    } else {
      location.href = `${this.collectionUrl}/${filter}`;
    }
  }

  /**
   * Expand / Collapse additional tags in the sidebar
   *
   * @param event
   * @private
   */
  _toggleTags(event) {
    event.preventDefault();

    const $trigger = $(event.currentTarget);
    const $items = $trigger.parent().siblings('[data-hidden-default]');
    const siblingsVisible = $trigger.data('filter-toggle');

    $items.toggleClass('filter-item--hidden', siblingsVisible);
    $trigger
      .data('filter-toggle', !siblingsVisible)
      .text(!siblingsVisible ? this.context.see_less : this.context.see_more);

    if (this.modal.isOpen()) {
      this.modal.position();
    }
  }

  /**
   * Make Shopify aware of releavent collection search info
   *  - tag
   *  - vendor
   *  - pagination
   *  - sorting criteria
   *
   * @private
   */
  _setSortByQueryParameters() {
    Shopify.queryParams = {};
    if (location.search.length) {
      for (let i = 0, aCouples = location.search.substr(1).split('&'); i < aCouples.length; i++) {
        const aKeyValue = aCouples[i].split('=');
        // Reset the page number when we apply (i.e. don't add it to params)
        if (aKeyValue.length > 1 && aKeyValue[0] !== 'page') {
          Shopify.queryParams[decodeURIComponent(aKeyValue[0])] = decodeURIComponent(aKeyValue[1]);
        }
      }
    }
  }

  /**
   * Sort by opens a modal on mobile, this handles button events
   *
   * @param event
   * @private
   */
  _changeSortingButton(event) {
    const activeClass = 'utils-sortby--modal-button--active';

    $(event.currentTarget)
      .addClass(activeClass)
      .parent()
      .siblings()
      .find(`.${activeClass}`)
      .removeClass(activeClass);

    this._changeSorting(event);
  }

  /**
   * Change sorting of collection
   *
   * @param event
   * @private
   */
  _changeSorting(event) {
    event.preventDefault();
    const $target = $(event.currentTarget);

    Shopify.queryParams.sort_by = $target.val();
    location.search = jQuery.param(Shopify.queryParams).replace(/\+/g, '%20');
  }

  /**
   * Toggle grid or list view
   *
   */
  _toggleView(event) {
    const $target = $(event.currentTarget);
    Shopify.queryParams.grid_list = $target.data('collection-view');
    location.search = jQuery.param(Shopify.queryParams).replace(/\+/g, '%20');
  }

  /**
   * Check grid/list view toggle query parameters
   *
   */
  _checkListView() {
    const view = Shopify.queryParams.grid_list ? Shopify.queryParams.grid_list : this.defaultView;
    this.$el.find('[href*="&grid_list"]')
      .attr('href',
        (i, url) => {
          let href = url;
          if (url.indexOf('?') < 0) {
            const replaceIndex = url.indexOf('&');
            const firstHalf = url.substr(0, replaceIndex);
            const secondHalf = url.substr(replaceIndex + 1);

            href = firstHalf.concat('?', secondHalf);
          }
          href = href.replace('grid_list', `grid_list=${view}`);
          return href;
        });

    this.$el.find('.utils-viewtoggle-button').removeClass('active');
    $(`[data-collection-view=${view}]`).addClass('active');
    const className = view.replace('-', '');
    this.gridContainer.classList.add(`productgrid-${className}`);

    if (className === 'listview') {
      this.gridContainer.classList.remove('productgrid-gridview');
    } else {
      this.gridContainer.classList.remove('productgrid-listview');
    }

    this.view = view;

    this._initProductItems(view);
  }

  _initTags() {
    const inactiveTags = this.$filtersContent[0].querySelectorAll('[data-filter-active="false"]');
    const activeTags = this.$filtersContent[0].querySelectorAll('[data-filter-active="true"]');

    activeTags.forEach(tag => {
      if (tag.querySelector('.filter-item--swatch-wrapper') === null) {
        this._addProductCount(tag, true);
      }
      this._openActiveGroup(tag);
    });

    inactiveTags.forEach(tag => {
      this._addProductCount(tag, false);
    });
  }

  /**
* Get & set the product counts for each filter tag in the sidebar
*
*/
  _addProductCount(tag, active) {
    if (this.filterType === 'faceted' || (this.showFilterProductCount === false && this.filterStyle !== 'groups')) return;

    const productCountEl = tag.querySelector('[data-filtered-product-count]');

    if (this.collectionUrl.includes('/collections/vendors')) return;

    if (active) {
      productCountEl.innerHTML = `(${this.productCount})`;
    } else {
      const tagLinkEl = tag.querySelector('a');
      let url = tagLinkEl.getAttribute('href');
      url = url.split('?')[0];

      AsyncView.load(url, 'ajax-product-count')
        .then(_ref => {
          if (!_ref) return;
          const { data } = _ref;
          const count = data.product_count;

          productCountEl.innerHTML = `(${count})`;

          if (count === 0) {
            const title = this.noMatchedProductsText.replace('*tag*', tag.dataset.tagTitle);
            tag.classList.add('filter-item--disabled');

            tagLinkEl.ariaDisabled = true;
            tagLinkEl.title = title;
          }
        });
    }
  }

  _openActiveGroup(tag) {
    const button = this.filterStyle === 'tags'
      ? this.el.querySelector(this.filtersContentSelector)
      : tag.closest(this.filterGroupSelector).querySelector('[data-filter-group-trigger]');

    const list = tag.closest('[data-accordion-content]');
    this.filterGroupAccordions.openGroup(button, list, true);

    const tagIsHidden = 'hiddenDefault' in tag.dataset;
    if (tagIsHidden) {
      const toggle = tag.parentElement.querySelector('[data-filter-toggle="false"]');
      toggle.click();
    }
  }

  _initProductGridUtils() {
    const header = document.querySelector('[data-site-header-main]');
    const body = document.querySelector('body');
    const headerHeight = `${header.offsetHeight}px`;
    /*
      The utility bar should be positioned directly below the header when it is sticky.
      Then, we use the negative header height value to tell the IntersectionObserver when
      the utility bar has become sticky.
      If the header isn't sticky, place the utility bar at the top of the window.
    */
    if (body.classList.contains(this.stickyHeaderClass)) {
      this.stickyUtils.style.top = headerHeight;
      this.stickyUtilsIntersectionTarget.style.top = `-${headerHeight}`;
    } else {
      this.stickyUtils.style.top = '0';
      this.stickyUtilsIntersectionTarget.style.top = '0';
    }

    this._observeHeaders(this.el);
  }

  /**
 * Sets up an intersection observer to notify when the utility bar
 * becomes sticky/not-sticky.
 *
 */
  _observeHeaders() {
    this.observer = new IntersectionObserver(records => {
      records.forEach(record => {
        const targetInfo = record.boundingClientRect;
        const stickyTarget = record.target.parentElement.querySelector('[data-sticky-utils]');
        const rootBoundsInfo = record.rootBounds;

        // Started sticking.
        if (targetInfo.bottom < rootBoundsInfo.top) {
          this.postMessage('collection-page:collection-utils-sticky-change', { stuck: true, target: stickyTarget });
          this._handleStickyChange({ stuck: true, target: stickyTarget });
        }

        // Stopped sticking.
        if (targetInfo.bottom >= rootBoundsInfo.top && targetInfo.bottom < rootBoundsInfo.bottom) {
          this.postMessage('collection-page:collection-utils-sticky-change', { stuck: false, target: stickyTarget });
          this._handleStickyChange({ stuck: false, target: stickyTarget });
        }
      });
    });

    // Add the top sentinels to each section and attach an observer.
    this.observer.observe(document.querySelector('[data-utils-intersection-target]'));
  }

  _fireEvent(stuck, target) {
    // Inform header that it should remove its box-shadow once the utility bar has become sticky.
    this.postMessage('collection-page:collection-utils-sticky-change', { stuck, target });
  }

  _handleStickyChange(data) {
    const { stuck, target } = data;
    target.classList.toggle('productgrid--utils-box-shadow', stuck);
  }

  onBreakpointChange(breakpoints) {
    if (this.observer) {
      this.observer.disconnect();
    }

    if (breakpoints.current.max('S')) {
      this.headerTransitionEnd = () => {
        this._initProductGridUtils();
        this.header.removeEventListener('transitionend', this.headerTransitionEnd);
      };

      this.header.addEventListener('transitionend', this.headerTransitionEnd);
    }

    if (this._shouldInitFlickity(breakpoint)) {
      this._initFlickity();
    } else {
      this._destroyFlickity();
    }
  }
}
