import rimg from '@pixelunion/rimg-shopify';
import * as breakpoint from '@pixelunion/breakpoint';

export default class DynamicCollectionList {
  constructor(section) {
    this.el = section.el;
    const collectionListImages = this.el.querySelectorAll('[data-collection-image]');

    breakpoint.onChange(breakpoints => {
      if (breakpoints.previous.max('L') && breakpoints.current.value === 'S') {
        for (let i = 0; i < collectionListImages.length; i++) {
          rimg.instance.update(collectionListImages[i]);
        }
      }
    });
  }
}
