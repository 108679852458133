const storageKey = 'pxuProductCompareV3';

const MAX_COMPARE_PRODUCTS = 3;

class ProductCompare {
  constructor() {
    this._hasToggle = !!document.querySelector('[data-compare-toggle]');
    this.breadCrumb = document.querySelector('[data-product-compare-breadcrumb-data]');

    if (this.breadCrumb) {
      this.breadCrumbData = JSON.parse(this.breadCrumb.innerHTML);
    }

    this._state = this._getState();
    this._onUpdateCallbacks = [];
    this._onEnableChangeCallbacks = [];

    // update returnBreadcrumb immediately before user navigates to a new page
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'hidden') {
        this._state.returnBreadcrumb = this._getReturnBreadcrumb();
        this._save();
      }
    });
  }

  /**
   *
   * @param {String} param0.handle Handle to add
   * @param {Object} param0.data Additional product data to add (title, image, etc).
   * Will persist until product is removed or session ends
   */
  add({ handle, data }) {
    this._state.products.push({
      handle,
      data,
    });
    this._runUpdateCallbacks();
    this._save();
  }

  /**
   *
   * @param {String} handle Handle to remove from product compare
   */
  remove(handle) {
    this._state.products = this._state.products.filter(product => product.handle !== handle);
    this._runUpdateCallbacks();
    if (!this._state.products.length) {
      this._onRemoveLastProduct();
    }
    this._save();
  }

  /**
   * Removes all products from compare
   */
  removeAll() {
    this._state.products = [];
    this._runUpdateCallbacks();
    this._onRemoveLastProduct();
    this._save();
  }

  /**
   *
   * @param {String} handle Handle to check
   * @returns Boolean, true if handle is currently added to product compare
   */
  includes(handle) {
    return this._state.products.some(product => product.handle === handle);
  }

  /**
   *
   * @param {Function} fn Add a callback function to run every time a product is added or removed
   */
  runOnUpdate(fn) {
    this._onUpdateCallbacks.push(fn);
  }

  /**
   *
   * @param {Function} fn Remove a previously added callback function
   */
  removeRunOnUpdate(fn) {
    this._onUpdateCallbacks = this._onUpdateCallbacks.filter(cb => cb !== fn);
  }

  get enabled() {
    return this._state.enabled;
  }

  enable() {
    if (this._state.enabled) return;

    this._state.enabled = true;
    this._runEnableChangeCallbacks();
    this._save();
  }

  disable() {
    if (!this._state.enabled) return;

    this._state.enabled = false;
    this._runEnableChangeCallbacks();
    if (this._state.products.length) {
      this.removeAll();
    }
    this._save();
  }

  addRunOnEnableChange(fn) {
    this._onEnableChangeCallbacks.push(fn);
  }

  /**
   *
   * @param {Function} fn Remove a previously added callback function
   */
  removeRunOnEnableChange(fn) {
    this._onEnableChangeCallbacks = this._onEnableChangeCallbacks.filter(cb => cb !== fn);
  }

  get products() {
    return this._state.products;
  }

  get atProductLimit() {
    return this._state.products.length >= MAX_COMPARE_PRODUCTS;
  }

  get returnBreadcrumb() {
    return this._state.returnBreadcrumb;
  }

  _runUpdateCallbacks() {
    const data = {
      products: this._state.products,
      atProductLimit: this.atProductLimit,
    };

    this._onUpdateCallbacks.forEach(cb => cb(data));
  }

  _runEnableChangeCallbacks() {
    this._onEnableChangeCallbacks.forEach(cb => cb(this.enabled));
  }

  _onRemoveLastProduct() {
    if (this._hasToggle) return;

    this.disable();
  }

  _getReturnBreadcrumb() {
    if (this.breadCrumbData && this.breadCrumbData.update) {
      return {
        url: window.location.href,
        title: this.breadCrumbData.title,
      };
    }

    return this._state.returnBreadcrumb;
  }

  _getState() {
    const savedState = this._load();

    if (savedState) {
      const enabled = this._hasToggle || savedState.products.length ? savedState.enabled : false;
      return {
        ...savedState,
        enabled,
      };
    }

    return {
      products: [],
      returnBreadcrumb: null,
      enabled: false,
    };
  }

  _load() {
    try {
      return JSON.parse(sessionStorage.getItem(storageKey));
    } catch (e) {
      return null;
    }
  }

  _save() {
    sessionStorage.setItem(storageKey, JSON.stringify(this._state));
  }
}

export default new ProductCompare();
