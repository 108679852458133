import $ from 'jquery';
import Forms from '../Forms';

export default class Account {
  constructor() {
    this.$accountContents = $('[data-template-account]');
    this.$loginContent = $('[data-template-account-login]');
    this.$addressesContent = $('[data-template-account-addresses]');
    this.$addressButtonFocus = null;
    this._loginToggle = this._loginToggle.bind(this);

    if (this.$loginContent.length) {
      this._initLoginPage();
    }

    if (this.$addressesContent.length) {
      this._initAddressPage();
    }

    if (this.$accountContents.length) {
      this._init();
    }
  }

  _init() {
    new Forms(this.$accountContents);
  }

  _initLoginPage() {
    this.$loginToggle = this.$loginContent.find('[data-login-toggle]');
    this.$login = this.$loginContent.find('[data-account-login-main]');
    this.$recovery = this.$loginContent.find('[data-account-login-recovery]');
    this.$recoveryHasMessage = this.$recovery.find('[data-recovery-has-message]');

    this.$loginToggle.on('click', this._loginToggle);

    if (this.$recoveryHasMessage.length) {
      this._loginToggle();
    }
  }

  _loginToggle(event = null) {
    if (event) {
      event.preventDefault();
    }

    this.$login.toggleClass('visible');
    this.$recovery.toggleClass('visible');
  }

  _initAddressPage() {
    const $customerAddresses = this.$addressesContent.find('[data-address-id]');

    this.$addressesContent.on('click', '[data-edit-address]', event => {
      const $target = $(event.currentTarget);
      const itemId = $target.attr('data-edit-address');

      $customerAddresses.removeClass('visible');
      this.$addressButtonFocus = $target;

      $(`[data-address-id="${itemId}"]`)
        .addClass('visible')
        .find('.form-field-input')
        .eq(0)
        .focus();
    });

    this.$addressesContent.on('click', '[data-edit-address-cancel]', () => {
      $customerAddresses.removeClass('visible');
      $('[data-address-id="new"]').addClass('visible');

      // Return focus to last used button
      this.$addressButtonFocus.focus();
      this.$addressButtonFocus = null;
    });

    this.$addressesContent.on('click', '[data-delete-address]', event => {
      const itemId = $(event.target).attr('data-delete-address');
      Shopify.CustomerAddress.destroy(itemId, '');
    });

    $customerAddresses.each((i, el) => {
      const id = $(el).attr('data-address-id');

      const countryEl = `customer_addr_${id}_country`;
      const provinceEl = `customer_addr_${id}_province`;
      const options = { hideElement: `address_province_container_${id}` };

      // Initiate provinces for address forms
      new Shopify.CountryProvinceSelector(countryEl, provinceEl, options);
    });
  }
}
