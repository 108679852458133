import { transition } from '@pixelunion/animations';

const el = document.querySelector('[data-site-main-dimmer]');
const animation = transition({ el, state: 'closed' });
const openers = new Set();

const disableAnimations = 'reduceAnimations' in document.body.dataset;

export function dim(requestor) {
  if (disableAnimations) return;
  if (openers.has(requestor)) return;
  openers.add(requestor);
  animation.animateTo('open');
}

export function clear(requestor) {
  openers.delete(requestor);
  if (openers.size) return;
  animation.animateTo('closed', { force: disableAnimations });
}
