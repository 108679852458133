import $ from 'jquery';
import debounce from 'just-debounce';
import { transition } from '@pixelunion/animations';
import EventHandler from '@pixelunion/events';
import rimg from '@pixelunion/rimg-shopify';

import * as SiteMainDimmer from '../../helpers/site-main-dimmer';
import ScrollLock from '../../helpers/ScrollLock';
import layout from '../../Layout';
import SearchForm from './SearchForm';

export default class LiveSearch {
  constructor(els, settings) {
    this.$el = $(els.el);
    this.$header = $(els.header);

    this.closeEvents = new EventHandler();
    this.closeEventRequestors = new Set();

    this.settings = settings;
    this.enableImages = this.settings.enable_images;
    this.mobileSearchBar = this.settings.show_mobile_search_bar;
    this.category = '';

    this.$input = this.$el.find('[data-live-search-input]');
    this.$button = this.$el.find('[data-live-search-submit]');
    this.$takeOverButton = this.$el.find('[data-live-search-takeover-cancel]');
    this.$filter = this.$el.find('[data-live-search-filter]');

    this.$flyDown = this.$el.find('[data-live-search-flydown]');
    this.$searchResults = this.$flyDown.find('[data-live-search-results]');
    this.$searchPlaceholder = this.$flyDown.find('[data-live-search-placeholder]');
    this.$quickLinks = this.$flyDown.find('[data-live-search-quick-links]');

    this._onClose = () => {};

    this.disableAnimations = 'reduceAnimations' in document.body.dataset;
    this.animationFlyDown = transition({
      el: this.$flyDown.get(0),
      state: 'closed',
    });
    this.animationSearchResults = transition({
      el: this.$searchResults.get(0),
      state: 'closed',
    });
    this.animationTakeover = transition({
      el: this.$el[0],
      state: 'closed',
    });
    if (this.$quickLinks.length) {
      this.animationQuickLinks = transition({
        el: this.$quickLinks.get(0),
        state: 'closed',
      });
    }

    this.staticSearch = new SearchForm(
      this.$el[0],
      {
        liveSearch: true,
        setCategory: category => { this.category = category; },
      },
    );

    this._search = this._search.bind(this);
    this._documentFocus = this._documentFocus.bind(this);

    this._closeEsc = e => {
      if (e.key === 'Escape') {
        e.stopPropagation();
        this._closeFlyDown(true);
        this._closeTakeOver();
      }
    };

    this.events = [
      this.$input.on('keyup.live-search', debounce(this._search, 250)),
      this.$input.on('focus.live-search', event => {
        event.stopPropagation();
        this._onSearchFocus(event);
      }),
      this.$takeOverButton.on('click.live-search', event => {
        event.preventDefault();
        this._closeFlyDown(true);
        this._closeTakeOver();
      }),
      this.$filter.on('change.live-search', event => {
        const terms = this.$input.val();
        const hasTerms = terms.length > 0;

        if (!hasTerms) return;

        this._search(event);
      }),
      $(window).on('resize', debounce(() => {
        // Only recalculate and open flydown if the flydown is already open.
        // This helps to prevent quick links from opening on resize.
        if (this._isFlydownOpen) this._openFlyDown();
      }, 250)),
    ];
  }

  open() {
    // If is XS, pop the search out into a Takeover screen
    if (layout.isLessThanBreakpoint('S')) {
      this._openTakeOver();
    }
    this._openFlyDown();

    this.$input.focus();
  }

  set onClose(onClose) {
    this._onClose = onClose;
  }

  unload() {
    this.events.forEach($el => $el.off('.live-search'));
    this.closeEvents.unregisterAll();
    this.closeEventRequestors.clear();

    if (this.settings.use_dimmer) {
      SiteMainDimmer.clear(this);
    }
    this.staticSearch.unload();

    ScrollLock.unlock();
  }

  get _terms() {
    return this.$input.val();
  }

  get _hasTerms() {
    return this._terms.trim().length > 0;
  }

  get _isFlydownOpen() {
    return this.$flyDown.attr('data-animation-state') === 'open';
  }

  _onSearchFocus(event) {
    this.$el.addClass('live-search--focused');

    // If is XS, pop the search out into a Takeover screen
    if (layout.isLessThanBreakpoint('S')) {
      if (!this._hasTerms) return;
      this._openTakeOver();
    }

    this._search(event);
  }

  _search(event) {
    // Ignore non character key strokes
    const invalidKeyStrokes = [
      'Alt',
      'ArrowRight',
      'ArrowLeft',
      'ArrowUp',
      'ArrowDown',
      'Capslock',
      'Control',
      'Escape',
      'Meta',
      'Shift',
      'Tab',
      'Enter',
    ];

    if (event.key && invalidKeyStrokes.indexOf(event.key) !== -1) {
      return;
    }

    let terms = this._terms;

    // If is XS, pop the search out into a Takeover screen
    if (layout.isLessThanBreakpoint('S')) {
      this._openTakeOver();
    }

    this._toggleButton(this._hasTerms);

    if (this._hasTerms) {
      // Clear previous results
      this.$searchResults.html('');

      this._openFlyDown(true);

      const filter = this.$filter[0];

      // Filter should always come before the input terms, otherwise Shopify won't
      // return the expected results. Explicitly use an `AND` so splitting in Liquid becomes easier.
      // https://help.shopify.com/en/manual/online-store/storefront-search#prefix-search
      terms = filter && filter.value ? `${filter.value} AND ${terms}` : terms;

      fetch(`${window.Theme.routes.predictive_search_url}?q=${encodeURIComponent(terms)}&section_id=predictive-search`)
        .then(response => {
          if (!response.ok) {
            throw new Error(response.status);
          }
          return response.text();
        })
        .then(html => {
          this.$searchResults.html(html);
          rimg.watch(this.$searchResults[0]);
          this._openFlyDown();
          this._toggleButton(false);
        })
        .catch(error => {
          throw error;
        });
    } else if (this.$quickLinks.length) {
      this._openFlyDown();
    } else {
      this._closeFlyDown(true);
    }
  }

  _searchError(response) {
    console.warn('Search had error');
    console.log(response.message, response.error, response.event);
    this._toggleButton(false);
  }

  /**
   * Toggles search button while processing
   *
   * @param disable
   * @private
   */
  _toggleButton(disable) {
    if (disable) {
      this.$button
        .addClass('search-icon--processing')
        .attr('disabled');
    } else {
      this.$button
        .removeClass('search-icon--processing')
        .removeAttr('disabled');
    }
  }

  _shouldOpenFlyDown(placeholder = false) {
    if (placeholder) return true;

    const hasTerms = this.$input.val().length > 0;
    const hasNoResults = this.$searchResults.find('[data-live-search-no-products]').length > 0;
    const hasResults = this.$searchResults.children().length > 0;
    const hasQuickLinks = this.$quickLinks.length;

    return (hasTerms && (hasResults || hasNoResults)) || (!hasTerms && hasQuickLinks);
  }

  _registerCloseEvents(requestor) {
    if (this.closeEventRequestors.size === 0) {
      this.closeEvents.register(window, 'keydown', this._closeEsc);
      this.closeEvents.register(document, 'focusin', this._documentFocus);
      this.closeEvents.register(document, 'touchstart', this._documentFocus);
      this.closeEvents.register(document, 'click', this._documentFocus);
    }

    this.closeEventRequestors.add(requestor);
  }

  _unregisterCloseEvents(requestor) {
    this.closeEventRequestors.delete(requestor);
    if (this.closeEventRequestors.size) return;
    this.closeEvents.unregisterAll();
    this._onClose();
  }

  _openFlyDown(placeholder = false) {
    if (!this._shouldOpenFlyDown(placeholder)) return;

    const resize = ({ el }) => {
      // When this function is called, element is `display: block; height: 0;`
      // but the wrapper's scrollHeight is the height we want to transition to
      const container = el.querySelector(':scope > .visible');
      const scrollHeight = container ? container.scrollHeight : 0;
      el.style.setProperty('--open-height', `${scrollHeight}px`);

      // Ensure the flydown height doesn't exceed the viewport/body
      const viewportHeight = el.closest('[data-site-header]')
        ? document.documentElement.clientHeight
        : document.documentElement.scrollHeight;
      const topPos = container.getBoundingClientRect().top + (
        el.closest('[data-site-header]') ? 0 : window.scrollY
      );
      const offset = 30;
      const maxHeight = viewportHeight - topPos - offset;
      el.style.setProperty('--flydown-max-height', `${maxHeight}px`);
    };

    this._updateFlyDown(placeholder);

    if (!this.$flyDown.data('is-open')) {
      this._registerCloseEvents('flydown');

      if (this.settings.use_dimmer) {
        SiteMainDimmer.dim(this);
      }
      this.$flyDown.data('is-open', true);
      this.animationFlyDown.animateTo('open', { force: this.disableAnimations, onStart: resize })
        .then(() => this.$el.addClass('live-search--active'));
    } else {
      resize({ el: this.$flyDown.get(0) });
    }
  }

  _updateFlyDown(placeholder = false) {
    const hasTerms = this.$input.val().length > 0;
    const hasNoResults = this.$searchResults.find('[data-live-search-no-products]').length > 0;
    const hasResults = this.$searchResults.children().length > 0;
    const hasQuickLinks = this.$quickLinks.length;

    if (placeholder) {
      this.$searchResults.removeClass('visible');
      this.$quickLinks.removeClass('visible');
      this.$searchPlaceholder.addClass('visible');
      if (this.animationQuickLinks) {
        this.animationQuickLinks.animateTo('hidden', { force: this.disableAnimations });
      }
      this.animationSearchResults.animateTo('hidden', { force: this.disableAnimations });
    } else if (hasTerms && (hasNoResults || hasResults)) {
      this.$searchPlaceholder.removeClass('visible');
      this.$quickLinks.removeClass('visible');
      this.$searchResults.addClass('visible');
      if (this.animationQuickLinks) {
        this.animationQuickLinks.animateTo('hidden', { force: this.disableAnimations });
      }
      this.animationSearchResults.animateTo('visible', { force: this.disableAnimations });
    } else if (hasQuickLinks) {
      this.$searchPlaceholder.removeClass('visible');
      this.$searchResults.removeClass('visible');
      this.$quickLinks.addClass('visible');
      this.animationSearchResults.animateTo('hidden', { force: this.disableAnimations });
      this.animationQuickLinks.animateTo('visible', { force: this.disableAnimations });
    }
  }

  /**
   * Close the FlyDown when no longer needed
   *  - Keep focus styling if input is still being interacted with
   *
   * @param retainFocus
   * @private
   */
  _closeFlyDown(retainFocus = false) {
    if (!this.$flyDown.data('is-open')) {
      return;
    }

    this._unregisterCloseEvents('flydown');

    SiteMainDimmer.clear(this);
    this.$flyDown.data('is-open', false);
    this.$searchPlaceholder.removeClass('visible');
    this.$quickLinks.removeClass('visible');
    this.$searchResults.removeClass('visible');
    if (this.animationQuickLinks) {
      this.animationQuickLinks.animateTo('closed', { force: this.disableAnimations });
    }
    this.animationSearchResults.animateTo('closed', { force: this.disableAnimations });
    this.animationFlyDown
      .animateTo('closed', { force: this.disableAnimations, onStart: ({ el }) => el.style.setProperty('--open-height', '0') })
      .then(() => {
        this.$el.removeClass('live-search--active');

        if (!retainFocus) {
          this.$el.removeClass('live-search--focused');
        }
      });
  }

  _openTakeOver() {
    if (this.animationTakeover.state === 'open') return;

    if (this.$header.hasClass('search--section')) {
      document.body.classList.add('search-takeover-active');
    }
    ScrollLock.lock(this.$el[0]);
    this.$el.addClass('live-search--takeover');
    document.body.classList.add('mobile-search-takeover-active');
    this._registerCloseEvents('takeover');
    if (this.settings.use_dimmer) {
      SiteMainDimmer.dim(this);
    }
    if (this.mobileSearchBar) {
      // "Expand out" style
      const el = this.$el[0];
      const {
        top,
        right,
        left,
        width,
      } = el.getBoundingClientRect();

      el.style.setProperty('--live-search-takeover-initial-top', `${top}px`);
      el.style
        .setProperty('--live-search-takeover-initial-right', `${window.innerWidth - right}px`);
      el.style.setProperty('--live-search-takeover-initial-left', `${left}px`);
      el.style.setProperty('--live-search-takeover-initial-width', `${width}px`);

      el.parentNode.style.height = `${el.parentNode.getBoundingClientRect().height}px`;

      this.animationTakeover.animateTo('open', { force: this.disableAnimations });
    } else {
      // "Slide up" style
      this.animationTakeover.animateTo('open', { force: this.disableAnimations, hold: true });
    }
  }

  _closeTakeOver() {
    if (this.animationTakeover.state === 'closed') return;
    this._unregisterCloseEvents('takeover');
    if (this.settings.use_dimmer) {
      SiteMainDimmer.clear(this);
    }
    this.animationTakeover.animateTo('closed', { force: this.disableAnimations })
      .then(() => {
        ScrollLock.unlock();
        document.body.classList.remove('search-takeover-active');
        document.body.classList.remove('mobile-search-takeover-active');
        this.$el.removeClass('live-search--takeover');

        if (this.mobileSearchBar) {
          this.$el[0].parentNode.style.height = '';
        }
      });
  }

  /**
   * When the focus element has changed, either by clicking, touching, or tabbing
   * check to see if it is within the flydown
   *
   * @param event
   * @private
   */
  _documentFocus(event) {
    const $closest = $(event.target).closest('[data-live-search]');

    if ($closest[0] === this.$el[0]) {
      return;
    }

    this._closeFlyDown();
    this._closeTakeOver();
  }
}
