import * as SiteMainDimmer from '../../helpers/site-main-dimmer';
import NavDesktopParent from './NavDesktopParent'; // eslint-disable-line import/no-cycle
import NavDesktopMenu from './NavDesktopMenu'; // eslint-disable-line import/no-cycle

export default class NavDesktopMeganavParent extends NavDesktopParent {
  constructor(el, options) {
    super(el, options);
    this.enableSubmenu = this.listitem.dataset.navmenuMeganavType === 'meganav-sidenav';
  }

  get content() {
    return this.submenu.querySelector('.navmenu-meganav-wrapper');
  }

  get openHeight() {
    return this._openHeight;
  }

  get blockId() {
    return this.submenu.dataset.meganavId;
  }

  mouseout() {
    // This prevents the menu from closing on mouseout when it's selected in the TE
    if (!this.parentMenu.shouldBlockClose(this)) return;
    this.timer = setTimeout(this.close, 400);
  }

  _open(force = false) {
    if (this._isOpen) return;

    this._isOpen = true;
    this.details.setAttribute('open', 'open');
    window.addEventListener('keydown', this.closeEsc);

    if (this.enableSubmenu && !this.menu) {
      this.menu = new NavDesktopMenu(this.listitem.querySelector('[data-navmenu]'));
      this.menu.preselectFirstItem();
    }

    // Handles the special meganav to meganav transition behaviour, where
    // the drawer appears to stay open and transition from old to new height
    const resolveOpenMeganavs = new Promise(resolve => {
      const { openMeganav } = this.parentMenu;

      if (openMeganav) {
        // Set height to start transitioning from: the open height of the previous meganav
        this.listitem.style.setProperty('--menu-closed-height', `${openMeganav.openHeight}px`);

        // Inject old meganav ghost content
        const meganavGhostContent = openMeganav.content.cloneNode(true);
        const { width, left } = openMeganav.content.getBoundingClientRect();
        meganavGhostContent.classList.add('meganav-ghost');

        // Set styles to absolutely position ghost content correctly
        meganavGhostContent.style.left = `${left}px`;
        meganavGhostContent.style.width = `${width}px`;

        this.submenu.appendChild(meganavGhostContent);

        SiteMainDimmer.dim(this);

        // Jump to ghost state
        this.animation.animateTo('ghost', { force: true })
          // Close other meganav
          .then(() => this.parentMenu.openMeganav.forceClose())
          .then(resolve);
      } else {
        // If no other meganavs are open we can start immediately.
        this.listitem.style.setProperty('--menu-closed-height', 0);
        SiteMainDimmer.dim(this);
        resolve();
      }
    });

    resolveOpenMeganavs
      .then(() => this.closeSiblings(this))
      .then(() => this.animation.animateTo('open', {
        force,
        hold: !force,
        onStart: ({ el }) => {
          const wrapper = el.querySelector('.navmenu-meganav-wrapper');

          const maxHeight = parseInt(window.getComputedStyle(wrapper).maxHeight, 10);
          const height = isFinite(maxHeight) ? Math.min(wrapper.scrollHeight, maxHeight) : wrapper.scrollHeight;

          this.listitem.style.setProperty('--menu-open-height', `${height}px`);
          this._openHeight = height;
        },
      }))
      .then(() => {
        this.link.setAttribute('aria-expanded', true);
        this.parentMenu.openMeganav = this;

        // Rapid mouse movement can sometimes cancel animation before ghost is removed,
        // so when things finally settle make sure we're removing all ghosts.
        this.submenu.querySelectorAll('.meganav-ghost').forEach(ghost => {
          ghost.parentNode.removeChild(ghost);
        });
      });
  }

  _close(force = false) {
    // You would expect to see something like this to avoid "double closing" menus,
    // but in practice it works more reliably to always run when the function is called,
    // to avoid out-of-sync situations.
    // if (!this._isOpen) return Promise.resolve();

    if (this.menu) {
      this.menu.unload();
      this.menu = null;
    }

    if (this.parentMenu.openMeganav === this) {
      this.parentMenu.openMeganav = null;
    }

    this._isOpen = false;
    window.removeEventListener('keydown', this.closeEsc);
    this.listitem.style.setProperty('--menu-closed-height', 0);

    SiteMainDimmer.clear(this);

    return this.animation.animateTo('closed', { force })
      .then(() => {
        this.link.setAttribute('aria-expanded', false);
        this.parentMenu.openSelectedBlock();
      });
  }
}
