import FeaturedCollection from '../components/FeaturedCollection';

export default class DynamicFeaturedCollection {
  constructor(section) {
    const collectionEl = section.el.querySelector('[data-featured-collection]');
    this.featuredCollection = new FeaturedCollection({ el: collectionEl, sectionId: section.id });
  }

  onSectionUnload() {
    this.featuredCollection.unload();
  }
}
