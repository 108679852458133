import EventHandler from '@pixelunion/events';
import layout from '../Layout';
import ScrollLock from '../helpers/ScrollLock';

export default class StickyHeader {
  constructor(options, settings) {
    this.body = document.querySelector('body');
    this.header = document.querySelector('[data-site-header]');
    this.menu = this.header.querySelector('[data-site-navigation]');
    this.menuToggle = options.menuToggle;
    this.postMessage = options.postMessage;

    this.settings = settings;
    this.transitioning = false;
    this.lastToggle = Date.now() - 1000;

    this.stickyClass = 'site-header-sticky';
    this.scrolledClass = 'site-header-sticky--scrolled';
    this.navOpenClass = 'site-header-nav--open';

    this.events = new EventHandler();

    this._toggleStickyHeader = this._toggleStickyHeader.bind(this);
    this._toggleMenu = this._toggleMenu.bind(this);

    if (this.settings.sticky_header) {
      this.body.classList.add(this.stickyClass);
      window.requestAnimationFrame(() => {
        // If browser doesn't support sticky, we don't want any of the sticky functionality.
        if (window.getComputedStyle(this.header).position.indexOf('sticky') > -1) {
          this.observer = new IntersectionObserver(entries => this._toggleStickyHeader(entries));
          this.observer.observe(document.querySelector('[data-header-intersection-target]'));

          this.toggleClick = event => {
            event.preventDefault();
            if (layout.isGreaterThanBreakpoint('M')) this._toggleMenu();
          };

          this.menuToggle.addEventListener('click', this.toggleClick);
        }
      });

      this._storeHeaderHeight();
    }
  }

  _storeHeaderHeight() {
    let headerHeight = this.header.offsetHeight;
    document.documentElement.style.setProperty('--header-height', `${headerHeight}px`);

    const transitionEndHandler = () => {
      headerHeight = this.header.offsetHeight;
      document.documentElement.style.setProperty('--header-height', `${headerHeight}px`);
      this.events.unregister(this.header, 'transitionend', transitionEndHandler);
    };

    this.events.register(this.header, 'transitionend', transitionEndHandler);
  }

  closeNavigation() {
    if (this.transitioning) { return; }

    this.menuToggle.classList.remove('active');

    this.navTransitionOutEvent = () => {
      this.header.classList.remove(this.navOpenClass);
      this.transitioning = false;
      this.menu.removeEventListener('transitionend', this.navTransitionOutEvent);
    };

    this.menu.addEventListener('transitionend', this.navTransitionOutEvent);

    this.transitioning = true;
    this.menu.setAttribute('style', `margin-top: -${this.menu.getBoundingClientRect().height}px;`);

    this.postMessage('nav:close-all');
  }

  openNavigation(onOpen = () => {}) {
    if (this.transitioning || this.header.classList.contains(this.navOpenClass)) {
      onOpen();
      return;
    }

    this.menuToggle.classList.add('active');

    this.navTransitionInEvent = () => {
      this.transitioning = false;
      this.menu.removeEventListener('transitionend', this.navTransitionInEvent);
      onOpen();
    };

    this.menu.addEventListener('transitionend', this.navTransitionInEvent);

    this.transitioning = true;

    // We need to wait for the browser to set the display to 'block' before we set the margin
    // This will help with ensuring the different animations/transitions happen in sequence
    // and not at the same time.
    window.requestAnimationFrame(() => {
      this.header.classList.add(this.navOpenClass);

      window.requestAnimationFrame(() => {
        this.menu.setAttribute('style', 'margin-top: 0;');
      });
    });
  }

  _toggleMenu() {
    if (this.header.classList.contains(this.navOpenClass)) {
      this.closeNavigation();
    } else {
      this.openNavigation();
    }
  }

  /**
   * Sticky header only shows as sticky after scroll
   *
   * @private
   */
  _toggleStickyHeader(entries) {
    if (ScrollLock.isLocked || !layout.isGreaterThanBreakpoint('M')) {
      return;
    }

    const shouldShrink = !entries[0].isIntersecting;

    // Sticky header is scrolled, is and is visible -- nothing more to do!
    if (shouldShrink && this.header.classList.contains(this.scrolledClass)) {
      return;
    }

    // We also check to make sure the toggle hasnt activated recently to stop jerky transitions
    if (this.lastToggle + 250 > Date.now()) {
      return;
    }

    this.lastToggle = Date.now();

    if (shouldShrink) {
      this._shrink();
    } else {
      this._expand();
    }
  }

  _shrink() {
    this.closeNavigation();
    this.header.classList.add(this.scrolledClass);
    this._storeHeaderHeight();
  }

  _expand() {
    this.openNavigation();
    this.header.classList.remove(this.scrolledClass);
    this.menuToggle.classList.remove('active');
    this._storeHeaderHeight();
  }

  unload() {
    this.body.classList.remove(this.stickyClass);
    this.body.classList.remove(this.scrolledClass);

    if (this.observer) {
      this.observer.disconnect();
    }

    this.menuToggle.removeEventListener('click', this.toggleClick);

    this.events.unregisterAll();
  }
}
