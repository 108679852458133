import $ from 'jquery';
import * as breakpoint from '@pixelunion/breakpoint';
import debounce from 'just-debounce';
import Flickity from 'flickity';
import layout from '../Layout';

export default class DynamicHighlightsBanner {
  constructor(section) {
    this.$el = $(section.el);
    this.$window = $(window);

    this.$carousel = this.$el.find('[data-highlights-slider]');
    this.slides = '[data-highlights-block]';

    this.flickity = null;
    this.flickityOptions = null;

    if (this.$carousel.length > 0 && this.$carousel.find(this.slides).length > 1) {
      this._initSlider();
    }

    breakpoint.onChange(breakpoints => this.onBreakpointChange(breakpoints));
  }

  _initSlider() {
    this.flickityOptions = {
      autoPlay: 0,
      accessibility: true,
      cellAlign: 'left',
      cellSelector: this.slides,
      pageDots: false,
      prevNextButtons: false,
      contain: true,
    };

    this._bindFlickity();
  }

  onSectionUnload() {
    this._destroyFlickity();
  }

  _bindFlickity() {
    if (breakpoint.max('M') && this.flickity === null && this.$carousel[0]) {
      this.flickity = new Flickity(this.$carousel[0], this.flickityOptions);
    }
  }

  _destroyFlickity() {
    if (!this.flickity) {
      return;
    }

    this.flickity.destroy();
    this.flickity = null;
  }

  onBreakpointChange(breakpoints) {
    if (breakpoints.current.min('M') && this.flickity) {
      this._destroyFlickity();
    } else if (breakpoints.previous.min('M')) {
      this._bindFlickity();
    }
  }
}
