import Flickity from 'flickity';
import * as breakpoint from '@pixelunion/breakpoint';
import EventHandler from '@pixelunion/events';

export default class DynamicTestimonials {
  constructor(section) {
    this.$el = section.el;
    this.$window = $(window);
    this.flickity = null;
    this.events = new EventHandler();

    this.carousel = this.$el.querySelector('.testimonials');
    this.slides = this.$el.querySelectorAll('.testimonial');

    this.onBreakpointChange  = () => {
      if (breakpoint.max('XS') && this.slides.length > 1
        || breakpoint.max('L') && this.slides.length >= 3
        || breakpoint.min('XL') && this.slides.length > 3
      ) {
        this._initFlickity();
      } else {
        this._destroyFlickity();
      }
    };

    this.onBreakpointChange();

    breakpoint.onChange(this.onBreakpointChange);
  }

  onSectionUnload() {
    this._destroyFlickity();
    breakpoint.offChange(this.onBreakpointChange);
    this.events.unregisterAll();
  }

  _initFlickity() {
    if (this.Flickity) {
      return;
    }

    this.flickity = new Flickity(this.carousel, {
      autoPlay: 0,
      accessibility: true,
      cellAlign: 'left',
      cellSelector: '.testimonial',
      groupCells: true,
      pageDots: true,
      contain: true,
      adaptiveHeight: false,
      arrowShape: 'M65.29 11.99L27.28 50L65.3 87.99L70.25 83.06L37.19 50L70.26 16.94L65.29 11.99Z',
    });

    this.events.register(this.carousel, 'rimg:load', () => {
      this.flickity.resize();
    });
  }

  _destroyFlickity() {
    if (!this.flickity) {
      return;
    }

    this.flickity.destroy();
    this.flickity = null;
  }
}
