import EventHandler from '@pixelunion/events';

export default class ScrollLink {
  constructor() {
    this.events = new EventHandler();
    this.els = [];
    this.lastTop = 0;
    this.lastLeft = 0;
    this.ticking = false;
    this.driver = null;
    this.settledTimeout = null;
  }

  add(el) {
    if (this.els.includes(el)) return;

    this.els.push(el);

    this.events.register(el, 'scroll', e => {
      if (this.driver === null) {
        this.driver = e.target;
        this._unsetScrollSnapTypes();
      }

      // Ignore scroll events on driven element
      if (this.driver && e.target !== this.driver) {
        e.preventDefault();
        return;
      }

      this.lastTop = e.target.scrollTop;
      this.lastLeft = e.target.scrollLeft;
      clearTimeout(this.settledTimeout);
      if (!this.ticking) {
        window.requestAnimationFrame(() => {
          this._updateScroll();
          this.ticking = false;
          this.settledTimeout = setTimeout(() => {
            this.driver = null;
            this._resetScrollSnapTypes();
          }, 50);
        });
      }
      this.ticking = true;
    });
  }

  unload() {
    this.events.unregisterAll();
  }

  syncAll() {
    this._updateScroll();
  }

  _updateScroll() {
    this.els.forEach(el => {
      if (el === this.driver) return;

      el.scrollTop = this.lastTop;
      el.scrollLeft = this.lastLeft;
    });
  }

  _unsetScrollSnapTypes() {
    this.els.forEach(el => {
      if (el === this.driver) return;
      el.style.scrollSnapType = 'none';
    });
  }

  _resetScrollSnapTypes() {
    this.els.forEach(el => {
      el.style.scrollSnapType = null;
    });
  }
}
