import NavDesktopParent from './NavDesktopParent'; // eslint-disable-line import/no-cycle
import NavDesktopMeganavParent from './NavDesktopMeganavParent'; // eslint-disable-line import/no-cycle

export default class NavDesktopMenu {
  constructor({ children }) {
    this.parents = [];
    this.children = children;

    // Meganav, if any, that is fully open (not animating).
    this._openMeganav = null;

    // Meganav, if any, that is selected for editing in the TE.
    this._selectedBlock = null;
    this._megaNavs = null;

    this.closeSiblings = current => {
      this.parents.forEach(parent => {
        if (parent !== current) {
          parent.close();
        }
      });
    };

    for (let i = 0; i < this.children.length; i++) {
      const child = this.children[i];
      if (child.dataset.navmenuMeganavTrigger !== undefined) {
        this.parents.push(
          new NavDesktopMeganavParent(
            child,
            { parentMenu: this },
          ),
        );
      } else if (child.dataset.navmenuParent !== undefined) {
        this.parents.push(
          new NavDesktopParent(
            child,
            { parentMenu: this },
          ),
        );
      } else if (child.classList.contains('navmenu-item')) {
        child.addEventListener('focusin', this.closeSiblings);
      }
    }
  }

  get openMeganav() {
    return this._openMeganav;
  }

  set openMeganav(meganav) {
    this._openMeganav = meganav;
  }

  selectBlock(id) {
    // This is TE only, so only initialize the first time a block is selected
    if (!this._megaNavs) {
      this._megaNavs = {};
      this.parents
        .filter(parent => parent instanceof NavDesktopMeganavParent)
        .forEach(megaNav => {
          this._megaNavs[megaNav.blockId] = megaNav;
        });
    }

    const newSelectedBlock = this._megaNavs[id];

    if (this._selectedBlock === newSelectedBlock) return;

    if (this._selectedBlock) {
      this._selectedBlock.close();
    }

    this._selectedBlock = this._megaNavs[id];

    // Force open give a better experience when changing settings.
    // Otherwise the selected block visibly closes and reopens after every
    // settings change.
    this._selectedBlock.forceOpen();
  }

  openSelectedBlock() {
    if (this._selectedBlock && this.parents.filter(parent => parent.isOpen).length === 0) {
      this._selectedBlock.open();
    }
  }

  // If a block is open and selected in the TE and no other blocks are open
  // we don't want to close it when we normally would.
  shouldBlockClose(block) {
    if (block === this._selectedBlock
      && this.parents.filter(parent => parent.isOpen).length === 1) {
      return false;
    }
    return true;
  }

  preselectFirstItem() {
    const firstParent = this.parents[0];
    if (!firstParent) return;
    if (firstParent.listitem === this.children[0]) {
      firstParent.listitem.classList.add('navmenu-item--preselected');
      firstParent.open();
    }
  }

  closeAllMenus() {
    this._selectedBlock = null;
    this.parents.forEach(parent => parent.close());
  }

  unload() {
    this.parents.forEach(parent => { parent.unload(); });
    for (let i = 0; i < this.children.length; i++) {
      this.children[i].removeEventListener('focusin', this.closeSiblings);
    }
  }
}
