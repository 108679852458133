import $ from 'jquery';
import RichText from '../components/RichText';
import Forms from '../Forms';

export default class StaticArticle {
  constructor(section) {
    this.$el = $(section.el);
    this.$commentForm = this.$el.find('[data-articlecomments-form]');

    this.richText = new RichText(this.$el);

    if (this.$commentForm.length) {
      this.commentForm = new Forms(this.$commentForm);
    }
  }

  onSectionUnload() {
    this.richText.unload();

    if (this.commentForm) {
      this.commentForm.unload();
    }
  }
}
