import $ from 'jquery';
import * as breakpoint from '@pixelunion/breakpoint';
import Accordion from '../Accordion';
import EventHandler from '@pixelunion/events';

export default class StaticSubCollectionsMenuList {
  constructor(section) {
    this.el = section.el;
    this.context = section.data.context;
    this.handleEvents = new EventHandler();
    this.accordionTrigger = '[data-accordion-trigger]';
    this.mobileAccordionOpen = this.el.querySelector(".mobile-accordion__open--true");

    this.Accordion = new Accordion(this.el, {
      content: '[data-accordion-content]',
      onStart: ({ el, state }) => {
        el.parentNode.querySelector(this.accordionTrigger).dataset.accordionTrigger = state;
      }
    });

    if (this.mobileAccordionOpen) {
      this.Accordion.openAll({ force: true });
    } else if (breakpoint.max('XS')) {
      this.Accordion.closeAll({ force: true });
      this._addAccordionClickEvent()
    }

    // Handle Accordion interaction when window size changes
    breakpoint.onChange(breakpoints => this.onBreakpointChange(breakpoints));

  }

  onSectionUnload() {
    this.Accordion.unload();
    this.handleEvents.unregisterAll();
  }

  onSectionBlockSelect(block) {
    this._toggleAccordion(block.el);
  }

  onSectionBlockDeselect(block) {
    this._toggleAccordion(block.el);
  }

  _addAccordionClickEvent() {
    this.el.querySelectorAll(this.accordionTrigger).forEach(el => {
      this.handleEvents.register(el, 'click', event => {
        event.preventDefault();
        this._toggleAccordion(event.currentTarget.parentNode);
      });
    });
  }

  _toggleAccordion(block) {
    if (breakpoint.min('S')) return;
    this.Accordion.toggle(block);
  }

  onBreakpointChange(breakpoints) {
    if (breakpoints.current.min('S') || this.mobileAccordionOpen) {
      this.Accordion.openAll({ force: true });
      this.handleEvents.unregisterAll();
    } else if (breakpoints.previous.min('S') && breakpoints.current.max('XS')) {
      this.Accordion.closeAll({ force: true });
      this._addAccordionClickEvent();
    }
  }
}
