import { transition } from '@pixelunion/animations';

export default class Checkbox {
  constructor(el) {
    this.checkmark = el.querySelector('.checkmark');
    this.checkmarkCheck = el.querySelector('.checkmark__check');
    this.checkmarkIndeterminate = el.querySelector('.checkmark__indeterminate');
    const state = 'unchecked';

    this.fillAnimation = transition({ el: this.checkmark, state });
    this.checkAnimation = transition({ el: this.checkmarkCheck, state });
    this.indeterminateCheckAnimation = transition({ el: this.checkmarkIndeterminate, state });
  }

  check() {
    this.fillAnimation.animateTo('checked');
    this.checkAnimation.animateTo('checked');
  }

  uncheck() {
    this.fillAnimation.animateTo('unchecked');
    this.checkAnimation.animateTo('unchecked');
  }

  setIndeterminate() {
    this.fillAnimation.animateTo('indeterminate');
    this.indeterminateCheckAnimation.animateTo('indeterminate');
  }

  unsetIndeterminate() {
    this.fillAnimation.animateTo('unchecked');
    this.indeterminateCheckAnimation.animateTo('unchecked');
  }

  set disabled(disabled) {
    this.checkmark.classList.toggle('checkmark--disabled', disabled);
  }

  unload() {
    this.fillAnimation.unload();
    this.checkAnimation.unload();
    this.indeterminateCheckAnimation.unload();
  }
}
