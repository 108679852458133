import $ from 'jquery';

import { transition } from '@pixelunion/animations';
import EventHandler from '@pixelunion/events';

export default class MessageBanner {
  constructor(message, type) {
    const bannerTemplate = document.querySelector('[data-templates] [data-message-banner]');
    this.banner = bannerTemplate.cloneNode(true);

    const messageElement = this.banner.querySelector('[data-message-banner-content]');
    messageElement.innerHTML = message;

    this.banner.classList.add(`message--${type}`);

    const modal = document.querySelector('.modal-loaded .modal-inner');
    const target = modal || document.querySelector('[data-site-header]');

    target.appendChild(this.banner);

    this.closeButton = this.banner.querySelector('[data-message-banner-close]');

    this.bannerAnimation = transition({ el: this.banner, state: 'closed' });
    this.bannerAnimation.animateTo('open');

    this.events = new EventHandler();

    this.events.register(this.closeButton, 'click', () => this._close());
    this.events.register(document, 'click', e => this._handleDocumentClick(e.target));
    this.events.register(document, 'touchStart', e => this._handleDocumentClick(e.target));
    this.events.register(window, 'keydown', e => this._closeEsc(e));
  }

  unload() {
    if (this.banner) {
      this._close();
    }
  }

  _closeEsc(e) {
    if (e.key === 'Escape') {
      this._close();
    }
  }

  _close() {
    this.bannerAnimation.animateTo('closed').then(() => {
      this.banner?.remove();
      this.banner = null;
      this.events.unregisterAll();
      this.bannerAnimation.unload();
    });
  }

  _handleDocumentClick(target) {
    const $parent = $(target).parents('[data-message-banner]');
    if ($parent.length) return;

    this._close();
  }
}
