const polyfillUrls = [];

// Checks if all IntersectionObserver and IntersectionObserverEntry
// features are natively supported.
if (!(
  'IntersectionObserver' in window
  && 'IntersectionObserverEntry' in window
  && 'intersectionRatio' in window.IntersectionObserverEntry.prototype
)) {
  polyfillUrls.push(document.querySelector('[data-scripts]').dataset.pxuPolyfills);
}

// Polyfill NodeList.forEach if required.
// Polyfill is so small it doesn't need to load any external code.
if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}

if (!Element.prototype.closest) {
  Element.prototype.closest = function(s) {
    var el = this;

    do {
      if (Element.prototype.matches.call(el, s)) return el;
      el = el.parentElement || el.parentNode;
    } while (el !== null && el.nodeType === 1);
    return null;
  };
}

export default polyfillUrls;
