import $script from 'scriptjs';

const api = 'https://player.vimeo.com/api/player.js';
let apiLoaded = false;

export default class VimeoPlayer {
  constructor({ el, videoUrl }) {
    this.el = el;
    const urlParts = videoUrl.split('/');

    this.id = urlParts[urlParts.length - 1].split('?')[0];

    this.onReadyCallback = null;

    this.onApiLoaded = this._onApiLoaded.bind(this);

    this.onProgress = this._onProgress.bind(this);
    this.onProgressCallback = null;

    if (apiLoaded) {
      this._onApiLoaded();
    } else {
      $script(api, this.onApiLoaded);
    }
  }

  play() {
    return new Promise(resolve => {
      this.onProgressCallback = resolve;

      if (apiLoaded) {
        this.player.on('play', this.onProgress);
        this.player.play();
      } else {
        this.onReadyCallback = () => {
          this.player.on('play', this.onProgress);
          this.player.play();
        };
      }
    });
  }

  pause() {
    return new Promise(resolve => {
      this.onProgressCallback = resolve;

      if (apiLoaded) {
        this.player.on('pause', this.onProgress);
        this.player.pause();
      } else {
        this.onReadyCallback = () => {
          this.player.on('pause', this.onProgress);
          this.player.pause();
        };
      }
    });
  }

  autoplay() {
    return new Promise(resolve => {
      this.onProgressCallback = resolve;

      if (apiLoaded) {
        this.player.on('play', this.onProgress);
        this.player.setVolume(0);
        this.player.play();
      } else {
        this.onReadyCallback = () => {
          this.player.on('play', this.onProgress);
          this.player.setVolume(0);
          this.player.play();
        };
      }
    });
  }

  unload() {
    this.player
      .unload()
      .catch();
  }

  _onApiLoaded() {
    this.player = new window.Vimeo.Player(this.el, { id: this.id });

    this.player
      .ready()
      .then()
      .catch();

    apiLoaded = true;

    if (this.onReadyCallback) {
      this.onReadyCallback();
    }
  }

  _onProgress() {
    this.player.off('play', this.onProgress);
    this.player.off('pause', this.onProgress);

    if (this.onProgressCallback) {
      this.onProgressCallback();
      this.onProgressCallback = null;
    }
  }
}
