import { transition } from '@pixelunion/animations';
import * as breakpoint from '@pixelunion/breakpoint';
import EventHandler from '@pixelunion/events';

const noOverflowClass = 'show-more__content-wrapper--no-overflow';

export default class ShowMoreToggle {
  constructor({ el, context }) {
    this.context = context;
    this.wrapper = el.querySelector('[data-show-more-content-wrapper]');
    this.toggle = el.querySelector('[data-show-more-toggle]');

    if (this.wrapper && this.toggle) {
      this.events = new EventHandler();
      this.menuIsOpen = false;

      this.menuTransition = transition({
        el: this.wrapper,
        state: 'closed',
        stateAttribute: 'data-show-more-state',
        stateChangeAttribute: 'data-show-more-animation',
      });

      this.events.register(this.toggle, 'click', () => {
        if (this.menuIsOpen) {
          this._closeMenu();
        } else {
          this._openMenu();
        }
      });

      this.onBreakpointChange = () => this._closeMenu();
      breakpoint.onChange(this.onBreakpointChange);

      this.checkOverflow();
    }
  }

  unload() {
    if (this.events) this.events.unregisterAll();
    breakpoint.offChange(this.onBreakpointChange);
  }

  checkOverflow() {
    this._closeMenu(true);
  }

  _checkOverflow() {
    const { height } = this.wrapper.getBoundingClientRect();
    const { scrollHeight } = this.wrapper;

    // Round up to account for rounding in scrollHeight
    const noOverflow = Math.ceil(scrollHeight) <= Math.ceil(height);
    this.wrapper.classList.toggle(noOverflowClass, noOverflow);
  }

  _openMenu() {
    this.menuIsOpen = true;
    this.toggle.innerHTML = this.context.see_less;

    const { height: closedHeight } = this.wrapper.getBoundingClientRect();
    this.wrapper.style.setProperty('--closed-height', `${closedHeight}px`);

    this.wrapper.style.maxHeight = 'none';

    this.menuTransition.animateTo('open', {
      onStart: () => {
        const { height: menuToggleHeight } = this.toggle.getBoundingClientRect();
        const openHeight = this.wrapper.scrollHeight + menuToggleHeight;
        this.wrapper.style.setProperty('--open-height', `${openHeight}px`);
      },
    });
  }

  _closeMenu(force) {
    this.menuIsOpen = false;
    this.toggle.innerHTML = this.context.see_more;
    this.wrapper.classList.remove(noOverflowClass);
    this.menuTransition.animateTo('closed', { force }).then(() => {
      this.wrapper.style.removeProperty('--closed-height');
      this.wrapper.style.maxHeight = null;
      this._checkOverflow();
    });
  }
}
