import { transition } from '@pixelunion/animations';
import EventHandler from '@pixelunion/events';
import {
  trapFocus,
  removeTrapFocus,
} from '@shopify/theme-a11y';

import * as breakpoint from '@pixelunion/breakpoint';
import NavMobileSubMenus from './NavMobileSubMenus';
import ScrollLock from '../../helpers/ScrollLock';

export default class NavMobile {
  constructor(elements) {
    this.el = elements.el;
    this.toggleOpen = elements.toggleOpen;

    this.mobileNav = this.el.querySelector('[data-mobile-nav]');
    this.navPanel = this.el.querySelector('[data-mobile-nav-panel]');
    this.navOverlay = this.el.querySelector('[data-mobile-nav-overlay]');
    this.toggleClose = this.el.querySelector('[data-mobile-nav-close]');

    this.announcementBar = document.querySelector('[data-announcement-bar]');

    this.isOpen = false;
    this.subMenus = null;

    this.events = new EventHandler();

    // Revert navigation to original state on breakpoint change
    this.breakpointHandler = this.onBreakpointChange.bind(this);
    breakpoint.onChange(this.breakpointHandler);

    this.navPanelAnimation = transition({ el: this.navPanel, state: 'closed' });
    this.navOverlayAnimation = transition({ el: this.navOverlay, state: 'closed' });

    this.events.register(this.toggleOpen, 'click', e => this._open(e));
  }

  unload() {
    this.events.unregisterAll();
    breakpoint.offChange(this.breakpointHandler);
    ScrollLock.unlock();
  }

  onBreakpointChange() {
    if (breakpoint.min('L') && this.isOpen) {
      this._close();
    }
  }

  _open(e) {
    e.preventDefault();
    if (breakpoint.min('L')) { return; }
    ScrollLock.lock(this.navPanel);

    if (this.announcementBar) {
      this.announcementBar.style.setProperty('--index-announcement-bar', '1100');
    }

    this.isOpen = true;

    // Activate Submenu handler
    this.subMenus = new NavMobileSubMenus(this.navPanel);

    this.mobileNav.dataset.open = 'true';
    this.navPanelAnimation.animateTo('open');
    this.navOverlayAnimation.animateTo('open');

    this.mobileNav.focus();
    trapFocus(this.mobileNav);

    this.overlayClickEvent = this.events.register(this.navOverlay, 'click', e => this._close(e));
    this.toggleCloseEvent = this.events.register(this.toggleClose, 'click', e => this._close(e));
    this.overlayTouchEvent = this.events.register(this.navOverlay, 'touchmove', e => e.preventDefault());
    this.closeEsc = this.events.register(window, 'keydown', e => {
      if (e.key === 'Escape') {
        this._close(e);
      }
    });
  }

  _close(e) {
    if (e) e.preventDefault();

    this.navPanelAnimation.animateTo('closed');
    this.navOverlayAnimation.animateTo('closed')
      .then(() => {
        this.mobileNav.dataset.open = 'false';
        removeTrapFocus(this.mobileNav);
        this.toggleOpen.focus();

        if (this.announcementBar) {
          this.announcementBar.style.setProperty('--index-announcement-bar', '');
        }
      });

    ScrollLock.unlock();

    this.isOpen = false;

    // Close any open drop down menus
    if (this.subMenus) {
      this.subMenus.closeSubMenus(this.navPanel);
      this.subMenus.closeAllSubmenus();

      // Unbind Mobile sub menus
      this.subMenus.unload();
    }

    this.events.unregister(this.overlayClickEvent);
    this.events.unregister(this.overlayTouchEvent);
    this.events.unregister(this.toggleCloseEvent);
    this.events.unregister(this.closeEsc);
  }
}
