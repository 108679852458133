import $ from 'jquery';
import $script from 'scriptjs';
import debounce from 'just-debounce';
import morphdom from 'morphdom';
import { CountryProvinceSelector } from '@shopify/theme-addresses';
import rimg from '@pixelunion/rimg-shopify';
import AsyncView from '@pixelunion/shopify-asyncview';
import EventHandler from '@pixelunion/events';
import RichText from '../components/RichText';
import QuantitySelector from '../helpers/QuantitySelector';

import Forms from '../Forms';

export default class StaticCart {
  constructor(section) {
    this.section = section;
    this.settings = section.data.settings;
    this.shipping = section.data.shipping;
    this.updateTimeout = null;

    this.$window = $(window);
    this.$el = $(section.el);
    this.el = section.el;
    this.events = new EventHandler();
    this.totals = this.el.querySelectorAll('[data-cart-total]');
    this.$shipping = this.$el.find('[data-cartshipping]');
    this.freeShippingBars = this.$el[0].querySelectorAll('[data-free-shipping-bar]');
    this.$cartSidebar = this.$el.find('[data-cart-sidebar]');

    // Quantity selector
    this.quantitySelectors = [];
    this.inputFields = this.el.querySelectorAll('[data-quantity-input]');

    // Product form containers
    this.$titleTotalSmall = this.$el.find('.cart-title-total--small');
    this.$titleTotalLarge = this.$el.find('.cart-title-total--large');
    this.$titleTotalContents = this.$el.find('[data-cart-title-total]');

    // Cart list
    this.cartItemList = this.$el[0].querySelector('[data-cart-item-list]');
    this.cartDiscounts = this.$el[0].querySelector('[data-cart-discounts]');

    // Shipping calculator elements
    this.$shippingToggle = this.$el.find('[data-cartshipping-toggle]');
    this.$shippingResponse = this.$shipping.find('[data-cartshipping-response]');
    this.$shippingResponseMessage = this.$shippingResponse.find('[data-cartshipping-message]');
    this.$shippingResponseRates = this.$shippingResponse.find('[data-cartshipping-rates]');
    this.$shippingSubmit = this.$shipping.find('[data-cartshipping-submit]');

    this._moveTitleTotal();

    const $scripts = $('[data-scripts]');

    this._editItemQuantity = this._editItemQuantity.bind(this);

    this.inputFields.forEach(input => {
      this.quantitySelectors.push(new QuantitySelector({
        quantityField: input.parentNode,
        onChange: this._editItemQuantity,
      }));
    });

    $script($scripts.data('shopify-api-url'), () => {
      this._bindEvents();

      window.Shopify.onError = this._handleErrors.bind(this);
    });

    this.forms = new Forms(this.$el);

    if (this.settings.shipping && this.$shipping.length) {
      $script($scripts.data('shopify-countries'), () => {
        $script($scripts.data('shopify-common'), () => {
          this._initShippingCalc();
        });
      });
    }

    if (this.$cartSidebar.length) {
      new RichText(this.$cartSidebar);
    }
  }

  onSectionUnload() {
    this.$el.off('.cart-page');
    this.$window.off('.cart-page');
    this.forms.unload();
  }

  _bindEvents() {
    this.$el.on('click.cart-page', '[data-cartitem-remove]', event => {
      event.preventDefault();

      this._editItemQuantity(event.currentTarget, true);
    });

    this.$window.on('resize.cart-page', debounce(() => this._moveTitleTotal(), 20));
  }

  /**
   * Gets the current value of the quantity input box for a given line item key
   *
   * @param {string} key
   */
  _getItemQuantity(key) {
    return parseInt(
      this.el
        .querySelector(`[data-cartitem-key="${key}"] [data-quantity-input]`)
        .value,
      10,
    );
  }

  _moveTitleTotal() {
    if (!this.$titleTotalContents.length) {
      return;
    }

    if (this.$window.outerWidth() >= 480) {
      if (!$.contains(this.$titleTotalLarge[0], this.$titleTotalContents[0])) {
        const $form = this.$titleTotalContents.detach();
        this.$titleTotalLarge.append($form);
      }
    } else if (!$.contains(this.$titleTotalSmall[0], this.$titleTotalContents[0])) {
      const $form = this.$titleTotalContents.detach();
      this.$titleTotalSmall.append($form);
    }
  }

  /**
   * Handle an item quantity change
   *
   * @param event
   * @param {Boolean} remove - Set as true to remove cart item
   * @private
   */
  _editItemQuantity(target, remove = false) {
    const $target = $(target);
    const cartItemRow = $target.closest('[data-cartitem-id]')[0];

    if (remove) {
      cartItemRow.classList.add('removing');
    }

    const quantity = remove ? 0 : parseInt(cartItemRow.querySelector('[data-quantity-input]').value, 10);

    const key = cartItemRow.getAttribute('data-cartitem-key');

    this._updateCart(key, quantity);
  }

  /**
   * Update cart with a valid quantity
   *
   * @param $cartItem
   * @param quantity
   * @private
   */
  _updateCart(key, quantity) {
    // cancel any pending requests
    if (this.updateTimeout !== null) {
      clearTimeout(this.updateTimeout);
    }

    this.updateTimeout = setTimeout(() => {
      if (quantity > 0 && this._getItemQuantity(key) !== quantity) {
        this.updateTimeout = null;
        return;
      }

      const thisTimeoutId = this.updateTimeout;
      // Notify Shopify updated item
      window.Shopify.changeItem(key, quantity, response => {
        if (this.updateTimeout !== thisTimeoutId) {
          return;
        }

        this._didUpdate(response, thisTimeoutId);
      });
    }, 300);
  }

  /**
   * Fetches new cart contents and swaps into page
   *
   * @param response
   * @param {integer} thisTimeoutId Id of timeout for this request. If no longer current, update is cancelled.
   * @returns {*}
   * @private
   */

  _didUpdate(response, thisTimeoutId) {
    // Reload page if all items are removed from cart
    if (!response.items.length) {
      window.location = window.Theme.routes.cart_url;
      return;
    }

    // Reload the cart-item-list and the discounts snippets
    AsyncView.load(
      window.Theme.routes.cart_url,
      this.section.id,
    )
      .then(({ html }) => {
        // If another request is in progress, discard this update
        if (this.updateTimeout !== thisTimeoutId) {
          return;
        }

        const countEvent = new CustomEvent('cartcount:update', { detail: response });
        window.dispatchEvent(countEvent);

        // Unregister QuantitySelector events
        this.quantitySelectors.forEach(selector => {
          selector.unload();
        });

        // Update Free shipping bar contents
        if (this.freeShippingBars.length > 0) {
          this.freeShippingBars.forEach(el => {
            const freeShippingBar = el;
            freeShippingBar.innerHTML = html.free_shipping_bar;
            freeShippingBar.classList.add('free-shipping-bar--animate');
          });
        }

        // Inject new cart list contents
        const newListContainer = document.createElement('div');
        newListContainer.innerHTML = html.list;

        morphdom(
          this.cartItemList,
          newListContainer.querySelector('ul'),
          {
            onBeforeElUpdated: (fromEl, toEl) => {
              // Skip images if src matches
              // - we don't want to reload lazy loaded images
              if (fromEl.tagName === 'IMG' && fromEl.src === toEl.src) {
                return false;
              }

              return true;
            },
          },
        );

        // Update cart totals
        this.totals.forEach(total => {
          const newTotal = total;
          newTotal.innerHTML = html.cart_total;

          morphdom(
            total,
            newTotal,
            {
              childrenOnly: true,
            },
          );
        });

        rimg.watch(this.cartItemList);

        this.forms.unload();
        this.forms = new Forms(this.$el);

        this.inputFields.forEach(input => {
          this.quantitySelectors.push(new QuantitySelector({
            quantityField: input.parentNode,
            onChange: this._editItemQuantity,
          }));
        });

        this.$el.off('click.cart-page', '[data-cartitem-remove]');
        this.$el.on('click.cart-page', '[data-cartitem-remove]', event => {
          event.preventDefault();
          this._editItemQuantity(event.currentTarget, true);
        });

        // Inject new cart level discounts
        this.cartDiscounts.innerHTML = html.discounts;
      })
      .catch(() => window.location.reload());
  }

  /**
   * Handle Errors returned from Shopify
   *
   * @param errors
   * @private
   */
  _handleErrors(errors = null) {
    if (!errors) {
      return;
    }

    const shippingResponse = {
      message: this.shipping.error_general,
    };

    if (errors.zip && errors.zip.length > 0) {
      if (errors.zip[0].indexOf('is not valid') !== -1 || errors.zip[0].indexOf('can\'t be blank') !== -1) {
        shippingResponse.message = `${this.shipping.zip} ${errors.zip}`;
      }
    }

    if (errors.error && errors.error.length > 0) {
      if (errors.error[0].indexOf('shipment_too_heavy') !== -1) {
        shippingResponse.message = this.shipping.shipment_too_heavy;
      }
    }

    this._handleShippingResponse(shippingResponse);
  }

  _initShippingCalc() {
    this._bindShippingCalcEvents();

    const countrySelect = document.getElementById('address_country');
    const provinceSelect = document.getElementById('address_province');
    const provinceContainer = document.getElementById('address_province_container');

    this.shippingCountryProvinceSelector = new CountryProvinceSelector(countrySelect.innerHTML);

    this.shippingCountryProvinceSelector
      .build(
        countrySelect,
        provinceSelect,
        {
          onCountryChange: provinces => {
            if (provinces.length) {
              provinceContainer.style.display = 'block';
            } else {
              provinceContainer.style.display = 'none';
            }

            // "Province", "State", "Region", etc. and "Postal Code", "ZIP Code", etc.
            // Even countries without provinces include a label.
            const { label, zip_label: zipLabel } = window.Countries[countrySelect.value];
            provinceContainer.querySelector('label[for="address_province"]').innerHTML = label;
            this.el.querySelector('#address_zip ~ label[for="address_zip"]').innerHTML = zipLabel;
          },
        },
      );
  }

  _bindShippingCalcEvents() {
    this.$el.on('click.cart-page', '[data-cartshipping-toggle]', () => {
      this._toggleShippingCalc();
    });

    this.$el.on('click.cart-page', '[data-cartshipping-submit]', () => {
      this._getShippingRates();
    });

    this.$el.on('keypress.cart-page', '#address_zip', event => {
      if (event.keyCode === 10 || event.keyCode === 13) {
        event.preventDefault();
        this.$shippingSubmit.trigger('click');
      }
    });
  }

  _toggleShippingCalc() {
    const oldText = this.$shippingToggle.text();
    const newText = this.$shippingToggle.data('cartshipping-toggle');

    this.$shippingToggle
      .html(newText)
      .data('cartshipping-toggle', oldText);

    this.$shipping.toggleClass('open');
  }

  _getShippingRates() {
    this._disableShippingButton();

    const shippingAddress = {};
    shippingAddress.country = $('#address_country').val() || '';
    shippingAddress.province = $('#address_province').val() || '';
    shippingAddress.zip = $('#address_zip').val() || '';

    const queryString = Object.keys(shippingAddress)
      .map(key => `${encodeURIComponent(`shipping_address[${key}]`)}=${encodeURIComponent(shippingAddress[key])}`)
      .join('&');

    $.ajax(`${window.Theme.routes.cart_url}/shipping_rates.json?${queryString}`, { dataType: 'json' })
      .fail(error => this._handleErrors(error.responseJSON || {}))
      .done(response => {
        const rates = response.shipping_rates;
        const addressBase = [];

        if (shippingAddress.zip.length) {
          addressBase.push(shippingAddress.zip.trim());
        }

        if (shippingAddress.province.length) {
          addressBase.push(shippingAddress.province);
        }

        if (shippingAddress.country.length) {
          addressBase.push(shippingAddress.country);
        }

        const address = addressBase.join(', ');

        let message = '';
        if (rates.length > 1) {
          const firstRate = window.Shopify.formatMoney(rates[0].price, this.settings.money_format);
          message = this.shipping.multiple_rates
            .replace('*address*', address)
            .replace('*number_of_rates*', rates.length)
            .replace('*rate*', `<span class="money">${firstRate}</span>`);
        } else if (rates.length === 1) {
          message = this.shipping.one_rate.replace('*address*', address);
        } else {
          message = this.shipping.no_rates;
        }

        const ratesList = rates.map(rate => {
          const price = window.Shopify.formatMoney(rate.price, this.settings.money_format);
          const rateValue = this.shipping.rate_value
            .replace('*rate_title*', rate.name)
            .replace('*rate*', `<span class="money">${price}</span>`);

          return `<li>${rateValue}</li>`;
        });

        this._handleShippingResponse({
          message,
          rates: ratesList,
        });
      });
  }

  _enableShippingButton() {
    this.$shippingSubmit
      .html(this.shipping.calculate_shipping)
      .attr('disabled', false);
  }

  _disableShippingButton() {
    this.$shippingSubmit
      .html(this.shipping.calculating)
      .attr('disabled', true);
  }

  _showShippingResponse() {
    this.$shippingResponse.addClass('visible');
  }

  _hideShippingResponse() {
    this.$shippingResponse.removeClass('visible');
  }

  /**
   * Handle shipping responses
   *
   * @param {object} shippingResponse
   * @property {String} shippingResponse.messages - Error / Success message
   * @property {Array|String} shippingResponse.rates - Shipping rates
   * @private
   */
  _handleShippingResponse(shippingResponse = {}) {
    // Hide the response so that it can be populated smoothly
    this._hideShippingResponse();

    const message = shippingResponse.message || null;
    const rates = shippingResponse.rates || null;

    // Empty out contents
    this.$shippingResponseMessage.empty();
    this.$shippingResponseRates.empty();

    if (message) {
      this.$shippingResponseMessage.html(message);
    }

    if (rates) {
      this.$shippingResponseRates.html(rates);
    }

    // Reset the calculating button so it can be used again
    this._enableShippingButton();

    // No error provided
    if (!message && !rates) {
      return;
    }

    // Show the response
    this._showShippingResponse();
  }
}
