import $ from 'jquery';
import '@pixelunion/shopify-variants-ui';
import ShopifySurfacePickUp from '@pixelunion/shopify-surface-pick-up';
import EventHandler from '@pixelunion/events';
import RecipientForm from '@pixelunion/pxs-gift-card-recipient-form';
import Forms from '../Forms';
import AddToCartFlyout from './AddToCartFlyout';
import ProductGallery from './ProductGallery';
import Modal from './Modal';
import MessageBanner from './MessageBanner';
import PaymentTerms from '../helpers/PaymentTerms';
import QuantitySelector from '../helpers/QuantitySelector';

export default class ProductDetails {
  constructor(options) {
    this.$window = $(window);
    this.$formArea = options.$formArea;
    this.$details = options.$details;
    this.context = options.context;
    this.settings = options.settings;
    this.product = options.product;
    this.useHistory = options.useHistory;
    this.sectionId = options.sectionId;
    this.el = options.productEl;
    this.events = new EventHandler();
    this.url = window.location.search;
    this.urlParams = new URLSearchParams(this.url);
    this.paymentTerms = new PaymentTerms(this.el);
    this.variantSelection = this.el.querySelector('[data-variant-selection]');
    this.select_first_available_variant = this.settings.select_first_available_variant;
    this.recipientFormEl = this.el.querySelector('[data-recipient-form]');
    this.surfacePickUpEl = this.el.querySelector('[data-surface-pick-up]');
    this.requestFormEl = this.el.querySelector('[data-product-request-form]');

    if (this.recipientFormEl) {
      this.recipientForm = new RecipientForm(this.el);
    }

    if (this.surfacePickUpEl) {
      this.surfacePickUp = new ShopifySurfacePickUp(this.surfacePickUpEl);
    }

    if (this.requestFormEl) {
      this.requestFormSKU = this.requestFormEl.querySelector('[data-request-form-sku]');
      this.requestFormVariant = this.requestFormEl.querySelector('[data-request-form-variant]');
      this.requestFormVariantID = this.requestFormEl.querySelector('[data-request-form-variant-id]');

      new Forms(this.requestFormEl);

      // eslint-disable-next-line max-len
      if (!this.select_first_available_variant && this.requestFormVariant && this.requestFormVariantID) {
        this.requestFormVariant.removeAttribute('name');
        this.requestFormVariantID.removeAttribute('name');
      }

      this.events.register(this.requestFormEl, 'submit', () => {
        const message = this.requestFormEl.querySelector('[data-product-request-form-message]');

        if (message.value === '') {
          message.classList.add('form-field-filled');
          message.innerHTML = this.context.request_message_empty;
        }
      });

      this.events.register(window, 'load', () => {
        if (this.urlParams.get('contact_posted')) {
          this.requestFormEl.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }
      });
    }

    if (this.product) {
      if ('media' in this.product) {
        this.gallery = new ProductGallery({
          el: options.gallery,
          sectionContext: options.sectionContext,
          settings: this.settings,
          isQuickshop: options.isQuickshop,
        });
      }
    }

    if (!$(this.$formArea).length) return;

    if (this.variantSelection) {
      this.isDefaultVariant = !!this.variantSelection.querySelector('[data-variants].variant-selection__variants--default');

      this.variantSelection.getVariant().then(variant => {
        if (this.surfacePickUp) {
          this.surfacePickUp.load(variant ? variant.id : null);
        }

        this._updateBadge(variant);
        this._updatePrice(variant);

        if (!variant) {
          this._showPriceRange();
          this._updateSKU();
        }

        this.events.register(
          this.variantSelection,
          'variant-change',
          event => this._switchVariant(
            event.detail,
          ),
        );
      });
    }

    this.modal = new Modal({
      onClose: () => this.modal.unload(),
      modalId: 1,
    });

    if (this.surfacePickUp) {
      this.surfacePickUp.onModalRequest(contents => {
        let container = this.$formArea[0].querySelector('[data-surface-pick-up-modal-contents]');

        this.variantSelection.getVariant().then(variant => {
          const variantTitle = !this.isDefaultVariant ? `<div class="surface-pick-up-modal__variant">${variant.title}</div>` : '';

          const modalContents = `
            <div class="surface-pick-up-modal__header">
              <h2 class="surface-pick-up-modal__title">${this.product.title}</h2>
              ${variantTitle}
            </div>
            ${contents}
            `;

          if (!container) {
            container = document.createElement('div');
            container.setAttribute('data-surface-pick-up-modal-contents', '');
            container.style.display = 'none';
            container.innerHTML = modalContents;
            this.$formArea[0].appendChild(container);
          } else {
            container.innerHTML = modalContents;
          }

          this.modal.open('[data-surface-pick-up-modal-contents]', 'surface-pick-up');
        });
      });
    }

    this.addToCartFlyout = null;

    this.atcCallbacks = options.atcCallbacks;

    // Form
    this.$form = this.$formArea.find('[data-product-form]');
    this.$productAtcButton = this.$formArea.find('[data-product-atc]');
    this.$productVariants = this.$form.find('[data-variants]');
    this.$productOptions = this.$form.find('[data-product-option]');
    this.productPricing = this.$details[0].querySelector('[data-product-pricing]');
    this.detailsLink = this.$formArea[0].querySelector('[data-product-details-link]');
    this.quantitySelector = this.el.querySelector('[data-quantity-selector]');

    if (this.detailsLink) {
      this.detailsBaseHref = this.detailsLink.getAttribute('href');
    }

    this.variantFields = {
      $priceContainer: this.$details.find('[data-price-container]'),
      $priceMoney: this.$details.find('[data-price-container] [data-price]'),
      $compareAtPrice: this.$details.find('[data-price-compare-container]'),
      $compareAtPriceMoney: this.$details.find('[data-price-compare-container] [data-price-compare]'),
      $badge: this.$details.find('[data-badge-sales]'),
      $badgeRange: this.$details.find('[data-badge-sales-range]'),
      $badgeSingle: this.$details.find('[data-badge-sales-single]'),
      $sku: this.$details.find('[data-product-sku]'),
      stockLevels: this.$details[0].querySelectorAll('[data-stock-level]'),
      unitPrice: this.$details[0].querySelector('[data-unit-price]'),
      totalQuantity: this.$details[0].querySelector('[data-total-quantity]'),
      unitPriceAmount: this.$details[0].querySelector('[data-unit-price-amount]'),
      unitPriceMeasure: this.$details[0].querySelector('[data-unit-price-measure]'),
      taxLine: this.$details[0].querySelector('[data-tax-line]'),
      hiddenComparePrice: this.$details[0].querySelector('[data-compare-price-hidden]'),
      hiddenCurrentPrice: this.$details[0].querySelector('[data-current-price-hidden]'),
      hiddenComparePriceRange: this.$details[0].querySelector('[data-compare-price-range-hidden]'),
      hiddenCurrentPriceRange: this.$details[0].querySelector('[data-current-price-range-hidden]'),
    };

    if (this.quantitySelector) {
      this.productQuantityBox = new QuantitySelector({
        quantityField: this.quantitySelector,
      });
    }

    this.forms = new Forms(this.$form);

    if (this.product) {
      this._bindEvents();
    }
  }

  unload() {
    if (this.$form) {
      this.$form.off(`.product-details-${this.sectionId}`);
    }

    if (this.forms) {
      this.forms.unload();
    }

    if (this.gallery) {
      this.gallery.unload();
    }

    if (this.messageBanner) {
      this.messageBanner.unload();
    }

    this.events.unregisterAll();
  }

  _bindEvents() {
    this.$form.on(`submit.product-details-${this.sectionId}`, event => this._addToCartFlyout(event));
  }

  _switchVariant(data) {
    const { variant } = data;

    if (this.productPricing) {
      if (!variant) {
        this.productPricing.style.visibility = 'hidden';
      } else {
        this.productPricing.style.visibility = 'visible';
      }
    }

    // Update main select
    this.$productVariants.val(variant.id);

    if (this.gallery) {
      this.gallery.selectMediaByVariant(variant);
    }

    if (this.surfacePickUp) {
      this.surfacePickUp.load(variant.id);
    }

    // Update Variant information
    this._updatePrice(variant);
    this._updateSKU(variant);
    this._updateBadge(variant);
    this._updateButton(variant);
    this._updateSwatchLabel(variant);
    this._updateFullDetailsLink(variant);
    this._updateUnitPrice(variant);
    this._updateStockLevels(variant);
    this.paymentTerms.update(variant.id);

    if (Shopify.PaymentButton) {
      Shopify.PaymentButton.init();
    }

    if (this.useHistory) {
      const url = `${this.product.handle}?${$.param({ variant: variant.id })}`;
      history.replaceState({}, 'variant', url);
    }

    if (this.requestFormEl && !this.urlParams.get('contact_posted')) {
      this.requestFormVariant.value = variant.title;
      this.requestFormVariantID.value = variant.id;

      if (this.requestFormSKU.value === '') {
        this.requestFormSKU.removeAttribute('name');
      } else {
        this.requestFormSKU.setAttribute('name', 'contact[sku]');
      }

      if (!this.select_first_available_variant) {
        this.requestFormVariant.setAttribute('name', 'contact[variant]');
        this.requestFormVariantID.setAttribute('name', 'contact[id]');
      }
    }
  }

  _updateStockLevels(variant) {
    this.variantFields.stockLevels.forEach(el => {
      const stockLevelVariantId = Number(el.dataset.stockVariantId);
      if (variant.id === stockLevelVariantId) {
        el.setAttribute('data-stock-variant-selected', 'true');
      } else {
        el.setAttribute('data-stock-variant-selected', 'false');
      }
    });
  }

  _updatePrice(variant) {
    if (!this.productPricing) { return; }
    if (!variant) {
      this._showPriceRange();
      this.productPricing.style.visibility = 'visible';
      return;
    }

    const compareAtPrice = this.variantFields.hiddenComparePrice.innerHTML;
    const currentPrice = this.variantFields.hiddenCurrentPrice.innerHTML;
    this.variantFields.$compareAtPrice[0].innerHTML = compareAtPrice;
    this.variantFields.$priceContainer[0].innerHTML = currentPrice;

    this.variantFields.$priceMoney = this.$details.find('[data-price-container] [data-price]');
    this.variantFields.$compareAtPriceMoney = this.$details.find('[data-price-compare-container] [data-price-compare]');

    // Update compare at price
    const hasComparePrice = (
      !!variant.compare_at_price && variant.compare_at_price > variant.price
    );

    this.variantFields.$compareAtPrice.toggleClass('visible', hasComparePrice);

    this.variantFields.$compareAtPriceMoney.html(
      Shopify.formatMoney(variant.compare_at_price, this.settings.money_format),
    );

    // Update price
    this.variantFields.$priceMoney.html(
      Shopify.formatMoney(variant.price, this.settings.money_format),
    );
  }

  _showPriceRange() {
    this._updateBadge(false);

    const currentPriceIsRange = this.product.price_varies;
    const currentCompareAtPriceIsRange = this.product.variants.some(variant => (
      (variant.compare_at_price || variant.price) !== this.product.compare_at_price
    ));
    const currentPrice = currentPriceIsRange
      ? this.variantFields.hiddenCurrentPriceRange.innerHTML
      : this.variantFields.hiddenCurrentPrice.innerHTML;
    const compareAtPrice = currentCompareAtPriceIsRange
      ? this.variantFields.hiddenComparePriceRange.innerHTML
      : this.variantFields.hiddenComparePrice.innerHTML;
    const shouldDisplayCompareAtPrice = this.product.compare_at_price_max > this.product.price_min;

    this.variantFields.$compareAtPrice.toggleClass('visible', shouldDisplayCompareAtPrice);

    this.variantFields.$compareAtPrice[0].innerHTML = compareAtPrice;
    this.variantFields.$priceContainer[0].innerHTML = currentPrice;
  }

  _updateSKU(variant = false) {
    if (variant) {
      if (variant.sku === '') {
        this.variantFields.$sku.parent().addClass('product-sku--empty');
      } else {
        this.variantFields.$sku.parent().removeClass('product-sku--empty');
        this.variantFields.$sku.parent().show();
      }

      this.variantFields.$sku.text(variant.sku);

      if (this.requestFormEl && !this.urlParams.get('contact_posted')) {
        this.requestFormSKU.value = variant.sku;
      }
    } else {
      this.variantFields.$sku.parent().hide();
    }
  }

  _updateBadge(variant = false) {
    if (!variant) {
      const priceSaved = this.product.compare_at_price
        ? this.product.compare_at_price_max - this.product.price_min
        : 0;
      if (priceSaved <= 0) {
        this.variantFields.$badge.toggle(false);
      } else {
        this.variantFields.$badgeSingle.toggle(false);
        this.variantFields.$badgeRange.toggle(!!priceSaved);
        this.variantFields.$badge.toggle(!!priceSaved);
      }
    } else {
      const priceSaved = variant.compare_at_price ? variant.compare_at_price - variant.price : 0;
      if (priceSaved <= 0) {
        this.variantFields.$badge.toggle(false);
      } else {
        this.variantFields.$badgeRange.toggle(false);
        this.variantFields.$badgeSingle.toggle(!!priceSaved);
        this.variantFields.$badge.toggle(!!priceSaved);

        const $badgeMoneySaved = this.variantFields.$badgeSingle.find('[data-price-money-saved]');
        const $badgePercentSaved = this.variantFields.$badgeSingle.find('[data-price-percent-saved]');

        if ($badgeMoneySaved.length) {
          // Update badge if it shows money saved
          $badgeMoneySaved.text(
            Shopify.formatMoney(priceSaved, this.settings.money_format),
          );
        }

        if ($badgePercentSaved.length) {
          // Update badge if it shows percentiles
          const percentileSaved = Math.round((priceSaved * 100) / variant.compare_at_price);
          $badgePercentSaved.text(percentileSaved);
        }
      }
    }
  }

  _updateButton(variant) {
    if (!variant) {
      this.$productAtcButton.text(this.context.product_unavailable);
      this.$productAtcButton
        .addClass('disabled')
        .prop('disabled', true);
    } else if (variant.available) {
      if (this.$productAtcButton[0].hasAttribute('data-product-atc-preorder')) {
        this.$productAtcButton.text(this.context.product_preorder);
      } else {
        this.$productAtcButton.text(this.context.product_available);
      }
      this.$productAtcButton
        .removeClass('disabled')
        .prop('disabled', false);
    } else {
      this.$productAtcButton.text(this.context.product_sold_out);
      this.$productAtcButton
        .addClass('disabled')
        .prop('disabled', true);
    }
  }

  _updateSwatchLabel(variant) {
    if (this.settings.swatches_enable) {
      const swatchLabel = this.$form[0].querySelector('[data-option-swatch-value]');
      if (swatchLabel) {
        swatchLabel.innerText = variant.options[parseInt(swatchLabel.dataset.optionSwatchValue, 10)];
      }
    }
  }

  _updateFullDetailsLink(variant) {
    if (this.detailsLink) {
      this.detailsLink.setAttribute('href', `${this.detailsBaseHref}?variant=${variant.id}`);
    }
  }

  _updateUnitPrice(variant) {
    if (this.variantFields.unitPrice && variant.unit_price_measurement) {
      this.variantFields.totalQuantity.innerHTML = `${variant.unit_price_measurement.quantity_value}${variant.unit_price_measurement.quantity_unit}`;

      this.variantFields.unitPriceAmount.innerHTML = Shopify.formatMoney(variant.unit_price, this.settings.money_format);
      if (variant.unit_price_measurement.reference_value === 1) {
        this.variantFields.unitPriceMeasure.innerHTML = variant.unit_price_measurement.reference_unit;
      } else {
        this.variantFields.unitPriceMeasure.innerHTML = `${variant.unit_price_measurement.reference_value}${variant.unit_price_measurement.reference_unit}`;
      }
      this.variantFields.unitPrice.classList.remove('hidden');
    } else if (this.variantFields.unitPrice) {
      this.variantFields.unitPrice.classList.add('hidden');
    }

    if (this.variantFields.taxLine) {
      if (variant.taxable) {
        this.variantFields.taxLine.classList.remove('hidden');
      } else {
        this.variantFields.taxLine.classList.add('hidden');
      }
    }
  }

  _addToCartFlyout(event) {
    event.preventDefault();

    if (this.recipientFormEl) {
      this.recipientFormEl.classList.remove('recipient-form--has-errors');
    }

    this.variantSelection.getVariant().then(variant => {
      if (!variant) {
        this.messageBanner = new MessageBanner(this.context.select_variant, 'error');
        this.$form.get(0).classList.add('product-form--error-option-unselected');
        return;
      }

      if (this.addToCartFlyout) {
        this.addToCartFlyout.unload();
      }

      const formData = this.$form.serializeArray();
      const options = {
        atcButton: this.$productAtcButton[0],
        settings: {
          moneyFormat: this.settings.money_format,
          cartRedirection: this.settings.cart_redirection,
        },
      };
      this.addToCartFlyout = new AddToCartFlyout(formData, options, this.atcCallbacks);
    });
  }
}
