const { body } = document;
const html = document.querySelector('html');

function _blockScroll(event) {
  // Only block events that occur outside the modal
  if (event.target.closest('.allow-scroll-while-locked')) return;
  event.preventDefault();
  event.stopPropagation();
}

export default class ScrollLock {
  /**
   * Prevents all scrolling of the document
   * @param {HTMLElement} modal Element within which scrolling is allowed
   */
  static lock(modal) {
    if (modal) {
      modal.classList.add('allow-scroll-while-locked');
    }
    html.classList.add('scroll-locked');
    body.style.top = -1 * window.pageYOffset;
    body.addEventListener('scroll', _blockScroll, false);
    body.addEventListener('touchmove', _blockScroll, { passive: false });
  }

  /**
   * Removes scroll lock
   */
  static unlock() {
    document
      .querySelectorAll('.allow-scroll-while-locked')
      .forEach(modal => modal.classList.remove('allow-scroll-while-locked'));
    html.classList.remove('scroll-locked');
    body.style.top = '';
    body.removeEventListener('scroll', _blockScroll, false);
    body.removeEventListener('touchmove', _blockScroll, { passive: false });
  }

  static get isLocked() {
    return html.classList.contains('scroll-locked');
  }
}
