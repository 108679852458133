import rimg from '@pixelunion/rimg-shopify'; // eslint-disable-line
import AsyncView from '@pixelunion/shopify-asyncview';
import layout from '../Layout';
import ProductGridItem from '../components/ProductGridItem';
import ProductRowScroller from '../components/ProductRowScroller';
import { initShopifyProductReviews } from '../helpers/ProductReviews';

export default class StaticProductRecommendations {
  constructor(section) {
    this.section = section;
    this.productId = section.data.productId;
    this.limit = section.data.settings.limit;
    this.recommendedProducts = [];
    this.productsScroller = null;
    this.sectionId = section.data.sectionId;

    this.recommendationContainer = document.querySelector('[data-product-recommendations]');

    this.recommendUrl = `${window.Theme.routes.product_recommendations_url}?section_id=${this.sectionId}&limit=${this.limit}&product_id=${this.productId}`;

    this._loadRecommendations = this._loadRecommendations.bind(this);
    this._resizeRowScroller = this._resizeRowScroller.bind(this);

    this._loadRecommendations();
  }

  _loadRecommendations() {
    AsyncView.load(
      this.recommendUrl,
      { view: '' },
    ).then(({ html }) => {
      this.recommendationContainer.innerHTML = html;

      rimg.watch(this.recommendationContainer);

      const productItems = this.recommendationContainer.querySelectorAll('[data-product-item]');

      const productItemLazyLoad = layout.isGreaterThanBreakpoint('L', true);

      if (productItems.length) {
        productItems.forEach(productItem => {
          this.recommendedProducts.push(new ProductGridItem({
            el: productItem,
            id: this.section.id,
            lazy: productItemLazyLoad,
          }));
        });

        initShopifyProductReviews();

        if (window.Shopify && Shopify.PaymentButton) {
          Shopify.PaymentButton.init();
        }

        this.recommendationContainer.addEventListener('rimg:load', this._resizeRowScroller);
        this.productsScroller = new ProductRowScroller(this.section.el.querySelector('[data-product-row]'));
      }
    });
  }

  _resizeRowScroller() {
    if (this.productsScroller && this.productsScroller.flickity) {
      this.productsScroller.flickity.resize();
    }
  }

  onSectionUnload() {
    if (this.productsScroller) {
      this.productsScroller.unload();
    }

    this.recommendedProducts.forEach(productItem => {
      productItem.unload();
    });

    this.recommendationContainer.removeEventListener('rimg:load', this._resizeRowScroller);
  }
}
