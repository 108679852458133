import FeaturedCollection from '../components/FeaturedCollection';
import ShowMoreToggle from '../components/ShowMoreToggle';

export default class StaticSubcollectionsFeaturedCollection {
  constructor(section) {
    const collectionEls = section.el.querySelectorAll('[data-featured-collection]');
    this.collections = Array.prototype.map.call(
      collectionEls,
      collectionEl => new FeaturedCollection({ el: collectionEl, sectionId: section.id }),
    );

    this.showMoreToggle = new ShowMoreToggle({
      el: section.el,
      context: section.data.context,
    });
  }

  onSectionUnload() {
    this.collections.forEach(collection => collection.unload());
    this.showMoreToggle.unload();
  }
}
