import $ from 'jquery';
import Modal from './Modal';
import ProductDetails from './ProductDetails';
import RichText from './RichText';
import { initShopifyProductReviews } from '../helpers/ProductReviews';
import { setupRippleEffect } from '../helpers/Ripple';

export default class ProductQuickshop {
  constructor(options) {
    this.$el = options.$el;
    this.id = options.id;
    this.sectionContext = options.sectionContext;
    this.initialVariant = options.initialVariant || null;
    this.quickShopSelector = options.quickShopSelector ? options.quickShopSelector : `#shopify-section-${this.id} [data-product-quickshop]`;
    this.$quickShop = $(this.quickShopSelector);
    this.quickshopSpinner = this.$quickShop[0].querySelector('.quickshop-spinner');
    this.modalClass = options.modalClass;
    this.loaded = false;
    this.richText = null;
    this.productDetails = null;
    this.trigger = options.trigger;
    this.isOpen = false;
    this.openingAddToCart = false;
    this.modal = options.modal ? options.modal : null;
    this.quickshopContainer = options.quickshopContainer ? options.quickshopContainer : null;
    this.view = options.view ? options.view : 'product-quickshop';
    this.url = options.productUrl ? options.productUrl : this.$el.data('product-quickshop-url');
    this.modalCallbacks = {
      onOpen: this._open.bind(this),
      onClose: this._close.bind(this),
    };
    this.atcCallbacks = {
      onInit: this._onATCInit.bind(this),
      onError: this._onATCError.bind(this),
      onSuccess: this._onATCSuccess.bind(this),
      onClose: this._onATCClose.bind(this),
    };
    this._initialize();
  }

  _initialize() {
    if (this.modal == null) {
      this.modal = new Modal(this.modalCallbacks);
      this.modal.open(
        this.quickShopSelector,
        this.modalClass,
      );
    }

    this.isOpen = true;
    let $quickShopModalContent = this.modal.$modalInner.find('[data-modal-content]');

    if (this.quickshopContainer) {
      $quickShopModalContent = $(this.quickshopContainer);
    }

    fetch(this.url.indexOf('?') >= 0 ? `${this.url}&view=quickshop` : `${this.url}?view=quickshop`)
      .then(response => response.text()) // Return text string of the HTML
      .then(data => {
        // Convert the HTML string into a document object
        const parser = new DOMParser();
        const doc = parser.parseFromString(data, 'text/html');
        // Locate the product wrapper
        const productSection = doc.querySelector('[data-product-wrapper]');
        // Locate the product settings
        const staticProduct = doc.querySelector('[data-section-type="static-product"]');
        // Converts JSON into object for us to grab the settings
        this.productSettings = JSON.parse(staticProduct.innerHTML);
        // Stop populating the modal if it was closed before the content was loaded
        if (!this.isOpen) { return; }
        $quickShopModalContent.html(productSection.innerHTML); // Inserting product wrapper into modal
        initShopifyProductReviews();
        setupRippleEffect($quickShopModalContent[0]);
        if (window.Shopify && Shopify.PaymentButton) {
          Shopify.PaymentButton.init();
        }
        this.$message = $quickShopModalContent.find('[data-product-quickshop-message]');
        this.$formArea = $quickShopModalContent.find('[data-product-form-area]');
        this.gallery = $quickShopModalContent[0].querySelector('[data-product-gallery]');
        this.$details = $quickShopModalContent.find('[data-product-details]');
        this.$description = $quickShopModalContent.find('[data-product-description]');
        this.productEl = $quickShopModalContent[0];
        this.context = this.productSettings.context;
        this.settings = this.productSettings.settings;
        this.product = this.productSettings.product;
        this._onQuickshopLoaded();
      }).catch();
  }

  _onQuickshopLoaded() {
    if (this.loaded) {
      return;
    }

    if (this.$description && this.$description.length) {
      this.richText = new RichText(this.$description);
    }

    const options = {
      $formArea: this.$formArea,
      gallery: this.gallery,
      $details: this.$details,
      atcCallbacks: this.atcCallbacks,
      context: this.productSettings.context,
      sectionContext: this.sectionContext,
      settings: this.productSettings.settings,
      product: this.productSettings.product,
      useHistory: false,
      isQuickshop: true,
      initialVariant: this.initialVariant,
      productEl: this.productEl,
    };

    this.modal.position();
    this.modal.finishedLoading();
    this.loaded = true;

    window.requestAnimationFrame(() => {
      this.productDetails = new ProductDetails(options);
    });
  }

  _open() {
    // do nothing
  }

  _close() {
    if (!this.openingAddToCart) {
      this.trigger.focus();
    }

    this.loaded = false;
    this.isOpen = false;
    this.$quickShop.empty();
    this.$quickShop.html(this.quickshopSpinner);
    this._toggleMessage('', false);
    this.modal.unload();
  }

  _toggleMessage(message, isVisible) {
    if (this.$message) {
      this.$message
        .html(message)
        .toggleClass('visible', isVisible);
    }
  }

  _onATCInit() {
    this.openingAddToCart = true;
    this.$message.removeClass('visible');
  }

  _onATCError(error) {
    const $content = $(`<div class="product-message--error" tabindex="-1">${error}</div>`);
    this._toggleMessage($content, true);
    $content.focus();
  }

  _onATCSuccess() {
    this._close();
  }

  _onATCClose() {
    // Do nothing
  }

  unload() {
    if (this.isLoaded) {
      return this._close()
        .then(() => {
          if (this.productDetails) {
            this.productDetails.unload();
          }

          if (this.richText) {
            this.richText.unload();
          }
        });
    }
    return Promise.resolve();
  }
}
