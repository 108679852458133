import $script from 'scriptjs';
import { transition } from '@pixelunion/animations';
import EventHandler from '@pixelunion/events';

import Forms from '../Forms';
import layout from '../Layout';
import StickyHeader from '../components/StickyHeader';
import NavMobile from '../components/navigation/NavMobile';
import NavDesktopMenu from '../components/navigation/NavDesktopMenu';
import LiveSearch from '../components/search/LiveSearch';
import SearchForm from '../components/search/SearchForm';

export default class StaticHeader {
  constructor(section) {
    this.el = section.el;
    this.settings = section.data.settings;
    this.postMessage = section.postMessage;
    this.headerSearch = null;

    this.scripts = document.querySelector('[data-scripts]');

    this.menuToggle = this.el.querySelector('[data-menu-toggle]');
    this.cartCount = this.el.querySelector('[data-header-cart-count]');
    this.searchField = this.el.querySelector('[data-live-search]');

    this.siteNavigation = this.el.querySelector('[data-site-navigation]');
    this.desktopNavigation = this.siteNavigation.querySelector('ul.navmenu-depth-1');
    this.headerTools = this.siteNavigation.querySelector('[data-header-actions]');

    this.stickyHeader = new StickyHeader({
      header: this.el,
      menuToggle: this.menuToggle,
      postMessage: this.postMessage,
    }, this.settings);

    this.navMobile = new NavMobile({
      el: this.el,
      toggleOpen: this.menuToggle,
    });

    this.navDesktop = new NavDesktopMenu(document.querySelector('.site-navigation > [data-navmenu]'));

    this.forms = new Forms(this.el);

    this.events = new EventHandler();

    if (this.settings.live_search.enable) {
      $script(this.scripts.dataset.shopifyApiUrl, () => {
        this.headerSearch = new LiveSearch({
          el: this.searchField,
          header: this.el,
        }, {
          ...this.settings.live_search,
          use_dimmer: true,
        });

        this.mobileSearchButtonEl = section.el.querySelector('[data-mobile-search-button]');

        if (this.mobileSearchButtonEl) {
          const disableAnimations = 'reduceAnimations' in document.body.dataset;

          this.mobileSearchButtonAnimation = transition({
            el: this.mobileSearchButtonEl,
            state: 'visible',
          });

          this.events.register(this.mobileSearchButtonEl, 'click', e => {
            e.stopPropagation();
            this.headerSearch.open();
            this.mobileSearchButtonAnimation.animateTo('hidden', { force: disableAnimations });
          });

          this.headerSearch.onClose = () => {
            this.mobileSearchButtonAnimation.animateTo('visible', { force: disableAnimations });
            this.mobileSearchButtonEl.focus();
          };
        }
      });
    } else {
      this.headerSearch = new SearchForm(this.searchField);
    }

    this.events.register(window, 'cartcount:update', event => {
      this.cartCount.dataset.headerCartCount = event.detail.item_count;

      if (event.detail.item_count > 0) {
        this.cartCount.classList.add('visible');
      } else {
        this.cartCount.classList.remove('visible');
      }
    });
  }

  onSectionSelect() {
    this.stickyHeader.openNavigation();
  }

  onSectionDeselect() {
    this._closeAllNavigation();
  }

  onSectionUnload() {
    this.stickyHeader.unload();
    this.navMobile.unload();
    this.navDesktop.unload();
    this.forms.unload();

    this.headerSearch.unload();

    this.events.unregisterAll();
  }

  onSectionMessage(name, data) {
    if (name === 'nav:close-all' && layout.isGreaterThanBreakpoint('M')) {
      this._closeAllNavigation();
    }

    /*
      This event gets dispatched from StaticCollection.js > _fireEvent()
      It should fire when the collection page's utility bar becomes sticky
      the box-shadow on the header should be removed and placed on the
      utility bar instead.
    */
    if (name === 'collection-page:collection-utils-sticky-change') {
      this._handleStickyChange(data);
    }
  }

  onSectionBlockSelect(block) {
    if (!layout.isGreaterThanBreakpoint('M')) {
      return;
    }

    this.stickyHeader.openNavigation(() => {
      this.navDesktop.selectBlock(block.id);
    });
  }

  onSectionBlockDeselect() {
    this._closeAllNavigation();
  }

  _closeAllNavigation() {
    this.navDesktop.closeAllMenus();
  }

  /*
    Remove the box-shadow when the collection page's utility bar has become sticky.
    This only needs to happen if the sticky header is enabled and if the header
    background colour is the same as the body background colour. Otherwise, no box-shadow is shown.
  */
  _handleStickyChange(data) {
    if (!this.settings.sticky_header || !this.settings.has_box_shadow) return;

    const { stuck, target } = data;
    this.el.classList.toggle('site-header-wrapper--no-shadow', stuck);
  }
}
