import Slideshow from '@pixelunion/pxs-slideshow';
import {
  playLoadInAnimations,
  resetLoadInAnimations,
  removeLoadInAnimationsAutoplay,
} from '../helpers/LoadInAnimations';

export default class DynamicSlideshow extends Slideshow {
  constructor(section) {
    super(section);

    this.carouselContainer = this.carousel.parentNode;
    this.slideWrappers = this.carousel.querySelectorAll('[data-slide-image-wrapper]');
    this.header = document.querySelector('.site-header');
    this.announcementBar = document.querySelector('.announcement-bar');

    // Fit screen
    if (this.carouselContainer.classList.contains('slideshow--height-fit_screen') || this.carouselContainer.classList.contains('slideshow--height-fit_screen-mobile')) {
      this._setupCCSVariables();
      this.events.register(window, 'resize', () => this._setupCCSVariables());
      this._positionNavigation();
    }

    // We are manually playing load in animations when
    // changing slides, so prevent automatic play.
    removeLoadInAnimationsAutoplay(this.slides);
    playLoadInAnimations([this.slides[0]]);

    if (this.flickity) {
      this.flickity.on('change', index => {
        // This is a delay to fake waiting until the slide has fully changed.
        // Waiting until 'settle' is a bit too long.
        setTimeout(() => {
          resetLoadInAnimations(Array.prototype.filter.call(this.slides, (el, i) => i !== index));
        }, 100);
        setTimeout(() => {
          playLoadInAnimations([this.slides[index]]);
        }, 150);

        this._setContentHeight();
        this._positionNavigation();
      });
    }
  }

  _setContentHeight() {
    const currentSlide = this.slides[this.flickity.selectedIndex];
    const slideContent = currentSlide.querySelector('.slideshow-slide__content');

    // Determine absolute height
    const getAbsoluteHeight = el => {
      el = (typeof el === 'string') ? document.querySelector(el) : el;

      const styles = window.getComputedStyle(el);
      const margin = parseFloat(styles.marginTop)
                   + parseFloat(styles.marginBottom);

      return Math.ceil(el.offsetHeight + margin);
    };

    const currentSlideContentHeight = getAbsoluteHeight(slideContent);
    this.el.style.setProperty('--content-height', `${currentSlideContentHeight}px`);
  }

  _setupCCSVariables() {
    const currentHeaderHeight = this.header.offsetHeight;
    let currentAnnouncementHeight = 0;

    // Check for the announcement bar
    if (this.announcmentBar) {
      currentAnnouncementHeight = document.querySelector('.announcement-bar').offsetHeight;
    }

    this.el.style.setProperty('--header-height', `${currentHeaderHeight - currentAnnouncementHeight}px`);
    this._setContentHeight();
    this.flickity.resize();
  }
}
