import $ from 'jquery';
import ShoppableImage from '@pixelunion/pxs-shoppable-image';
import ProductQuickshop from '../components/ProductQuickshop';
import Modal from '../components/Modal';

export default class DynamicShoppableImage extends ShoppableImage {
  constructor(section) {
    super(section);
    this.modalSelector = `[data-hotspot-modal-id='${section.id}']`;
    this.modalEl = document.querySelector(this.modalSelector);
    this.modalWrapper = document.querySelector(`[data-hotspot-section-id='${section.id}']`);
    this.quickShopSelector = `[data-hotspot-section-id='${section.id}'] [data-hotspot-modal-quickshop]`;
    this.quickshopSpinner = this.modalEl.querySelector(this.quickShopSelector).innerHTML;
    this.isOpen = false;
    this.modalSidebar = this.modalWrapper.querySelector('[data-hotspot-modal-sidebar]');
    this.modalSidebarItems = this.modalWrapper.querySelectorAll('[data-hotspot-modal-sidebar-item]');
    this.sidebarArrow = this.modalEl.querySelector('[data-sidebar-arrow]');
    this.sidebarItemActiveClass = 'active-item';
    this.qsTrigger = null;
    this.modal = new Modal({
      onClose: () => this.unload(),
    });

    this.points.forEach(point => {
      this.events.register(point, 'animationend', e => {
        if (e.animationName === 'slide-fade-y' && e.elapsedTime > 0) {
          point.classList.add('shoppable-image__hotspot--pulse');
          point.classList.remove('pxu-lia-element');
          point.style.animationPlayState = '';
        }
      });
    });

    this.tooltips.forEach(tooltip => {
      this.events.register(tooltip, 'click', e => this.onTooltipClick(e));
    });

    this.modalSidebarItems.forEach(item => {
      this.events.register(item, 'click', e => this.onSidebarItemClick(e));
    });
  }

  onTooltipClick(event) {
    event.preventDefault();
    const productUrl = event.currentTarget.querySelector('[data-tooltip]').getAttribute('href');
    const productId = event.currentTarget.closest('[data-hotspot]').dataset.hotspotProduct;

    this.points.forEach(point => {
      if (point.dataset.hotspotProduct === productId) {
        this.qsTrigger = point;
      }
    });

    if (productUrl === '#') { return; }

    this.modal.open(this.modalSelector, 'quickshop-full hotspot__modal');

    const selectedItem = this.modalWrapper.querySelector(`[data-product-id='${productId}']`);
    selectedItem.classList.add(this.sidebarItemActiveClass);

    this.positionSidebarArrow(selectedItem);

    this.isOpen = true;

    this._fetchQuickshop(productId, productUrl);
  }

  onSidebarItemClick(event) {
    event.preventDefault();
    const currentSidebarItem = event.currentTarget;
    const productUrl = currentSidebarItem.dataset.productQuickshopUrl;
    const productId = currentSidebarItem.dataset.productId;
    const activeSidebarItems = this.modalWrapper.querySelectorAll(`.${this.sidebarItemActiveClass}`);
    this.modalWrapper.querySelector('[data-hotspot-modal-quickshop]').innerHTML = this.quickshopSpinner;

    this.positionSidebarArrow(currentSidebarItem);

    activeSidebarItems.forEach(item => {
      item.classList.remove(this.sidebarItemActiveClass);
    });

    currentSidebarItem.classList.add(this.sidebarItemActiveClass);

    if (productUrl && productId) {
      this._fetchQuickshop(productId, productUrl);
    }
  }

  positionSidebarArrow(selectedItem) {
    const itemImg = selectedItem.querySelector('[data-modal-sidebar-image]');
    const imgBounds = itemImg.getBoundingClientRect();
    const selectedItemTop = selectedItem.offsetTop;
    const middlePoint = selectedItemTop + (imgBounds.height / 2);
    this.sidebarArrow.style.top = `${middlePoint}px`;
  }

  _fetchQuickshop(productId, productUrl) {
    const quickshopContainer = this.modal.$modalInner[0].querySelector('[data-hotspot-modal-quickshop]');
    const $quickshopEl = this.modal.$modalInner.find('[data-hotspot-modal-sidebar-item]');
    const spinnerContainer = this.modalWrapper.querySelector('.productitem-quickshop');
    if (spinnerContainer) {
      spinnerContainer.style.display = 'block';
    }

    this.productQuickshop = new ProductQuickshop({
      $el: $quickshopEl,
      id: this.sectionId,
      modal: this.modal,
      sectionContext: 'shoppable-image',
      trigger: $(this.qsTrigger),
      quickshopContainer,
      quickShopSelector: this.quickShopSelector,
      productUrl,
      view: 'product-shoppable-img-qs',
    });
  }

  unload() {
    if (this.productQuickshop) {
      this.productQuickshop.unload();
    }

    const activeSidebarItems = this.modalWrapper.querySelectorAll(`.${this.sidebarItemActiveClass}`);

    activeSidebarItems.forEach(item => {
      item.classList.remove(this.sidebarItemActiveClass);
    });
  }
}
