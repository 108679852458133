import $ from 'jquery';
import RichText from '../components/RichText';

export default class Page {
  constructor() {
    this.$pageContent = $('[data-template-page]');

    if (this.$pageContent.length) {
      new RichText(this.$pageContent);
    }
  }
}
