import $script from 'scriptjs';
import Forms from '../Forms';
import LiveSearch from '../components/search/LiveSearch';
import SearchForm from '../components/search/SearchForm';

export default class DynamicSearch {
  constructor(section) {
    this.el = section.el;
    this.settings = section.data.settings;
    this.search = null;

    this.scripts = document.querySelector('[data-scripts]');

    this.searchField = this.el.querySelector('[data-live-search]');

    this.forms = new Forms(this.el);

    if (this.settings.live_search.enable) {
      $script(this.scripts.dataset.shopifyApiUrl, () => {
        this.search = new LiveSearch({
          el: this.searchField,
          header: this.el,
        }, {
          ...this.settings.live_search,
          use_dimmer: false,
        });
      });
    } else {
      this.search = new SearchForm(this.searchField);
    }
  }

  onSectionUnload() {
    this.search.unload();
    this.forms.unload();
  }
}
