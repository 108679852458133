export default class FlickityA11yPatch {
  constructor(slider) {
    this.slider = slider;
    this.observer = new MutationObserver(m => this._removeAttr(m));
    this.observer.observe(this.slider, { attributes: true, childList: true, subtree: true });

    this.slider.querySelectorAll('[aria-hidden]').forEach(el => {
      el.removeAttribute('aria-hidden');
    });
  }

  _removeAttr(mutations) {
    if (mutations.length) {
      mutations.forEach(m => {
        if (m?.target && m.target.hasAttribute('aria-hidden')) {
          m.target.removeAttribute('aria-hidden');
        }
      });
    }
  }

  unload() {
    this.observer.disconnect();
  }
}
