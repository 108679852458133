import $ from 'jquery';

import Accordion from '../../Accordion';

export default class NavMobileSubMenus {
  constructor(el) {
    this.$el = $(el);

    this.Accordion = new Accordion(el);
    this.closeAllSubmenus();

    // Sub menu selectors
    this.activeMenuClass = 'navmenu-link-parent-active';
    this.activeMenu = `.${this.activeMenuClass}`;
    this.linkClass = 'navmenu-link-parent';
    this.linkSelector = `.${this.linkClass}`;
    this.navTrigger = '[data-navmenu-parent]';
    this.subMenu = '[data-navmenu-submenu]';
    this.buttonClass = 'navmenu-button';
    this.buttonSelector = `.${this.buttonClass}`;

    this._bindEvents();
  }

  unload() {
    this.$el.off('.mobile-nav');
    this.Accordion.unload();
  }

  closeSubMenus(current) {
    const $current = $(current);
    $current.find(this.activeMenu).each((index, el) => {
      this._closeSubmenu($(el));
    });
  }

  closeAllSubmenus() {
    this.Accordion.closeAll({ force: true });
  }

  _bindEvents() {
    // Prevent focus state from applying on mouse click
    this.$el.on('mousedown.mobile-nav', '.navmenu-link', event => {
      event.preventDefault();
    });

    this.$el.on('click.mobile-nav', `${this.navTrigger} > .navmenu-link-parent`, this._linkClicked.bind(this));

    this.$el.on('click.mobile-nav', `${this.navTrigger} > .navmenu-button`, event => {
      event.preventDefault();
      this._toggleSubmenu(event);
    });
  }

  _linkClicked(event) {
    const $target = $(event.currentTarget);

    if (!$target.hasClass(this.activeMenuClass)) {
      event.preventDefault();
      this._openSubmenu($target);
    }
  }

  _toggleSubmenu(event) {
    const $target = $(event.currentTarget);
    const $link = $target.hasClass(this.linkClass)
      ? $target : $target.siblings(this.linkSelector).first();

    if ($link.hasClass(this.activeMenuClass)) {
      this._closeSubmenu($target);
    } else {
      this._openSubmenu($target);
    }
  }

  _openSubmenu($target) {
    const $menu = $target.siblings(this.subMenu).first();
    const $link = $target.hasClass(this.linkClass)
      ? $target
      : $target.siblings(this.linkSelector).first();
    const $button = $target.hasClass(this.buttonClass)
      ? $target
      : $target.siblings(this.buttonSelector).first();

    $link.addClass(this.activeMenuClass);

    const onComplete = () => {
      $link.attr('aria-expanded', true);
      $button.attr('aria-expanded', true);
    };

    this.Accordion.open($menu[0], { onComplete });
  }

  _closeSubmenu($target) {
    const $menu = $target.siblings(this.subMenu).first();
    const $link = $target.hasClass(this.linkClass)
      ? $target
      : $target.siblings(this.linkSelector).first();
    const $button = $target.hasClass(this.buttonClass)
      ? $target
      : $target.siblings(this.buttonSelector).first();

    $link.removeClass(this.activeMenuClass);

    const onComplete = () => {
      $link.attr('aria-expanded', false);
      $button.attr('aria-expanded', false);
    };

    this.Accordion.close($menu[0], { onComplete });
    this.closeSubMenus($menu);
  }
}
