import $ from 'jquery';
import RichText from '../components/RichText';

export default class DynamicRichText {
  constructor(section) {
    this.$el = $(section.el);

    this.rte = [];
    this.$el.find('[data-rte]').each((i, el) => {
      this.rte.push(new RichText($(el)));
    });
  }

  /**
   * Unbind events when section is re-drawn
   */
  onSectionUnload() {
    this.rte.forEach(richTextArea => {
      richTextArea.unload();
    });
  }
}
