import { MDCRipple } from '@material/ripple';

// This function sets up the ripple effect for the various buttons throughout the
// theme using CSS selectors.
export const setupRippleEffect = rootElement => {
  if ('reduceAnimations' in document.body.dataset) { return; }

  const rippleElements = [
    '.button-primary',
    '.button-secondary',
    '.slideshow-slide__button',
    '.product-form--atc-button',
    '.mobile-nav-content .navmenu-link',
    '.mobile-nav-content .navmenu-button',
    '.mobile-nav-primary-content .navmenu-link',
    '.mobile-nav-primary-content .navmenu-button',
    '.live-search-button',
    '.options-selection__radios .options-selection__option-value-name',
    '.countdown-timer__caption-button',
    '.order-page__atc-button',
  ];

  rootElement.querySelectorAll(rippleElements.join(',')).forEach(el => {
    if (!el.classList.contains('disabled') && !el.hasAttribute('disabled')) {
      el.classList.add('mdc-ripple-surface');
      MDCRipple.attachTo(el);
    }
  });
};

export default {
  setupRippleEffect,
};
