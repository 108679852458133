import EventHandler from '@pixelunion/events';
import rimg from '@pixelunion/rimg-shopify';
import AsyncView from '@pixelunion/shopify-asyncview';
import { transition } from '@pixelunion/animations';
import ProductGridItem from '../components/ProductGridItem';
import productCompare from '../components/ProductCompare';
import FilterGroups from '../components/FilterGroups';
import Checkbox from '../components/Checkbox';
import Modal from '../components/Modal';
import RichText from '../components/RichText';
import { setupRippleEffect } from '../helpers/Ripple';
import ScrollLink from '../helpers/ScrollLink';
import ShowMoreToggle from '../components/ShowMoreToggle';

/**
 * Gets comma separated product handles from `compare` query parameter
 * @returns [String] Product handles
 */
const getCompareHandles = () => {
  const { searchParams } = new URL(window.location);
  const handles = searchParams.get('compare');
  if (typeof handles === 'string' && handles !== '') {
    return handles.split(',');
  }
  return [];
};

const updateUrlForHandles = handles => {
  if (window.Shopify && window.Shopify.designMode) return;

  const [productHandle, ...compareHandles] = handles;
  const url = new URL(window.location);
  url.searchParams.set('compare', compareHandles.join(','));
  if (productHandle) {
    url.pathname = url.pathname.replace(/\/[^/]+$/, `/${productHandle}`);
  }
  history.replaceState({}, '', url);
};

const generateBaseUrl = rootUrl => {
  const separator = /\/$/.test(rootUrl) ? '' : '/';
  return `${rootUrl}${separator}products`;
};

const lastRowClass = 'product-compare__table-row--last';

export default class StaticProductCompare {
  constructor(section) {
    this.sectionId = section.id;
    this.el = section.el;
    this.data = section.data;
    this.baseUrl = generateBaseUrl(section.data.root_url);
    this.templateCells = {};
    this.productItems = [];
    this.showMoreToggles = [];
    this.descriptionItems = [];
    this.filterLabels = {};
    this.filterGroups = section.data.filter_groups;
    this.activeFilterIds = new Map();
    this.filterCheckboxes = new Map();
    this.events = new EventHandler();
    this.filtersModal = new Modal(
      {
        onBeforeOpen: () => this._onBeforeFiltersModalOpen(),
        onClose: () => this._onFiltersModalClose(),
      },
    );

    this.allRows = this.el.querySelectorAll('[data-compare-row-type="info"], [data-compare-row-type="heading"]');
    this.infoRows = this.el.querySelectorAll('[data-compare-row-type="info"]');
    this.headingRows = this.el.querySelectorAll('[data-compare-row-type="heading"]');

    this._initCell(this.el);

    this.table = this.el.querySelector('[data-compare-table]');
    const productCardRow = this.infoRows[0];
    const firstNonProductCardRow = this.allRows[1];
    const emptyMessage = this.el.querySelector('[data-compare-empty]');

    // @pixelunion/animations requires the transitionend events to fire on
    // the element to which the animation is attached, but due to the table
    // structure and the use of sticky on `th` cells we are attaching the
    // animation to the `tr` and applying that animation the all `th` descendants.
    // For this to work, we need to dispatch a synthetic transitionend event on
    // the `tr` itself. Similarly we apply some animations to the entire table
    // but the actual transition is performed on individual rows.

    const eventsToSynthetize = [
      { target: productCardRow, newTarget: this.table },
      { target: firstNonProductCardRow, newTarget: this.table },
    ];

    eventsToSynthetize.forEach(({ target, newTarget }) => {
      this.events.register(target, 'transitionend', e => {
        if (e.target !== target) return;

        newTarget.dispatchEvent(new Event('transitionend'));
      });
    });

    this.clearAllFadeAnimation = transition({
      el: this.table,
      state: 'visible',
      stateAttribute: 'data-fade-animation-state',
      stateChangeAttribute: 'data-fade-animation',
    });

    this.emptyMessageAnimation = transition({
      el: emptyMessage,
      state: 'hidden',
      stateAttribute: 'data-animation-state',
      stateChangeAttribute: 'data-animation',
    });

    this.animateToEmptyState = () => {
      this._removeAllFilters();
      this._disableFilters();
      this.stickyObserver.disconnect();
      this.stickyBarAnimation.animateTo('up');
      this.clearAllFadeAnimation.animateTo('only-cards', { force: this.allRows.length < 2 })
        .then(() => this.clearAllFadeAnimation.animateTo('hidden'))
        .then(() => this.emptyMessageAnimation.animateTo(
          'visible',
          { onStart: () => { document.documentElement.scroll(0, 0); } },
        ));
    };

    this._registerProductRemoveEvents(this.el);

    this.scrollLink = new ScrollLink();
    this.scrollLink.add(this.el.querySelector('[data-compare-table-scroll-wrapper]'));

    this.filtersEl = this.el.querySelector('[data-compare-filters]');

    if (this.filtersEl) {
      const options = {
        groups: this.filterGroups,
      };

      this.filterGroupAccordions = new FilterGroups(this.filtersEl, options);

      this.filtersEl.querySelectorAll('[data-filter-checkbox-for]').forEach(checkboxEl => {
        this.filterCheckboxes.set(checkboxEl.dataset.filterCheckboxFor, new Checkbox(checkboxEl));
      });

      this.filtersModalButton = this.el.querySelector('[data-compare-open-filter-modal-button]');

      this.events.register(
        this.filtersModalButton,
        'click',
        () => this.filtersModal.open('[data-compare-filters-modal-target]', 'productgrid-filters'),
      );

      this.mobileActiveFiltersTarget = this.el.querySelector('[data-compare-mobile-active-filters-target]');

      this.activeFiltersContainer = this.el.querySelector('[data-compare-active-filters-container]');
      this.activeFiltersEl = this.activeFiltersContainer.querySelector('[data-compare-active-filters]');
      this.removeFilterTemplate = this.activeFiltersContainer.querySelector('[data-compare-filter-remove-template]');
      this.clearAllFiltersTemplate = this.activeFiltersContainer.querySelector('[data-compare-filter-clear-all-template]');

      this.events.register(this.activeFiltersEl, 'click', e => {
        const filterToRemove = e.target.closest('[data-product-compare-filter-remove]');

        if (!filterToRemove) return;

        // prevent modal from interpreting this click event as outside the modal
        // when it does a DOM trace from the by-then removed elements.
        e.stopPropagation();
        const id = filterToRemove.dataset.productCompareFilterRemove;

        if (id === '') {
          this._removeAllFilters();
          return;
        }

        document.getElementById(id).checked = false;
        this._updateFilter({ checked: false, id });
      });
    }

    this.events.register(
      this.filtersEl,
      'change',
      e => {
        const id = e.target.value;
        this.filterLabels[id] = e.target.labels[0].innerText;
        this._updateFilter({ checked: e.target.checked, id });
      },
    );

    const compareHandles = getCompareHandles();

    this.allHandles = [section.data.handle, ...compareHandles];

    this._updateBreadcrumbs();

    if (!compareHandles.length) {
      this._injectStickyBar();
      return;
    }

    // Add required columns first, so the table size
    // is correct immediately, without having to load
    // the compared products
    this._addBlankColumns(compareHandles.length);
    this._setProductCountVar(this.allHandles.length);
    Promise.all(this._fetchCompareProducts(compareHandles))
      .then(() => this._injectStickyBar());
  }

  onSectionUnload() {
    this.productItems.forEach(productItem => {
      productItem.unload();
    });

    this.events.unregisterAll();

    if (this.filterGroups) {
      this.filterGroupAccordions.unload();
    }

    this.filterCheckboxes.forEach(checkbox => checkbox.unload());

    this.stickyBarAnimation.unload();
    this.clearAllFadeAnimation.unload();
    this.scrollLink.unload();
    this.stickyBar.remove();

    if (this.tableObserver) {
      this.tableObserver.disconnect();
    }

    if (this.stickyObserver) {
      this.stickyObserver.disconnect();
    }
  }

  _registerProductRemoveEvents(el) {
    this.events.register(el.querySelector('[data-compare-clear-all]'), 'click', () => {
      this.animateToEmptyState();
      productCompare.removeAll();
    });

    this.events.register(el, 'click', e => {
      const removeButton = e.target.closest('[data-compare-remove]');

      if (!removeButton) return;

      this._removeHandle(removeButton.dataset.compareRemove);
    });
  }

  _injectStickyBar() {
    const barTemplate = this.el.querySelector('[data-compare-sticky-bar-template]').content;
    const barTable = barTemplate.querySelector('[data-compare-table]');
    barTable.appendChild(this.infoRows[0].cloneNode(true));
    this._addScrollStops(barTemplate, this.allHandles.length - 2);
    document.querySelector('[data-site-header]').append(barTemplate);
    this.stickyBar = document.querySelector('[data-compare-sticky-bar]');
    this.stickyBarRow = this.stickyBar.querySelector('[data-compare-row-type="info"]');

    this._initProductItems(this.stickyBar);
    setupRippleEffect(this.stickyBar);
    this._registerProductRemoveEvents(this.stickyBar);

    this.scrollLink.add(this.stickyBar.querySelector('[data-compare-table-scroll-wrapper]'));

    const productCardRow = this.infoRows[0];

    this.stickyBarAnimation = transition({
      el: document.querySelector('[data-compare-sticky-bar]'),
      state: 'up',
      stateAttribute: 'data-sticky-animation-state',
      stateChangeAttribute: 'data-sticky-animation',
    });

    const animateToDown = () => this.stickyBarAnimation.animateTo(
      'down',
      {
        onStart: () => this.scrollLink.syncAll(),
      },
    );

    this.tableObserver = null;

    // We recreate this observer each time to ensure the margin
    // accurately reflects the bar height but don't account
    // for screen resizes because it still works acceptably
    // if the margin isn't perfect.
    const createTableObserver = () => {
      const { top, height } = this.stickyBar.getBoundingClientRect();
      this.tableObserver = new IntersectionObserver(([entry]) => {
        if (!entry.isIntersecting) {
          this.stickyBarAnimation.animateTo('up');
          return;
        }

        animateToDown();
      },
      {
        rootMargin: `-${top + height}px 0px 0px 0px`,
        threshold: 0,
      });

      this.tableObserver.observe(this.table);
    };

    const hasStickyHeader = document.body.classList.contains('site-header-sticky');

    this.stickyObserver = new IntersectionObserver(([entry]) => {
      if (!entry.isIntersecting) {
        animateToDown().then(createTableObserver);
        return;
      }

      if (this.tableObserver) {
        this.tableObserver.disconnect();
        this.tableObserver = null;
      }

      this.stickyBarAnimation.animateTo('up');
    },
    {
      rootMargin: `${hasStickyHeader ? '-100' : '0'}px 0px 0px 0px`,
      threshold: 0,
    });

    this.stickyObserver.observe(productCardRow);
  }

  _setProductCountVar(count) {
    // Set this on the body so it also applies
    // to the cards in the sticky bar in the header
    document.body.style.setProperty('--compare-products-count', Math.max(count, 2));
  }

  /**
   * @param {Integer} n Number of blank columns to add
   */
  _addBlankColumns(n) {
    this.infoRows.forEach(row => {
      const templateCell = row.querySelector('[data-compare-cell-placeholder]');

      this.templateCells[row.dataset.compareRowId] = templateCell
        .parentNode.removeChild(templateCell);

      for (let i = 0; i < n; i++) {
        row.appendChild(templateCell.cloneNode(true));
      }
    });

    this.headingRows.forEach(row => {
      const heading = row.querySelector('[data-compare-heading]');
      heading.colSpan += n - 1; // allow for removed placeholder column
    });

    this._addScrollStops(this.el, n - 1);
  }

  _addScrollStops(el, n) {
    const scrollStop = el.querySelector('[data-compare-scroll-stop]');
    for (let i = 0; i < n; i++) {
      scrollStop.after(scrollStop.cloneNode(true));
    }
  }

  /**
   * Removes product from compare
   * @param {String} handle Handle to remove
   */
  _removeHandle(handle) {
    if (this.allHandles.length === 1) {
      this.animateToEmptyState();
      productCompare.remove(handle);
      this._setProductCountVar(this.allHandles.length);
      return;
    }

    this._removeColumn(handle);
    this.allHandles = this.allHandles.filter(h => h !== handle);
    this._setProductCountVar(this.allHandles.length);
    updateUrlForHandles(this.allHandles);
    productCompare.remove(handle);
  }

  /**
   *
   * @param {String} handle Handle for product to remove from table
   */
  _removeColumn(handle) {
    const insertPlaceholders = this.allHandles.length <= 2;

    [this.stickyBarRow, ...this.infoRows].forEach(row => {
      const cell = row.querySelectorAll('[data-compare-cell]')[this.allHandles.indexOf(handle)];
      if (insertPlaceholders) {
        cell.parentNode
          .append(this.templateCells[row.dataset.compareRowId].cloneNode(true));
      }
      cell.remove();
    });

    if (!insertPlaceholders) {
      this.headingRows.forEach(row => {
        const heading = row.querySelector('[data-compare-heading]');
        heading.colSpan--;
      });

      [this.stickyBar, this.el].forEach(el => {
        // Scrollstops are identical, doesn't matter which one we remove.
        el.querySelector('[data-compare-scroll-stop]').remove();
      });

      this.showMoreToggles.forEach(toggle => toggle.checkOverflow());
    }

    const productItemImages = this.el.querySelectorAll('[data-product-item-image] img');

    rimg.instance.update(productItemImages);
  }

  /**
   * Fetches data and populates the table based on a list of product handles.
   *
   * @param {[String]} handles Product handles for which to fetch data
   */
  _fetchCompareProducts(handles) {
    return handles.map((handle, index) => AsyncView.load(
      `${this.baseUrl}/${handle}`,
      this.sectionId,
      { view: 'compare' },
    ).then(({ html }) => {
      const parser = new DOMParser();
      const sourceEl = parser.parseFromString(html, 'text/html');
      // offset index to account for initial product
      this._populateContent(sourceEl, index + 1);
    }));
  }

  /**
   * Populates content in this section's table
   *
   * @param {HTMLElement} source Element containing all source rows and cells
   * @param {integer} columnIndex Index of target column for content
   */
  _populateContent(source, columnIndex) {
    source.querySelectorAll('[data-compare-row-type="info"]')
      .forEach(row => {
        const { compareRowId } = row.dataset;
        // Source data is always in the first cell
        const cellHTML = row.querySelector('[data-compare-cell]').innerHTML;

        const targetRow = this.el.querySelector(`[data-compare-row-id="${compareRowId}"]`);
        const targetCell = targetRow.querySelectorAll('[data-compare-cell]')[columnIndex];

        targetCell.innerHTML = cellHTML;

        targetCell.querySelectorAll('[data-compare-remove-on-populate').forEach(el => el.remove());

        this._initCell(targetCell);
      });

    this.showMoreToggles.forEach(toggle => toggle.checkOverflow());
  }

  _updateBreadcrumbs() {
    const breadcrumb = this.el.querySelector('[data-compare-breadcrumb]');

    if (!productCompare.returnBreadcrumb) {
      breadcrumb.remove();
      return;
    }

    const { url, title } = productCompare.returnBreadcrumb;

    breadcrumb.href = url;

    if (title) {
      const breadcrumbText = breadcrumb.querySelector('[data-compare-breadcrumb-text]');
      breadcrumbText.innerHTML = this.data.breadcrumb.replace('** location **', title);
    }

    const placeholders = this.el.querySelectorAll('[data-compare-placeholder-link]');

    placeholders.forEach(placeholder => {
      placeholder.href = url;
    });
  }

  _removeAllFilters() {
    this.activeFilterIds.forEach((_, id) => {
      document.getElementById(id).checked = false;
      this._updateFilter({ checked: false, id });
    });
  }

  _disableFilters() {
    this.filtersDisabled = true;
    this.filtersEl.querySelectorAll('[data-compare-filter-checkbox]')
      .forEach(checkbox => { checkbox.disabled = true; });
  }

  _updateFilter({ checked, id }) {
    if (this.filtersDisabled) return;

    if (checked) {
      this.activeFilterIds.set(id, true);
      this.filterCheckboxes.get(id).check();
    } else {
      this.activeFilterIds.delete(id);
      this.filterCheckboxes.get(id).uncheck();
    }

    this._updateAppliedFilters();
    this._updateActiveFilterCount();

    const activeFilterCount = this.activeFilterIds.size;

    if (activeFilterCount === 0) {
      // show all rows
      this.allRows.forEach(row => {
        row.style.display = '';
        row.classList.remove(lastRowClass);
      });
      return;
    }

    this.infoRows.forEach(row => {
      if ('compareIgnoreFilter' in row.dataset) return;

      row.style.display = this.activeFilterIds.has(row.dataset.compareRowId) ? '' : 'none';
    });

    this._updateHeadersDisplay();
  }

  _updateAppliedFilters() {
    this.activeFiltersEl.innerHTML = '';
    this.activeFilterIds.forEach((_, id) => {
      const removeFilter = this.removeFilterTemplate.cloneNode(true).content;
      removeFilter.firstElementChild.dataset.productCompareFilterRemove = id;
      removeFilter.querySelector('[data-compare-filter-remove-text]').innerHTML = this.filterLabels[id];
      this.activeFiltersEl.append(removeFilter);
    });

    if (this.activeFilterIds.size) {
      this.activeFiltersContainer.style.display = '';
      this.activeFiltersEl.append(this.clearAllFiltersTemplate.cloneNode(true).content);
    } else {
      this.activeFiltersContainer.style.display = 'none';
    }
  }

  _updateActiveFilterCount() {
    const filterText = this.el.querySelector('[data-compare-filter-text]');

    if (this.activeFilterIds.size > 0) {
      const filterTextString = this.data.filter_count.replace(
        '** count **',
        this.activeFilterIds.size,
      );
      filterText.innerHTML = filterTextString;
    } else {
      filterText.innerHTML = this.data.filter_count_zero;
    }
  }

  /**
   * Update visibility of header rows in table depending on visibility
   * of info rows
   */
  _updateHeadersDisplay() {
    let lastHeadingRow = null;
    let showLastHeading = false;
    let lastDisplayedRow = null;

    const updateHeadingDisplay = () => {
      if (lastHeadingRow) {
        lastHeadingRow.style.display = showLastHeading ? '' : 'none';
      }
    };

    const updateLastDisplayedRow = () => {
      if (lastDisplayedRow) {
        lastDisplayedRow.classList.add(lastRowClass);
      }
    };

    this.allRows.forEach(row => {
      row.classList.remove(lastRowClass);
      if (row.dataset.compareRowType === 'heading') {
        updateHeadingDisplay();
        updateLastDisplayedRow();
        lastHeadingRow = row;
        showLastHeading = false;
        lastDisplayedRow = null;
      } else if (this.activeFilterIds.has(row.dataset.compareRowId) || 'compareIgnoreFilter' in row.dataset) {
        lastDisplayedRow = row;
        showLastHeading = true;
      }
    });

    updateHeadingDisplay();
    updateLastDisplayedRow();
  }

  /**
   * Move active filters element into sidebar so it appears in the modal
   */
  _onBeforeFiltersModalOpen() {
    this.mobileActiveFiltersTarget.before(this.activeFiltersEl);
  }

  /**
   * Move active filters element back into normal location
   */
  _onFiltersModalClose() {
    this.activeFiltersContainer.append(this.activeFiltersEl);
  }

  _initCell(el) {
    const productItemDescription = el.querySelector('[data-compare-description]');

    if (productItemDescription) {
      this.descriptionItems.push(new RichText(productItemDescription));
      this.showMoreToggles.push(new ShowMoreToggle({ el, context: this.data.context }));
    }

    this._initProductItems(el);

    setupRippleEffect(el);
  }

  _initProductItems(el) {
    const productItemEls = el.querySelectorAll('[data-product-item]');

    productItemEls.forEach(productItem => {
      rimg.watch(el);

      this.productItems.push(new ProductGridItem({
        el: productItem,
        id: this.sectionId,
        disableActionsToggle: true,
        lazy: false,
      }));
    });

    if (productItemEls.length && window.Shopify && Shopify.PaymentButton) {
      Shopify.PaymentButton.init();
    }
  }
}
