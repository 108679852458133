import $ from 'jquery';
import $script from 'scriptjs';
import ComplementaryProducts from '@pixelunion/pxs-complementary-products';
import layout from '../Layout';

import RichText from '../components/RichText';
import ProductDetails from '../components/ProductDetails';

export default class Product {
  constructor(section, options = {}) {
    this.section = section;
    this.sectionId = section.id;
    this.el = this.section.el;
    this.$el = $(this.section.el);
    this.context = section.data.context;
    this.settings = section.data.settings;
    this.product = section.data.product;
    this.productRecommendationLimit = section.data.product_recommendation_limit;
    this.richText = null;
    this.readmoreText = null;
    this.reviewForm = null;

    // History
    this.isThemeEditor = window.Shopify && window.Shopify.designMode;
    this.useHistory = 'useHistory' in options
      ? options.useHistory
      : !!(!this.isThemeEditor && history.replaceState);

    // Complementary products
    const complementaryProductsEl = this.el.querySelector('[data-complementary-products]');

    if (complementaryProductsEl) {
      this.complementaryProducts = new ComplementaryProducts({
        sectionEl: this.el,
        sectionId: this.sectionId,
        productId: this.product.id,
        productRecommendationsRoute: window.Theme.routes.product_recommendations_url,
        includeIndicatorDots: true,
        limit: this.productRecommendationLimit,
        arrowShape: 'M 65.29,11.99 L 27.28,50 L 65.3,87.99 L 70.25,83.06 L 37.19,50 L 70.26,16.94 L 65.29,11.99 Z',
      });
    }

    const gallery = this.$el.find('[data-product-gallery]')[0];
    const productWrapper = this.$el.find('[data-product-wrapper]');

    // Product details
    this.$details = this.$el.find('[data-product-details]');

    // Product description
    this.$description = this.$el.find('[data-product-description]');
    this.$readmore = this.$el.find('[data-product-readmore]');

    // Product form containers
    this.$formRegular = this.$el.find('[data-product-form-regular]');
    this.$formAlt = this.$el.find('[data-product-form-alt]');

    // Product form area
    this.$formArea = this.$el.find('[data-product-form-area]');

    // Move product form and information on breakpoint change
    this.layoutHandler = this.onBreakpointChange.bind(this);
    layout.onBreakpointChange(this.layoutHandler);

    if (productWrapper.hasClass('product__container--three-columns')) {
      this._moveForm();
    }

    if (this.$description.length) {
      this.richText = new RichText(this.$description);
    }

    if (this.$readmore.length) {
      this.readmoreText = new RichText(this.$readmore);
    }

    // Instantiate ProductDetails after Shopify API is loaded
    $script($('[data-scripts]').data('shopify-api-url'), () => {
      this.productDetails = new ProductDetails({
        $formArea: this.$formArea,
        $details: this.$details,
        gallery,
        context: this.context,
        settings: this.settings,
        product: this.product,
        useHistory: this.useHistory,
        sectionId: section.id,
        productEl: this.$el[0],
      });
    });
  }

  onSectionUnload() {
    layout.offBreakpointChange(this.layoutHandler);

    if (this.productDetails) {
      this.productDetails.unload();
    }

    if (this.richText) {
      this.richText.unload();
    }

    if (this.readmoreText) {
      this.readmoreText.unload();
    }

    if (this.reviewForm) {
      this.reviewForm.unload();
    }
  }

  onBreakpointChange() {
    if (this.$formAlt.length) {
      this._moveForm();
    }
  }

  /**
   * Move product form if is a three column layout
   * @private
   */
  _moveForm() {
    if (layout.isGreaterThanBreakpoint('M')) {
      if (!$.contains(this.$formAlt[0], this.$formArea[0])) {
        const $form = this.$formArea.detach();
        this.$formAlt.append($form);
      }
    } else if (!$.contains(this.$formRegular[0], this.$formArea[0])) {
      const $form = this.$formArea.detach();
      this.$formRegular.append($form);
    }
  }
}
