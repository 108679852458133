import CountdownTimer from '@pixelunion/pxs-countdown-timer';
import ProductGridItem from '../components/ProductGridItem';
import { setupRippleEffect } from '../helpers/Ripple';

export default class DynamicCountdownTimer {
  constructor(section) {
    this.el = section.el;
    this.product = this.el.querySelector('[data-product-item]');
    new CountdownTimer(this.el);

    setupRippleEffect(this.el);

    if (this.product) {
      this.productItems = new ProductGridItem({
        el: this.product,
        id: section.id,
      });
    }
  }

  onSectionUnload() {
    if (this.product) {
      this.productItems.unload();
    }
  }
}
