import EventHandler from '@pixelunion/events';
import debounce from 'just-debounce';

export default class QuantitySelector {
  constructor({ quantityField, onChange }) {
    this.events = new EventHandler();
    this.field = quantityField;
    this.input = this.field.querySelector('[data-quantity-input]');
    this.plus = this.field.querySelector('[data-quantity-plus]');
    this.minus = this.field.querySelector('[data-quantity-minus]');
    this.minusButtonWrapper = this.field.querySelector('[data-button-wrapper-minus]');
    this.incrementValue = parseInt(this.input.dataset.incrementValue, 10);
    this.increaseAmount = this.increaseAmount.bind(this);
    this.decreaseAmount = this.decreaseAmount.bind(this);
    this.onChange = onChange ? debounce(onChange, 50) : () => {};

    this.registerEvents();
  }

  registerEvents() {
    this.increaseAmountClickEvent = this.events.register(this.plus, 'click', e => this.increaseAmount(e));
    this.decreaseAmountClickEvent = this.events.register(this.minus, 'click', e => this.decreaseAmount(e));
    this.setAmountChangeEvent = this.events.register(this.input, 'change', e => this.setAmount(e));
    this.quantityKeyUpEvent = this.events.register(this.input, 'keyup', e => this.quantityKeyUp(e));
    this.quantityKeyDownEvent = this.events.register(this.input, 'keydown', e => this.quantityKeyDown(e));

    this._updateMinusButton();
  }

  setAmount(e) {
    e.preventDefault();

    const count = this._getCount();

    if (!count) {
      this.input.value = this.incrementValue;
    }

    this._updateMinusButton();
    this.onChange(e.currentTarget);
  }

  _getCount() {
    return this.input.valueAsNumber;
  }

  increaseAmount(e) {
    e.preventDefault();
    const count = this._getCount();

    this.input.value = count + this.incrementValue;
    this._updateMinusButton();

    this.onChange(e.currentTarget);
  }

  decreaseAmount(e) {
    e.preventDefault();
    const count = this._getCount();

    this.input.value = count - this.incrementValue;
    this._updateMinusButton();

    this.onChange(e.currentTarget);
  }

  quantityKeyUp(e) {
    if (e.key === 'Backspace') {
      return;
    }

    this._updateMinusButton();

    this.onChange(e.currentTarget);
  }

  quantityKeyDown(e) {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  }

  _updateMinusButton() {
    const count = this._getCount();
    if (count <= this.incrementValue) {
      this.minusButtonWrapper.classList.add('quantity-selector__button-wrapper--disabled');
    } else {
      this.minusButtonWrapper.classList.remove('quantity-selector__button-wrapper--disabled');
    }
  }

  unload() {
    this.events.unregisterAll();
  }
}
