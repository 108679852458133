const classes = {
  block: 'pxu-lia-block',
  element: 'pxu-lia-element',
  playBlock: 'pxu-lia-block--play',
  resetBlock: 'pxu-lia-block--reset',
  playSection: 'pxu-lia-section--play',
  resetSection: 'pxu-lia-section--reset',
};

const mappingSelector = '[type="application/pxs-animation-mapping+json"]';

/**
 * Find common parent of this block and the closest animation mapping block.
 *
 * @param {HTMLElement} node Block from which to begin search.
 */
const getMappingParentFromBlock = ({ parentNode }) => (
  parentNode.querySelector(mappingSelector) ? parentNode : getMappingParentFromBlock(parentNode)
);

const reset = (block, elements) => {
  block.style.animationName = 'none';
  block.classList.add(classes.resetBlock);
  elements.forEach(element => { element.style.animationName = 'none'; });
};

const removeReset = (block, elements) => {
  block.style.animationName = '';
  block.classList.remove(classes.resetBlock);
  elements.forEach(element => { element.style.animationName = ''; });
};

const play = (block, elements) => {
  block.style.animationPlayState = 'running';
  block.classList.add(classes.playBlock);
  elements.forEach(element => { element.style.animationPlayState = 'running'; });
};

const pause = (block, elements) => {
  block.style.animationPlayState = '';
  block.classList.remove(classes.playBlock);
  elements.forEach(element => { element.style.animationPlayState = ''; });
};

/**
 * Play, or reset animations for a collection of blocks.
 *
 * @param {[HTMLElement]} blocks Blocks to process
 * @param {String} state One of 'play' or 'reset'.
 */
const loadInAnimations = (blocks, state = 'play') => {
  const callNextFrame = [];

  if (state === 'reset') {
    blocks.forEach(block => {
      const elements = block.querySelectorAll(`.${classes.element}`);

      pause(block, elements);
      reset(block, elements);
      callNextFrame.push(() => {
        removeReset(block, elements);
      });
    });

    const section = getMappingParentFromBlock(blocks[0]);
    section.classList.remove(classes.playSection);
    section.classList.add(classes.resetSection);

    callNextFrame.push(() => {
      section.classList.remove(classes.resetSection);
    });
  } else {
    blocks.forEach((block, blockIndex) => {
      // Set sequence for blocks
      block.style.setProperty('--pxu-lia-outer-sequence', blockIndex);

      // Set sequence for sub elements (heading, text, button, etc)
      const elements = block.querySelectorAll(`.${classes.element}`);

      elements.forEach((element, elementIndex) => {
        element.style.setProperty('--pxu-lia-inner-sequence', elementIndex);
      });

      if (block.classList.contains(classes.playBlock)) {
        reset(block, elements);
        callNextFrame.push(() => {
          removeReset(block, elements);
          play(block, elements);
        });
      } else {
        play(block, elements);
      }

      const section = getMappingParentFromBlock(blocks[0]);

      if (section.classList.contains(classes.playSection)) {
        section.classList.add(classes.resetSection);

        callNextFrame.push(() => {
          section.classList.remove(classes.resetSection);
          section.classList.add(classes.playSection);
        });
      } else {
        section.classList.add(classes.playSection);
      }
    });
  }

  window.requestAnimationFrame(() => callNextFrame.forEach(fn => fn()));
};

/**
 * Manually trigger load-in animations. Existing animations will be reset and replayed.
 *
 * @param {[HTMLElement]} blocks Blocks for which to play animations
 */
const playLoadInAnimations = blocks => loadInAnimations(blocks, 'play');

/**
 * Manually reset load-in animations to initial state.
 *
 * @param {[HTMLElement]} blocks Blocks for which to reset animations
 */
const resetLoadInAnimations = blocks => loadInAnimations(blocks, 'reset');

const intersectionCallback = (entries, observer) => {
  const toAnimate = new Map();

  entries.forEach(entry => {
    if (entry.isIntersecting) {
      // Animations only run once so stop observing
      observer.unobserve(entry.target);

      // Find all blocks on same level that are now intersecting and arrange by parent section
      const parentSequence = getMappingParentFromBlock(entry.target);
      const existingSiblings = toAnimate.get(parentSequence);
      toAnimate.set(
        parentSequence,
        existingSiblings ? [...existingSiblings, entry.target] : [entry.target],
      );
    }
  });

  toAnimate.forEach((blocks, parent) => playLoadInAnimations(blocks, parent));
};

let blockObserver = null;

/**
 * Removes blocks from the global intersection observer for load in animations.
 *
 * @param {[HTMLElement]} blocks Blocks to stop observing for automatic load-in-animation play
 */
const removeLoadInAnimationsAutoplay = blocks => blocks
  .forEach(block => blockObserver && blockObserver.unobserve(block));

const initLoadInAnimationsAutoplay = () => {
  // This assumes that animated elements are arranged by section,
  // block, and element. Animations are triggered when blocks
  // enter view, and are sequenced within sections by block and by subElement.
  // Sequencing information is applied as custom properties that can be referenced in css.
  blockObserver = new IntersectionObserver(intersectionCallback, { threshold: 0.3 });

  if (!('reduceAnimations' in document.body.dataset)) {
    const observe = parent => {
      parent
        .querySelectorAll(`.${classes.block}`)
        .forEach(block => blockObserver.observe(block));
    };

    observe(document);
  }
};

export {
  initLoadInAnimationsAutoplay,
  playLoadInAnimations,
  resetLoadInAnimations,
  removeLoadInAnimationsAutoplay,
};
