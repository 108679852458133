import EventHandler from '@pixelunion/events';
import MessageBanner from '../components/MessageBanner';
import Checkbox from '../components/Checkbox';

export default class Order {
  constructor() {
    this.el = document.querySelector('.template-order');
    this.checkboxEls = this.el.querySelectorAll('[data-checkbox]');
    this.atcButton = this.el.querySelector('[data-atc-button]');
    this.selectAllCheckbox = this.el.querySelector('[data-select-all-checkbox]');
    this.selectAllCheckboxInput = this.el.querySelector('[data-select-all-checkbox-input]');
    this.selectItemsCountEl = this.el.querySelector('[data-select-items-count]');
    this.lineCheckboxInputs = this.el.querySelectorAll('[data-line-checkbox-input]');

    if (!this.selectAllCheckboxInput) return;

    this.lineCheckboxInputsArray = Array.from(this.lineCheckboxInputs);
    this.data = JSON.parse(this.el.querySelector('[data-order-line-items-data]').innerHTML);
    this.settings = JSON.parse(this.el.querySelector('[data-settings]').innerHTML);
    this.itemsToAddToCart = [];
    this.messageBanner = null;

    this.lastCheckedIndex = null;
    this.currentCheckedIndex = null;

    // We need to create a `Checkbox` instance for each checkbox and access them individually.
    // `this.map` is what will hold each input element along with its `Checkbox` instance.
    this.map = new Map();

    this.events = new EventHandler();

    this._init();
  }

  get _selectedLines() {
    return this.el.querySelectorAll('[data-line-checkbox-input]:checked');
  }

  get _allLineCheckboxesAreChecked() {
    return this.lineCheckboxInputsArray.filter(inputEl => !inputEl.checked).length === 0;
  }

  get _noCheckboxIsChecked() {
    return this.lineCheckboxInputsArray.filter(inputEl => inputEl.checked).length === 0;
  }

  _init() {
    this.checkboxEls.forEach(el => {
      this.map.set(el, new Checkbox(el));
    });

    this._bindCheckboxEvents();

    this.events.register(this.atcButton, 'click', event => {
      this._selectItemsToAddToCart();
      this._addToCart(event);
    });
  }

  _bindCheckboxEvents() {
    this.events.register(this.selectAllCheckboxInput, 'change', () => {
      const isChecked = this.selectAllCheckboxInput.checked;

      this._onCheckboxChange(this.selectAllCheckboxInput, isChecked);

      this.lineCheckboxInputs.forEach(inputEl => {
        inputEl.checked = isChecked;
        this._onCheckboxChange(inputEl, isChecked);
      });

      this._onCheckboxStateUpdate();
    });

    this.lineCheckboxInputs.forEach(inputEl => {
      this.events.register(inputEl, 'change', () => {
        const isInputElChecked = inputEl.checked;
        this._onCheckboxChange(inputEl, isInputElChecked);
        if (isInputElChecked) this._updateCheckedIndexes(inputEl);
        this._onCheckboxStateUpdate();
      });
    });

    // Shift + click multi-select feature
    this.events.register(document, 'click', event => {
      if (!event.shiftKey || event.target.nodeName !== 'INPUT') return;

      window.requestAnimationFrame(() => {
        if (this.lastCheckedIndex !== null && this.currentCheckedIndex !== null) {
          const lastCheckedIndexState = this.lineCheckboxInputsArray[this.lastCheckedIndex].checked;
          let start = null;
          let end = null;

          if (this.lastCheckedIndex < this.currentCheckedIndex) {
            start = this.lastCheckedIndex;
            end = this.currentCheckedIndex;
          } else {
            start = this.currentCheckedIndex;
            end = this.lastCheckedIndex;
          }

          for (let i = start; i < end; i++) {
            const el = this.lineCheckboxInputsArray[i];
            el.checked = lastCheckedIndexState;
            this._onCheckboxChange(el, lastCheckedIndexState);
          }

          this._onCheckboxStateUpdate();
          this._resetCheckedIndexes();
        }
      });
    });
  }

  _updateCheckedIndexes(el) {
    // Checking for `null` since `this.lastCheckedIndex` can have a value of `0`
    if (this.lastCheckedIndex === null) {
      this.lastCheckedIndex = this.lineCheckboxInputsArray.indexOf(el);
    } else {
      this.currentCheckedIndex = this.lineCheckboxInputsArray.indexOf(el);
    }
  }

  _resetCheckedIndexes() {
    this.lastCheckedIndex = null;
    this.currentCheckedIndex = null;
  }

  _onCheckboxChange(el, isChecked, isIndeterminate = false) {
    const targetEl = el.parentElement;

    if (isChecked) {
      this.map.get(targetEl).unsetIndeterminate();
      this.map.get(targetEl).check();
      el.closest('[data-order-row]')?.classList.add('checkbox-selected');
    } else if (isIndeterminate) {
      this.map.get(targetEl).uncheck();
      this.map.get(targetEl).setIndeterminate();
      el.closest('[data-order-row]')?.classList.add('checkbox-selected');
    } else {
      this.map.get(targetEl).uncheck();
      this.map.get(targetEl).unsetIndeterminate();
      el.closest('[data-order-row]')?.classList.remove('checkbox-selected');
    }
  }

  _onCheckboxStateUpdate() {
    let isChecked = false;
    let isIndeterminate = false;

    if (this._allLineCheckboxesAreChecked) {
      isChecked = true;
      isIndeterminate = false;
    } else if (!this._noCheckboxIsChecked) {
      isChecked = false;
      isIndeterminate = true;
    }

    this.selectAllCheckboxInput.checked = isChecked;
    this.selectAllCheckboxInput.indeterminate = isIndeterminate;
    this._onCheckboxChange(this.selectAllCheckboxInput, isChecked, isIndeterminate);

    if (this._noCheckboxIsChecked) {
      this._disableAtcButton();
      this.selectAllCheckbox.classList.remove('order-checkbox--active');
    } else {
      this._enableAtcButton();
      this.selectAllCheckbox.classList.add('order-checkbox--active');
      this.selectItemsCountEl.textContent = this._selectedLines.length;
    }
  }

  _selectItemsToAddToCart() {
    // Reset itemsToAddToCart list
    this.itemsToAddToCart.length = 0;

    const selectedOrderIds = [];

    this._selectedLines.forEach(line => {
      selectedOrderIds.push(Number(line.getAttribute('data-line-item-id')));
    });

    this.data.forEach(item => {
      if (selectedOrderIds.includes(item.id)) {
        // Shopify docs: https://shopify.dev/api/ajax/reference/cart
        this.itemsToAddToCart.push({
          id: item.variant_id,
          quantity: item.quantity,
          selling_plan: (
            item.selling_plan_allocation
              ? item.selling_plan_allocation.selling_plan.id
              : null
          ),
        });
      }
    });
  }

  _addToCart(event) {
    // Stopping further propagation here is mainly to prevent the click event from bubbling as
    // it will cause an issue with the disclosures and message banner.
    event.stopPropagation();

    this._setAtcButtonProcessing();

    const formData = { items: this.itemsToAddToCart };

    fetch(`${window.Theme.routes.cart_add_url}.js`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then(response => response.json())
      .then(data => {
        this._removeAtcButtonProcessing();
        if (data.message === 'Cart Error') {
          return Promise.reject(data);
        }
        return this._onSuccess();
      })
      .catch(error => {
        this._showErrorBanner(error.description);
      });
  }

  _onSuccess() {
    return fetch(`${window.Theme.routes.cart_url}.js`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (!response.ok) {
          return Promise.reject(response);
        }
        return response.json();
      })
      .then(data => {
        if (this.settings.cart_redirection) {
          location.href = window.Theme.routes.cart_url;
          return;
        }

        // Notify Header of new cart count
        const countEvent = new CustomEvent('cartcount:update', { detail: data });
        window.dispatchEvent(countEvent);

        this._showSuccessBanner(this.settings.success_message);
      })
      .catch(error => {
        this._showErrorBanner(error.message);
      });
  }

  _showSuccessBanner(successMsg) {
    this.messageBanner = new MessageBanner(successMsg, 'success');
  }

  _showErrorBanner(errorMsg) {
    this.messageBanner = new MessageBanner(errorMsg, 'error');
  }

  _enableAtcButton() {
    this.atcButton.classList.remove('disabled');
    this.atcButton.disabled = false;
  }

  _disableAtcButton() {
    this.atcButton.classList.add('disabled');
    this.atcButton.disabled = true;
  }

  _removeAtcButtonProcessing() {
    this.atcButton.classList.remove('processing');
    this.atcButton.disabled = false;
  }

  _setAtcButtonProcessing() {
    this.atcButton.classList.add('processing');
    this.atcButton.disabled = true;
  }
}
