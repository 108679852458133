import EventHandler from '@pixelunion/events';
import { transition } from '@pixelunion/animations';
import productCompare from './ProductCompare';
import ProductCompareDrawerContent from './ProductCompareDrawerContent';

const storageKey = 'pxuProductCompareFlyoutV1';

export default class ProductCompareFlyout {
  constructor(el) {
    this.el = el;
    this.events = new EventHandler();
    this.compareDrawerLink = this.el.querySelector('[data-product-compare-drawer-link]');
    this.compareDrawerLinkHref = this.compareDrawerLink.href;
    this.compareDrawerClearAllButton = this.el.querySelector('[data-product-compare-clear-all]');
    this.compareDrawerTitle = this.el.querySelector('[data-product-compare-drawer-title]');
    this.compareDrawerNotification = this.el.querySelector('[data-product-compare-drawer-notification]');
    this.compareDrawerCountOne = this.compareDrawerNotification.dataset.productCountOne;
    this.compareDrawerCountOther = this.compareDrawerNotification.dataset.productCountOther;
    this.compareDrawerHeader = this.el.querySelector('[data-product-compare-drawer-header]');
    this.compareDrawerContent = new ProductCompareDrawerContent(this.el.querySelector('[data-compare-drawer-items-container]'));

    this.preferredState = this._loadPreferredState() || 'open';

    this.drawerAnimation = transition({
      state: 'hidden',
      el: this.el,
    });

    this.events.register(window, 'resize', () => {
      if (this.drawerAnimation.state === 'hidden') return;

      this._updateDrawerPosition();
    });

    this.events.register(this.compareDrawerHeader, 'click', () => {
      if (this.drawerAnimation.state === 'open') {
        this._savePreferredState('closed');
        this._closeDrawer();
      } else {
        this._savePreferredState('open');
        this._openDrawer();
      }
    });

    this.events.register(this.compareDrawerClearAllButton, 'click', () => {
      productCompare.removeAll();
    });

    let lastUpdateCount = productCompare.products.length;

    const onUpdate = ({ products }) => {
      if (products.length > 0) {
        if (products.length > lastUpdateCount) {
          // drawer should always open when a new product is added
          this._openDrawer();
          this._savePreferredState('open');
        } else {
          this._showDrawer();
        }
      } else {
        this._hideDrawer();
      }

      lastUpdateCount = products.length;

      this._updateDrawerCompareLink();
      this._updateDrawerNotification();
    };

    onUpdate({ products: productCompare.products });

    productCompare.runOnUpdate(onUpdate);
  }

  unload() {
    this.events.unregisterAll();
    this.compareDrawerContent.unload();
    this.drawerAnimation.unload();
  }

  _showDrawer() {
    this.drawerAnimation.animateTo(
      this.preferredState,
      { onStart: () => { this._updateDrawerPosition(); } },
    );
  }

  _openDrawer() {
    this.drawerAnimation.animateTo(
      'open',
      { onStart: () => { this._updateDrawerPosition(); } },
    );

    this._updateDrawerCompareLink();
    this._updateDrawerNotification();
  }

  _hideDrawer() {
    this.drawerAnimation.animateTo('hidden');
  }

  _closeDrawer() {
    this.drawerAnimation.animateTo('closed');

    this._updateDrawerCompareLink();
    this._updateDrawerNotification();
  }

  _updateDrawerPosition() {
    this.el.style.setProperty('--compare-drawer-header-height', `${this.compareDrawerHeader.offsetHeight}px`);
  }

  _updateDrawerNotification() {
    if (productCompare.products.length === 1) {
      this.compareDrawerNotification.innerHTML = this.compareDrawerCountOne;
    } else {
      const compareDrawerNotificationString = this.compareDrawerCountOther.replace(
        '** count **',
        productCompare.products.length,
      );
      this.compareDrawerNotification.innerHTML = compareDrawerNotificationString;
    }
  }

  _updateDrawerCompareLink() {
    const compareDrawerLinkText = this.compareDrawerLink.dataset.productCompareDrawerLinkText;
    // Default locale will end in `/`, other locales will end in `/de` for example
    const separator = /\/$/.test(this.compareDrawerLinkHref) ? '' : '/';
    const [firstProduct, ...otherProducts] = productCompare.products;

    if (productCompare.products.length > 1) {
      this.compareDrawerLink.classList.remove('disabled');
    } else {
      this.compareDrawerLink.classList.add('disabled');
    }

    if (productCompare.products.length > 0) {
      this.compareDrawerLink.innerHTML = `${compareDrawerLinkText} (${productCompare.products.length})`;
    } else {
      this.compareDrawerLink.innerHTML = `${compareDrawerLinkText}`;
    }

    if (!firstProduct) return;

    const otherProductHandles = otherProducts.map(({ handle }) => handle).join(',');
    this.compareDrawerLink.href = `${this.compareDrawerLinkHref}${separator}products/${firstProduct.handle}?view=compare&compare=${otherProductHandles}`;
  }

  _loadPreferredState() {
    try {
      return JSON.parse(sessionStorage.getItem(storageKey));
    } catch (e) {
      return null;
    }
  }

  _savePreferredState(state) {
    this.preferredState = state;
    sessionStorage.setItem(storageKey, JSON.stringify(state));
  }
}
