import EventHandler from '@pixelunion/events';
import Layout from '../../Layout';

function sanitizeCategory(value) {
  return value.replace(/(tag|product_type):(searchfilter_)?/, '');
}

export default class SearchForm {
  constructor(container, options = {}) {
    this.container = container;
    this.form = this.container.querySelector('[data-live-search-form]');
    this.filter = this.container.querySelector('[data-live-search-filter]');

    this.isLiveSearch = options.liveSearch ? options.liveSearch : false;
    this.setCategory = options.setCategory || (() => { /* do nothing */ });

    this.events = new EventHandler();

    this.events.register(this.form, 'submit', e => this.submitHandler(e));

    if (this.filter) {
      this.filterLabel = this.container.querySelector('[data-live-search-filter-label]');

      // page-search-form uses a hidden input to persist the filter, in which case
      // this.filter exists, but this.filterLabel does not.
      if (this.filterLabel) {
        this.setCategory(this.filterLabel.value);

        this.events.register(this.filter, 'change', e => {
          const { value } = e.target;
          if (value) {
            const newValue = sanitizeCategory(value);
            this.filterLabel.innerHTML = newValue;
            this.setCategory(newValue);
            this.form.classList.add('live-search-filter-active');
          } else {
            this.filterLabel.innerHTML = e.target.dataset.filterAll;
            this.form.classList.remove('live-search-filter-active');
            this.setCategory('');
          }
        });
      }

      const hideFilterIfMobile = () => {
        if (Layout.isLessThanBreakpoint('S')) {
          this.filter.value = '';
          this.form.classList.remove('live-search-filter-active');
          if (this.filterLabel) {
            this.filterLabel.innerHTML = this.filter.dataset.filterAll;
          }
        }
      };

      hideFilterIfMobile();
      Layout.onBreakpointChange(() => hideFilterIfMobile());
    }
  }

  unload() {
    this.events.unregisterAll();
  }

  submitHandler(event) {
    event.preventDefault();

    const form = event.currentTarget.cloneNode(true);
    const termsInput = form.querySelector('[name=q]');

    form.style.position = 'absolute';
    form.style.left = '-1000px';
    form.style.bottom = '-1000px';
    form.style.visibility = 'hidden';

    let terms = termsInput.value;

    if (this.isLiveSearch && !terms) {
      return;
    }

    // Add field filter
    // Filter should always come before the input terms,
    // otherwise Shopify won't return the expected results.
    terms = this.filter && this.filter.value ? `${this.filter.value} AND ${terms}` : terms;

    // Update value
    termsInput.value = terms;

    // Forms must be in the browser context in order to submit
    window.document.body.appendChild(form);
    form.submit();
  }
}
