import Flickity from 'flickity';
import EventHandler from '@pixelunion/events';
import FlickityA11yPatch from '../helpers/FlickityA11yPatch';
import ProductGridItem from './ProductGridItem';
import layout from '../Layout';

export default class FeaturedCollection {
  constructor({ el, sectionId }) {
    this.el = el;
    this.contentWrapperEl = el.querySelector('[data-content-wrapper]');
    this.contentEl = el.querySelector('[data-content]');
    this.flickityA11yPatch = new FlickityA11yPatch(this.contentEl);
    this.events = new EventHandler();

    this._resizeObserver = new ResizeObserver(() => {
      let foundTransitionEnd = false;
      this.events.register(this.el, 'transitionend', () => { foundTransitionEnd = true; });

      setTimeout(() => {
        if (foundTransitionEnd) return;

        if (this.flickity && 'resize' in this.flickity) {
          this.flickity.resize();
        }
      }, 500);
    });

    /*
     * We keep reference to the original layout of the collection
     * because dependent upon viewport width, we may need to enforce
     * one layout over the other.
     */
    this.initialDesktopLayout = this.contentEl.dataset.layout;
    this.initialMobileLayout = this.contentEl.dataset.mobileLayout;

    // Product items
    this.productItems = [];

    const productItemsEls = this.el.querySelectorAll('[data-product-item]');

    productItemsEls.forEach(productItemEl => {
      this._resizeObserver.observe(productItemEl.querySelector('.productitem__container'));
      this.productItems.push(new ProductGridItem({
        el: productItemEl,
        id: sectionId,
        lazy: false,
      }));
    });

    if (this.initialDesktopLayout === 'slideshow' || this.initialMobileLayout === 'slideshow') {
      this.onBreakpointChange = () => {
        if (this.useDesktopSlideshow || this.useMobileSlideshow) {
          this._initializeFlickity();
        } else {
          this._destroyFlickity();
        }
      };
      layout.onBreakpointChange(this.onBreakpointChange);

      if (this.useDesktopSlideshow || this.useMobileSlideshow) {
        window.requestAnimationFrame(() => this._initializeFlickity());
      }
    }
  }

  get useDesktopSlideshow() {
    return this.initialDesktopLayout === 'slideshow' && layout.isGreaterThanBreakpoint('M', true);
  }

  get useMobileSlideshow() {
    return this.initialMobileLayout === 'slideshow' && layout.isLessThanBreakpoint('M');
  }

  unload() {
    this.productItems.forEach(productItem => productItem.unload());
    layout.offBreakpointChange(this.onBreakpointChange);
    this.events.unregisterAll();

    this._destroyFlickity();

    if (this.flickityA11yPatch) {
      this.flickityA11yPatch.unload();
    }
  }

  _initializeFlickity() {
    if (this.flickity) return; // Already initialized

    this.contentEl.dataset.layout = 'slideshow';
    this.flickity = new Flickity(
      this.contentEl,
      {
        autoPlay: 0,
        accessibility: true,
        cellAlign: 'left',
        cellSelector: '.productgrid--item',
        groupCells: true,
        pageDots: false,
        contain: true,
        arrowShape: 'M65.29 11.99L27.28 50L65.3 87.99L70.25 83.06L37.19 50L70.26 16.94L65.29 11.99Z',
      },
    );

    const viewport = this.contentEl.querySelector('.flickity-viewport');
    const slider = this.contentEl.querySelector('.flickity-slider');

    /*
     * We must wrap Flickity's slider element to allow the usage of
     * clip, and clip-path to obscure the overflow product items.
     * To use clip, and clip-path, the clipped element must be absolutely
     * positioned. In this case, the only native Flickity element
     * that is absolutely positioned is the slider; but, it moves with the first
     * slide rather than remaining in the viewport removing it as a candidate.
     * Therefore, it is necessary to include a wrapper element that we can
     * absolutely position that remains within the viewport.
     * The only thing we need to watch out for is that the elements
     * are moved in a non-destructive manner.
     */
    const sliderWrapper = document.createElement('div');
    sliderWrapper.classList.add('flickity-slider--wrapper');
    viewport.appendChild(sliderWrapper);
    sliderWrapper.appendChild(slider);

    // Do not try resizing if user is scrolling/changing slides
    let hasCellChanged = false;

    this.flickity.on('change', () => {
      hasCellChanged = true;
    });

    this.events.register(this.el, 'transitionend', () => {
      if (hasCellChanged || !this.flickity) return;
      this.flickity.resize();
      hasCellChanged = false;
    });
  }

  _destroyFlickity() {
    if (!this.flickity) return; // Already uninitialized

    this.contentEl.dataset.layout = this.initialDesktopLayout;
    this.contentEl.dataset.mobile_layout = this.initialMobileLayout;
    const viewport = this.contentEl.querySelector('.flickity-viewport');
    const slider = this.contentEl.querySelector('.flickity-slider');

    /*
     * Remember to move the Flickity native elements back
     * into the correct DOM layout, before removing the added
     * wrapper.
     */
    const sliderWrapper = this.contentEl.querySelector('.flickity-slider--wrapper');
    viewport.appendChild(slider);
    viewport.removeChild(sliderWrapper);

    this.flickity.destroy();
    this.flickity = null;
  }
}
