import { transition } from '@pixelunion/animations';

import * as SiteMainDimmer from '../../helpers/site-main-dimmer';
import NavDesktopMenu from './NavDesktopMenu'; // eslint-disable-line import/no-cycle

export default class NavDesktopParent {
  constructor(el, options) {
    this.listitem = el;
    this.link = null;
    this.submenu = null;
    this._isOpen = false;
    this.menu = null;
    this.parentMenu = options.parentMenu;
    this.closeSiblings = this.parentMenu.closeSiblings;

    this.details = el.querySelector('[data-navmenu-details]');

    const { children } = this.details;

    for (let i = 0; i < children.length; i++) {
      if (children[i].classList.contains('navmenu-link')) {
        this.link = children[i];
      } else if (children[i].classList.contains('navmenu-submenu')) {
        this.submenu = children[i];
      }
    }

    this.animation = transition({ el: this.submenu, state: 'closed' });

    this.open = () => {
      this._open();
    };
    this.close = () => {
      this._close();
    };
    this.closeEsc = e => {
      if (e.key === 'Escape') {
        this.link.focus();
        this.close();
      }
    };

    this.closeTimer = null;
    this.openTimer = null;
    this.mouseover = () => {
      clearTimeout(this.closeTimer);
      if (!this.submenu.classList.contains('navmenu-depth-3')) {
        this.openTimer = setTimeout(this.open, 200);
      } else {
        this.open();
      }
    };

    this.mouseout = () => {
      clearTimeout(this.openTimer);
      this.closeTimer = setTimeout(this.close, 400);
    };

    this.click = e => {
      e.stopPropagation();

      const clickedParent = e.target.closest('.navmenu-link-parent');

      // If the link being clicked is a parent link, then take control of the navigation
      if (clickedParent && clickedParent.dataset.href) {
        e.preventDefault();
        // if already open, continue to link destination
        if (this._isOpen) {
          window.location = clickedParent.dataset.href;
          return;
        }
        // If the submenu isn't open, open it
        this.open();
      }
    };

    this.focusin = e => {
      e.stopPropagation();
      if (e.target.classList.contains('navmenu-link-parent')) {
        this.closeSiblings(this);
      }
    };

    this.touchend = e => {
      e.target.focus();
      this.click(e);
    };

    this.listitem.addEventListener('mouseover', this.mouseover);
    this.listitem.addEventListener('mouseout', this.mouseout);
    this.details.addEventListener('touchend', this.touchend);
    this.details.addEventListener('click', this.click);
    this.listitem.addEventListener('focusin', this.focusin);

    document.body.addEventListener('click', this.close);
    document.body.addEventListener('focusin', this.close);
  }

  get isOpen() {
    return this._isOpen;
  }

  forceOpen() {
    return this._open(true);
  }

  forceClose() {
    return this._close(true);
  }

  _open() {
    if (this._isOpen) return;

    this._isOpen = true;
    this.details.setAttribute('open', 'open');
    SiteMainDimmer.dim(this);
    this.closeSiblings(this);
    window.addEventListener('keydown', this.closeEsc);

    if (!this.menu) {
      this.menu = new NavDesktopMenu(this.submenu);
    }

    this.animation.animateTo('open', {
      hold: true,
      onStart: ({ el }) => {
        let height = 0;

        for (let i = 0; i < el.children.length; i++) {
          height += el.children[i].offsetHeight;
        }

        this.listitem.style.setProperty('--menu-open-height', `${height}px`);

        const isMeganav = this.listitem.closest('[data-navmenu-meganav-type]');

        // Check for alternate side dropdown
        const bounds = this.submenu.getBoundingClientRect();
        if (bounds.right > document.documentElement.clientWidth && !isMeganav) {
          this.listitem.classList.add('alternate-drop');
        }
      },
    })
      .then(state => {
        if (state === 'open') {
          this.link.setAttribute('aria-expanded', true);
        }
      });
  }

  _close(force = false) {
    if (!this._isOpen) return;


    if (this.menu) {
      this.menu.unload();
      this.menu = null;
    }

    this._isOpen = false;
    window.removeEventListener('keydown', this.closeEsc);

    SiteMainDimmer.clear(this);

    this.listitem.classList.remove('navmenu-item--preselected');

    this.animation.animateTo('closed', { force })
      .then(state => {
        if (state === 'closed') {
          this.listitem.classList.remove('alternate-drop');
          this.link.setAttribute('aria-expanded', false);
          this.parentMenu.openSelectedBlock();
          this.details.removeAttribute('open');
        }
      });
  }

  unload() {
    this.forceClose();
    this.listitem.removeEventListener('mouseover', this.mouseover);
    this.listitem.removeEventListener('mouseout', this.mouseout);
    this.listitem.removeEventListener('touchend', this.touchend);
    this.listitem.removeEventListener('click', this.click);
    this.listitem.removeEventListener('focusin', this.focusin);

    window.removeEventListener('keydown', this.closeEsc);
    document.body.removeEventListener('click', this.bodyClose);
    document.body.removeEventListener('focusin', this.focusInClose);

    this.animation.unload();
  }
}
