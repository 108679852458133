import $ from 'jquery';
import debounce from 'just-debounce';
import VanillaModal from 'vanilla-modal';
import {
  trapFocus,
  removeTrapFocus,
} from '@shopify/theme-a11y';

import ScrollLock from '../helpers/ScrollLock';

let openModals = [];

const unlockScrollLock = () => {
  if (openModals.length === 0) {
    ScrollLock.unlock();
  }
};

export default class Modal {
  constructor(options = {}) {
    this.$body = $(document.body);
    this.$window = $(window);

    // Extend default vanilla-modal callbacks back to instantiator of Modal
    const defaultOptions = {
      onOpen: () => {},
      onClose: () => {},
      onBeforeOpen: () => {},
      onBeforeClose: () => {},
      modalId: null,
    };

    this.options = { ...defaultOptions, ...options };

    const modalSelector = this.options.modalId
      ? `[data-modal-container-${this.options.modalId}]`
      : '[data-modal-container]';

    const closeSelector = this.options.modalId
      ? `[data-modal-${this.options.modalId}-close`
      : '[data-modal-close]';

    this.loadedClass = this.options.modalId ? `modal-${this.options.modalId}-loaded` : 'modal-loaded';
    this.visibleClass = this.options.modalId ? `modal-${this.options.modalId}-visible` : 'modal-visible';

    this.modal = null;

    this.$modal = $(modalSelector);
    this.$modalInner = this.$modal.find('[data-modal-inner]');

    this.finishedLoading = this.finishedLoading.bind(this);

    this._onOpen = this._onOpen.bind(this);
    this._onBeforeOpen = this._onBeforeOpen.bind(this);
    this._onClose = this._onClose.bind(this);
    this._onBeforeClose = this._onBeforeClose.bind(this);
    this._closeEsc = this._closeEsc.bind(this);

    this.position = this.position.bind(this);

    this.modalOptions = {
      modal: modalSelector,
      loadClass: '',
      class: this.loadedClass,
      close: closeSelector,
      onOpen: this._onOpen,
      onClose: this._onClose,
      onBeforeOpen: this._onBeforeOpen,
      onBeforeClose: this._onBeforeClose,
      transitions: false,
      closeKeys: [], // Override default "close on esc" so we have control
    };
  }

  unload() {
    if (!this.modal) return;

    this.modal.destroy();

    openModals = openModals.filter(modal => modal !== this);
    unlockScrollLock();
  }

  /**
   * Open a modal with contents from selector
   *
   * @param selector
   * @param handle
   */
  open(selector, handle = 'general') {
    this._addModalClass(handle);
    this.modal = new VanillaModal(this.modalOptions);
    this.modal.open(selector);
    openModals.push(this);
    window.addEventListener('keydown', this._closeEsc);
  }

  close() {
    this.modal.close();
    window.removeEventListener('keydown', this._closeEsc);
  }

  finishedLoading() {
    trapFocus(this.$modal[0]);
  }

  _closeEsc(e) {
    if (e.key === 'Escape' && openModals[openModals.length - 1] === this) {
      this.close();
    }
  }

  isOpen() {
    return this.modal && this.modal.isOpen;
  }

  /**
   * Update the vertical positioning of modal
   */
  position() {
    const windowHeight = window.innerHeight;

    const modalHeight = this.$modalInner.outerHeight();
    const modalPadding = parseInt(this.$modal.css('padding-top'), 10) * 2;

    const offset = (windowHeight - modalPadding - modalHeight) / 2;
    const marginTop = offset > 0 ? offset : 0;
    this.$modalInner.css({ marginTop });
  }

  /**
   * Add a class to the modal for individual styling
   * @param handle
   * @private
   */
  _addModalClass(handle) {
    this.$modal.addClass(`modal--${handle}`);
  }

  /**
   * Remove modal class based on the handle
   * @private
   */
  _removeModalClass() {
    const modalClass = this.$modal.attr('class').match(/modal--[\w-]*\b/);
    if (!modalClass) {
      return;
    }

    this.$modal.removeClass(modalClass[0]);
  }

  _onClose() {
    this._removeModalClass();
    this.$body.removeClass(this.visibleClass);

    this.$window.off('resize.modal');

    this.$modalInner.css({ marginTop: '' });

    this.options.onClose();

    removeTrapFocus(this.$modal[0]);

    if (this.activeElement) {
      // Ensure focus is properly re-trapped in the case when modal was
      // opened from another modal
      const focusTrap = this.activeElement.closest('[data-trap-focus]');
      if (focusTrap) {
        trapFocus(focusTrap);
      }

      this.activeElement.focus();
    }

    openModals = openModals.filter(modal => modal !== this);
    unlockScrollLock();
  }

  _onOpen() {
    this.activeElement = document.activeElement;
    this.position();
    ScrollLock.lock(this.$modal[0]);
    this.$body.addClass(this.visibleClass);
    this.$window.on('resize.modal', debounce(() => this.position(), 16, true, true));

    this.options.onOpen();

    trapFocus(this.$modal[0]);
  }

  _onBeforeClose() {
    this.options.onBeforeClose();
  }

  _onBeforeOpen() {
    this.options.onBeforeOpen();
  }
}
