import $ from 'jquery';
import layout from '../Layout';

// Adjusts the height of the block so it can contain the wrapper within it
const adjustHeight = block => {
  const $block = $(block);
  const $wrapper = $block.find('.promo-block--content-wrapper');

  const padding = window.getComputedStyle($block[0], null).getPropertyValue('padding-top').replace('px', '');

  if ($block.innerHeight() - (padding * 2) < $wrapper.innerHeight()) {
    $block.css({ height: `${$wrapper.innerHeight() + (padding * 2)}px` });
    $wrapper.css({ transform: 'none', top: 'auto' });
  }
};

// Removes height settings on the block because they only need to be there for small screens
const resetHeight = block => {
  const $block = $(block);
  const $wrapper = $block.find('.promo-block--content-wrapper');
  $block.css({ height: '' });
  $wrapper.css({ transform: '', top: '' });
};

export default class DynamicPromoBlocks {
  constructor(section) {
    this.$el = $(section.el);
    this.content = '[data-promo-block-content]';
    this.expandedClass = 'promo-block--expanded';
    this.compressBlocks = section.data.compress_blocks;

    // Revert navigation to original state on breakpoint change
    this.layoutHandler = this.onBreakpointChange.bind(this);
    layout.onBreakpointChange(this.layoutHandler);

    this._blockInteraction = this._blockInteraction.bind(this);

    this.$el.on('click.promo-block', this.content, this._blockInteraction);
    if (!this.compressBlocks && layout.isLessThanBreakpoint('S')) {
      this.$el.find(this.content).each((index, block) => {
        adjustHeight(block);
      });
    }

    if (!this.compressBlocks) {
      $(window).on('resize', () => {
        this.$el.find(this.content).each((index, block) => {
          if (layout.isLessThanBreakpoint('S')) {
            adjustHeight(block);
          } else {
            resetHeight(block);
          }
        });
      });
    }
  }

  /**
   * Remove block's toggled state and attributes when leaving mobile
   */
  onBreakpointChange() {
    if (!layout.isLessThanBreakpoint('S')) {
      this.$el.find(`.${this.expandedClass}`).each((i, content) => {
        this._collapse(content);
      });

      this.$el.find(this.content).each((index, block) => {
        resetHeight(block);
      });
    } else if (!this.compressBlocks) {
      this.$el.find(this.content).each((index, block) => {
        adjustHeight(block);
      });
    }
  }

  /**
   * Unbind events when section is re-drawn
   */
  onSectionUnload() {
    this.$el.off('.promo-block');
    layout.offBreakpointChange(this.layoutHandler);
  }

  /**
   * Callback to open block on mobile from the TE
   *
   * @param block
   */
  onSectionBlockSelect(block) {
    if (!layout.isLessThanBreakpoint('S')) return;

    this._expand(block.el.querySelector(this.content));
  }

  /**
   * Callback to close block on mobile from the TE
   *
   * @param block
   */
  onSectionBlockDeselect(block) {
    if (!layout.isLessThanBreakpoint('S')) return;

    this._collapse(block.el.querySelector(this.content));
  }

  /**
   * Expand a block on first click, then allow it to behave as normal
   *
   * @param event
   * @private
   */
  _blockInteraction(event) {
    const content = event.currentTarget;
    const clicked = content.getAttribute('data-clicked');

    // On second click, or on desktop, don't interfere with block
    if (clicked || !layout.isLessThanBreakpoint('S') || !this.compressBlocks) {
      return;
    }

    event.preventDefault();
    content.setAttribute('data-clicked', 'clicked');

    this._expand(content);
  }

  /**
   * Expand promo block
   *
   * @param content
   * @private
   */
  _expand(content) {
    if (!this.compressBlocks) {
      return;
    }

    const $content = $(content);

    $content
      .addClass('animating animating-in')
      .one('trend', () => {
        $content
          .removeClass('animating animating-in')
          .addClass(this.expandedClass)
          .off('trend');
        adjustHeight($content);
      });
  }

  /**
   * Collapse a block
   *
   * @param content
   * @private
   */
  _collapse(content) {
    if (!this.compressBlocks) {
      return;
    }

    const $content = $(content);

    $content
      .addClass('animating animating-out')
      .one('trend', () => {
        $content
          .removeClass(`animating animating-out ${this.expandedClass}`)
          .off('trend');
        content.removeAttribute('data-clicked');
        resetHeight($content);
      });
  }
}
