import { transition } from '@pixelunion/animations';
import EventHandler from '@pixelunion/events';
import * as breakpoint from '@pixelunion/breakpoint';
import throttle from '../helpers/throttle';

export default class BackToTop {
  constructor(el) {
    this.el = el;
    const label = this.el.querySelector('.back-to-top__button-label');
    this.events = new EventHandler();
    this.animations = {
      button: transition({ el }),
      label: transition({
        el: label,
        state: 'hidden',
      }),
    };
    this.scrollThreshold = 0.3;
    this.events.register(this.el, 'click', () => this._scrollToTop());
    this.events.register(this.el, 'mouseenter', () => this._onHover());
    this.events.register(this.el, 'mouseleave', () => this._onHoverEnd());
    this.events.register(window, 'scroll', throttle(() => this._onScroll(), 100));
  }

  get scrollPosition() {
    return window.scrollY / (document.body.offsetHeight - window.innerHeight);
  }

  _onScroll() {
    if (breakpoint.max('S')) return;

    if (this.scrollPosition >= this.scrollThreshold) {
      this.animations.button.animateTo('visible');
    } else {
      this.animations.button.animateTo('hidden');
    }
  }

  _onHover() {
    if (breakpoint.max('S')) return;
    this.animations.label.animateTo('visible', {
      onStart: ({ el }) => {
        // When this function is called, element is `display: block; width: 0;`
        // but the wrapper's scrollWidth is the width we want to transition to
        const { scrollWidth } = el;

        // Add 8px to the width to create padding between the text and the chevron
        // We add this space here because adding padding via CSS causes a 'stepped'
        // animation effect due to the way browsers calculate visible width
        const adjustedScrollWidth = parseInt(scrollWidth, 10) + 8;

        el.style.setProperty('--open-width', `${adjustedScrollWidth}px`);
      },
    });
  }

  _onHoverEnd() {
    if (breakpoint.max('S')) return;
    this.animations.label.animateTo('hidden');
  }

  _scrollToTop() {
    document.activeElement.blur();
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  unload() {
    this.events.unregisterAll();
    this.animations.button.unload();
    this.animations.label.unload();
    this.animations = null;
  }
}
