import $ from 'jquery';
import * as breakpoint from '@pixelunion/breakpoint';
import Disclosure from '@pixelunion/shopify-cross-border';

import Accordion from '../Accordion';
import Forms from '../Forms';

export default class StaticFooter {
  constructor(section) {
    this.$el = $(section.el);
    this.el = section.el;
    this.countryDisclosureEls = this.el.querySelectorAll('[data-disclosure-country]');
    this.localeDisclosureEls = this.el.querySelectorAll('[data-disclosure-locale]');
    this.accordionTrigger = '[data-accordion-trigger]';
    this.disclosures = [];
    this.Accordion = new Accordion(this.el, {
      onStart: ({ el, state }) => {
        el
          .parentNode
          .parentNode
          .querySelector(this.accordionTrigger)
          .dataset.accordionTrigger = state;
      }
    });

    if (breakpoint.max('M')) {
      this.Accordion.closeAll({ force: true });
    }

    this.forms = new Forms(this.$el);

    if (this.countryDisclosureEls.length > 0) {
      this.countryDisclosureEls.forEach(disclosureEl => {
        this.disclosures.push(new Disclosure(disclosureEl));
      });
    }

    if (this.localeDisclosureEls.length > 0) {
      this.localeDisclosureEls.forEach(disclosureEl => {
        this.disclosures.push(new Disclosure(disclosureEl));
      });
    }

    // Handle Accordion interaction when window size changes
    breakpoint.onChange(breakpoints => this.onBreakpointChange(breakpoints));

    this._bindEvents();
  }

  _bindEvents() {
    this.$el.on('click.footer', this.accordionTrigger, event => {
      event.preventDefault();
      this._toggleAccordion($(event.currentTarget).parent()[0]);
    });
  }

  onSectionUnload() {
    this.$el.off('.footer');
    this.forms.unload();
    this.disclosures.forEach(disclosure => disclosure.unload());
    this.Accordion.unload();
  }

  onSectionBlockSelect(block) {
    if (block.el.classList.contains('has-accordion')) this._toggleAccordion(block);
  }

  onSectionBlockDeselect(block) {
    if (block.el.classList.contains('has-accordion')) this._toggleAccordion(block);
  }

  _toggleAccordion(block) {
    if (breakpoint.min('L')) return;

    this.Accordion.toggle(block);
  }

  onBreakpointChange(breakpoints) {
    if (breakpoints.current.min('L')) {
      this.Accordion.openAll({ force: true });
    } else if (breakpoints.previous.min('L') && breakpoints.current.max('M')) {
      this.Accordion.closeAll({ force: true });
    }
  }
}
