import $ from 'jquery';
import * as breakpoint from '@pixelunion/breakpoint';

import Accordion from '../Accordion';

export default class DynamicMenuList {
  constructor(section) {
    this.$el = $(section.el);
    this.context = section.data.context;
    this.accordionTrigger = '[data-accordion-trigger]';
    this.seeMoreTrigger = '[data-menulist-toggle]';

    this.Accordion = new Accordion(this.$el[0], {
      content: '.menulist-menu--initial[data-accordion-content]',
      onStart: ({ el, state }) => {
        el.parentNode.querySelector(this.accordionTrigger).dataset.accordionTrigger = state;
      }
    });

    this.seeMore = new Accordion(this.$el[0], {
      content: '.menulist-menu--show-more[data-accordion-content]',
      onStart: ({ el, state }) => {
        el
          .parentNode
          .querySelector(this.seeMoreTrigger)
          .innerHTML = state === 'open' ? this.context.see_less : this.context.see_more;
      }
    });

    if (breakpoint.max('XS')) {
      this.Accordion.closeAll({ force: true });
    }

    this.seeMore.closeAll({ force: true });

    // Handle Accordion interaction when window size changes
    breakpoint.onChange(breakpoints => this.onBreakpointChange(breakpoints));

    this._bindEvents();
  }

  _bindEvents() {
    this.$el.on('click.menu-list', this.accordionTrigger, event => {
      event.preventDefault();
      this._toggleAccordion(event.currentTarget.parentNode);
    });

    this.$el.on('click.menu-list', this.seeMoreTrigger, event => {
      event.preventDefault();
      this.seeMore.toggle(event.currentTarget.parentNode);
    });
  }

  onSectionUnload() {
    this.$el.off('.menu-list');
    this.Accordion.unload();
    this.seeMore.unload();
  }

  onSectionBlockSelect(block) {
    this._toggleAccordion(block.el);
  }

  onSectionBlockDeselect(block) {
    this._toggleAccordion(block.el);
  }

  _toggleAccordion(block) {
    if (breakpoint.min('S')) return;
    this.Accordion.toggle(block);
  }

  onBreakpointChange(breakpoints) {
    if (breakpoints.current.min('S')) {
      this.Accordion.openAll({ force: true });
    } else if (breakpoints.previous.min('S') && breakpoints.current.max('XS')) {
      this.Accordion.closeAll({ force: true });
    }
  }
}
